import React, { Fragment, useEffect, useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import BlockControls from "../components/BlockControls";
import SocialIcons from "../components/SocialIcons";
import ContentBlock1 from "../components/ContentBlock1";
import ContentBlock2 from "../components/ContentBlock2";
import ScrollToTop from "../components/ScrollToTop";
import { Helmet } from "react-helmet-async";
import DialogKontakt from "../components/DialogKontakt";
import ContentBlockGallery from "../components/ContentBlockGallery";
import ZeitPreiseTabelle from "../components/ZeitPreiseTabelle";
import Reviews from "../components/Reviews";
import NewsletterFormular from "../components/NewsletterFormular";

function Yoga() {
    const blockControlsData = [
        {
            titel: "Kostenfreies Erstgespräch",
            blockId: "b1",
        },
        {
            titel: "Yoga",
            blockId: "b2",
        },
        {
            titel: "Infos zu Yoga",
            blockId: "b3",
        },
        {
            titel: "Positive Wirkung",
            blockId: "b4",
        },
        {
            titel: "Yoga Location",
            blockId: "b5",
        },
        {
            titel: "Yoga Impressionen",
            blockId: "b6",
        },
        {
            titel: "Newsletter",
            blockId: "newsletter",
        },
        {
            titel: "Kontakt",
            blockId: "b8",
        },
    ];

    const [schema, setSchema] = useState("light");
    const [schemaHeader, setSchemaHeader] = useState("light");

    useEffect(() => {
        window.addEventListener("scroll", () => {
            if (window.scrollY > 300) {
                setSchema("dark");
            } else {
                setSchema("light");
            }
        });
    }, []);

    const openDialog = (targetDialog) => {
        document.getElementById(targetDialog).showModal();
        document.body.style.overflowY = "hidden";
    };

    return (
        <Fragment>
            <Helmet>
                <title>Yoga | Carolin Pfeffer</title>
                <meta name="description" content="▶▶ Jetzt zum Yoga anmelden! Gruppenkurse oder Personal Training ✔ Muskulatur dehnen &amp; kräftigen ✔ Verspannungen lösen ✔ vieles mehr!"></meta>
                <meta name="keywords" content="yoga,yoga schule,yoga kurse,yoga lernen,yoga lehrer,yoga gruppe,yoga in der natur,yoga online,yoga videos,yoga ernährung, yoga thüringen,yoga am see"></meta>
            </Helmet>
            <ScrollToTop />
            <DialogKontakt />
            <Header schema={schemaHeader} />
            <SocialIcons schema={schema} />
            <BlockControls blockControlsData={blockControlsData} schema={schema} />
            <ContentBlock1
                titel="Yoga"
                text="Vereinbare jetzt dein kostenfreies Erstgespräch und Yoga-Einheit. Ich freue mich auf ein gemeinsames Training und Deine ersten Erfolge!"
                usps={["100% individuell", "Ganzheitliches Training", "Ortsgebunden", "Höchste Flexibilität"]}
                buttonText="kostenfreies Erstgespräch vereinbaren"
                buttonLink="dialog"
                targetDialog="DialogKontakt"
                backgroundImg="/img/StartseiteLandingBlock_2.webp"
                alignBox="left"
                id={blockControlsData[0].blockId}
            />
            <ContentBlock2
                icon="/img/Leistungen_Yoga.svg"
                titel="Yoga"
                subTitel="Der Ganzheitliche Gesundheitsansatz"
                text="Um Yoga zu praktizieren, musst du kein bestimmtes Alter, keinen bestimmten Körper oder kein bestimmtes Geschlecht haben. Yoga kann jeder. Beim Yoga darfst du so sein wie du bist. In den letzten Jahren ist Yoga sehr populär geworden. Es gibt zahlreiche Studien, welche die positive Wirkung von Yoga belegen. Yoga ist integrale Technik, die auf körperlicher, emotionaler und mentaler Ebene seine Wirkung entfaltet. Das Wort Yoga stammt aus dem Sanskrit von der Ursprungssilbe „Yui“ , was so viel bedeutet wie zusammenbinden. Es ist eine Jahrtausend alte indische Lehre, welche Körper, Geist und Seele zusammenbringen möchte. Die Rishis (indische Weise) suchten nach einem Übungssystem, einem Weg, der es ermöglicht den Menschen im ganzheitlichen Sinne Gesundheit und Frieden zu verschaffen. Durch gezielte Übungen auf körperlicher und mentaler Ebene möchte Yoga den unruhigen Geist zähmen und den Körper schulen. Achtsamkeit spielt hierbei eine große Rolle. Ein wichtiger Gelehrter ist Patanjali. Er verfasste im 2. oder 4.Jh.v.Chr. das Yoga Sutra, ein wichtiger Quelltext des Yoga. In dem zweiten Buch beschreibt er eine Art Anleitung, den 8-stufigen Pfad, welcher mithilfe von Verhaltensgrundsätzen, sowie geistigen und körperliche Übungen zur Selbsterkenntnis führen soll."
                zitat="Der Weg zu dieser tiefen Entspannung, die wir suchen führt für viele von uns am einfachsten über unseren Körper und damit über Asanas, die Körperhaltungen. Diese Haltungen allein sind schon wohltuend und förderlich für die Gesundheit."
                id={blockControlsData[1].blockId}
            />
            <div className="w-100" id={blockControlsData[2].blockId}>
                <div className="wrapper">
                    <div className="content d-flex flex-column align-items-center justify-content-evenly">
                        <h2 className="h4 h4--spaceing h4--border h4--center mb-7">Ich biete Yoga in den folgenden Formen an</h2>
                        <div className="row w-100 mb-4">
                            <div className="col d-flex flex-column justify-content-evenly text-uppercase mb-5">
                                <img src="/img/Content_PersonalTraining.svg" alt="Personal Training Icon" className="contentSpecItem mb-4" />
                                <span className="d-block text-center">Personal Training</span>
                            </div>
                            <div className="col d-flex flex-column justify-content-evenly text-uppercase mb-5">
                                <img src="/img/Content_GruppenTraining.svg" alt="Gruppen Training Icon" className="contentSpecItem mb-4" />
                                <span className="d-block text-center">Gruppen Training</span>
                            </div>
                            <div className="col d-flex flex-column justify-content-evenly text-uppercase mb-5">
                                <img src="/img/Content_FirmenFitness.svg" alt="Firmen Fitness Icon" className="contentSpecItem mb-4" />
                                <span className="d-block text-center">Firmen Fitness</span>
                            </div>
                            <div className="col d-flex flex-column justify-content-evenly text-uppercase mb-5">
                                <img src="/img/Content_Workshops.svg" alt="Workshops Icon" className="contentSpecItem mb-4" />
                                <span className="d-block text-center">Workshops</span>
                            </div>
                            <div className="col d-flex flex-column justify-content-evenly text-uppercase mb-5">
                                <img src="/img/Content_Vortraege.svg" alt="Vorträge Icon" className="contentSpecItem mb-4" />
                                <span className="d-block text-center">Vorträge</span>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-md-8 mb-4">
                                <h3 className="d-block h4 h4--spaceing mb-4">Hatha Yoga</h3>
                                <p className="mb-4">
                                    Es gibt viele Arten von Yoga. Bei mir findest du eine gute Mischung, um deinen eigenen Yogaweg zu etablieren. Wenn du dem ganzen gern einen Namen geben möchtest, dann findest du bei mir Hatha Yoga als
                                    Basis meiner Praxis.
                                </p>
                                <h4 className="d-block fw-bold mb-2 fs-5">Kostenfreis Erstgespräch</h4>
                                <p className="mb-4">
                                    Kostenfreies Erstgespräch Für den bestmöglichen Erfolg, werden wir gemeinsam in einem kostenfreien Erstgespräch über deine Bedürfnisse sprechen, um diese zu bestimmen. Wenn so weit alles passt und die
                                    Chemie stimmt vereinbaren wir einen Termin für eine Einzel-Sessions oder du nimmst an einen der zahlreichen Gruppenkurse teil.
                                </p>
                                <span className="cursor-pointer" onClick={() => openDialog("DialogKontakt")}>
                                    <span className="fw-bold">Los geht's:&nbsp;</span>
                                    jetzt kostenloses Erstgespräch vereinbaren
                                </span>
                            </div>
                            <div className="col-12 col-md-4 mb-4">
                                <img src="/img/Content_Yoga.webp" alt="Hatha Yoga Foto" className="w-100" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="w-100" id={blockControlsData[3].blockId}>
                <div className="wrapper">
                    <div className="content d-flex flex-column align-items-center justify-content-evenly">
                        <h2 className="h4 h4--spaceing h4--border h4--center mb-7">Die positive Wirkung des Yoga</h2>
                        <div className="row w-100">
                            <div className="col-12 col-md-6">
                                <div className="div--usp">
                                    <i className="bi bi-arrows-angle-expand"></i>
                                    <span>Yoga kräftigt und dehnt die Muskulatur</span>
                                </div>
                                <div className="div--usp">
                                    <i className="bi bi-balloon"></i>
                                    <span>Löst Verspannung und Blockaden und hilft damit bei Schmerzen</span>
                                </div>
                                <div className="div--usp">
                                    <i className="bi bi-droplet"></i>
                                    <span>Verbessert die Durchblutung</span>
                                </div>
                                <div className="div--usp">
                                    <i className="bi bi-heart-half"></i>
                                    <span>Verbessert die Funktion der Organe</span>
                                </div>
                                <div className="div--usp">
                                    <i className="bi bi-activity"></i>
                                    <span>Stärkt das Immunsystem</span>
                                </div>
                                <div className="div--usp">
                                    <i className="bi bi-emoji-smile"></i>
                                    <span>Reduziert Stress</span>
                                </div>
                                <div className="div--usp">
                                    <i className="bi bi-moon-stars"></i>
                                    <span>Reduziert Stress</span>
                                </div>
                            </div>
                            <div className="col-12 col-md-6">
                                <div className="div--usp">
                                    <i className="bi bi-graph-up-arrow"></i>
                                    <span>Verbessert die Konzentrations- und Leistungsfähigkeit</span>
                                </div>
                                <div className="div--usp">
                                    <i className="bi bi-arrow-repeat"></i>
                                    <span>Hilft bei Verdauungsproblemen</span>
                                </div>
                                <div className="div--usp">
                                    <i className="bi bi-wind"></i>
                                    <span>Vertieft den Atem und schult das korrekte und ökonomische Atmen</span>
                                </div>
                                <div className="div--usp">
                                    <i className="bi bi-heart-pulse"></i>
                                    <span>Wirkt positiv auf das Herz-Kreislauf-System</span>
                                </div>
                                <div className="div--usp">
                                    <i className="bi bi-balloon-heart"></i>
                                    <span>Hilft bei Bluthochdruck</span>
                                </div>
                                <div className="div--usp">
                                    <i className="bi bi-puzzle"></i>
                                    <span>Therapiebegl. Maßnahme bei Krebserkrankungen &amp; Depressionen</span>
                                </div>
                                <div className="div--usp">
                                    <i className="bi bi-yin-yang"></i>
                                    <span>Hebt die Stimmung und gleicht das Gemüt aus</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="w-100" id={blockControlsData[4].blockId}>
                <div className="wrapper">
                    <div className="content d-flex flex-column align-items-center justify-content-evenly">
                        <h2 className="h4 h4--spaceing h4--border h4--center mb-7">Wo findet die Yoga-Einheit statt?</h2>
                        <div className="row mb-6">
                            <div className="col-12">
                                <p className="mb-4">
                                    Ich biete mehrere Arten von Yoga an. Neben dem klassischen Hatha Yoga mische ich die verschiedenen Stile. Dadurch verwenden wir die effizientesten Übungen aus jeder Yoga-Art. du wünschst dir deine
                                    Yoga-Einheit an einem bestimmten Ort? Kein Problem: Unsere gemeinsame Yoga-Einheit kann bei dir zu Hause, im Büro, im Fitnessstudio, in meinem Fitnessraum oder in der freien Natur stattfinden. Wir suchen
                                    uns den geeigneten Platz oder Raum, in dem du dich am wohlsten fühlst.
                                </p>
                                <span className="d-block fw-bold mb-2">Termine und Preise</span>
                                <p>
                                    Gern kannst du auch in einem unserer Gruppenkurse teilnehmen. Die Termine hierfür findest du in der Kursübersicht. Du möchtest eine Freund*in mitbringen? Super! So teilen sich nicht nur die Kosten,
                                    sondern auch der Spaßfaktor. Eine aktuelle Preisübersicht findest du unter Preise.
                                </p>
                            </div>
                        </div>
                        {/* <ZeitPreiseTabelle id={9} /> */}
                        <ZeitPreiseTabelle id={10} />
                    </div>
                </div>
            </div>
            <div className="w-100">
                <div className="wrapper">
                    <div className="content">
                        <h4 className="h4 h4--spaceing h4--border h4--center mb-7">Das sagen meine Kunden</h4>
                        <Reviews url={"/cronjobs/googleReviewsPersonal.json"} />
                    </div>
                </div>
            </div>
            <ContentBlockGallery id={blockControlsData[5].blockId} img1={"/img/Content_GalerieYoga1.webp"} img2={"/img/Content_GalerieYoga2.webp"} img3={"/img/Content_GalerieYoga4.webp"} img4={"/img/Content_GalerieYoga5.webp"} />
            <NewsletterFormular id={blockControlsData[6].blockId}></NewsletterFormular>
            <Footer id={blockControlsData[7].blockId} />
        </Fragment>
    );
}

export default Yoga;
