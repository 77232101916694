import React from "react";
import "./BlockControls.css";

function BlockControls({ blockControlsData, schema }) {
    return (
        <div className={"BlockControls"}>
            {blockControlsData.map((data, idx) => (
                <a key={idx} href={"#" + data.blockId} className={"BlockControls__circle " + schema} title={data.titel}>
                    <i className={data.aktiv === "true" ? "bi bi-circle-fill" : "bi bi-circle"}></i>
                    <span>{"- " + data.titel}</span>
                </a>
            ))}
        </div>
    );
}

export default BlockControls;
