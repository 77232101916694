import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Routes, Route } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./index.css";
import Startseite from "./pages/Startseite";
import Yoga from "./pages/Yoga";
import Fitness from "./pages/Fitness";
import Firmenfitness from "./pages/Firmenfitness";
import Ernaehrungscoaching from "./pages/Ernaehrungscoaching";
import StandUpPaddlingSaalburg from "./pages/StandUpPaddlingSaalburg";
import UeberMich from "./pages/UeberMich";
import Aktivurlaub from "./pages/Aktivurlaub";
import Blog from "./pages/Blog";
import BlogPost from "./pages/BlogPost";
import Error404 from "./pages/Error404";
import Datenschutz from "./pages/Datenschutz";
import Impressum from "./pages/Impressum";
import AGB from "./pages/AGB";
import AnmeldungErfolgreich from "./pages/AnmeldungErfolgreich";
import Kurskalender from "./pages/Kurskalender";
import { HelmetProvider } from "react-helmet-async";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
    <React.StrictMode>
        <HelmetProvider>
            <BrowserRouter>
                <Routes>
                    <Route index element={<Startseite />} />
                    <Route path="*" element={<Error404 />} />
                    <Route path="/404" element={<Error404 />} />
                    <Route path="/anmeldung-erfolgreich" element={<AnmeldungErfolgreich />} />
                    <Route path="/yoga" element={<Yoga />} />
                    <Route path="/fitness" element={<Fitness />} />
                    <Route path="/firmenfitness" element={<Firmenfitness />} />
                    <Route path="/ernaehrungscoaching" element={<Ernaehrungscoaching />} />
                    <Route path="/stand-up-paddling-in-saalburg" element={<StandUpPaddlingSaalburg />} />
                    <Route path="/ueber-mich" element={<UeberMich />} />
                    <Route path="/aktivurlaub" element={<Aktivurlaub />} />
                    <Route path="/blog" element={<Blog />} />
                    <Route path="/datenschutz" element={<Datenschutz />} />
                    <Route path="/impressum" element={<Impressum />} />
                    <Route path="/agb" element={<AGB />} />
                    <Route path="/blog/:slug" element={<BlogPost />} />
                    <Route path="/kurskalender" element={<Kurskalender />} />
                </Routes>
            </BrowserRouter>
        </HelmetProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
