import React, { Fragment, useEffect, useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import BlockControls from "../components/BlockControls";
import SocialIcons from "../components/SocialIcons";
import ContentBlock1 from "../components/ContentBlock1";
import ContentBlock2 from "../components/ContentBlock2";
import ContentBlockUSPs from "../components/ContentBlockUSPs";
import ScrollToTop from "../components/ScrollToTop";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import "./StandUpPaddlingSaalburg.css";
import ContentBlockGallery from "../components/ContentBlockGallery";
import ZeitPreiseTabelle from "../components/ZeitPreiseTabelle";
import Reviews from "../components/Reviews";
import NewsletterFormular from "../components/NewsletterFormular";

function StandUpPaddlingSaalburg() {
    const blockControlsData = [
        {
            titel: "Spaß auf dem Wasser",
            blockId: "b1",
        },
        {
            titel: "Stand Up Paddling",
            blockId: "b2",
        },
        {
            titel: "Infos zu Stand Up Paddling",
            blockId: "b3",
        },
        {
            titel: "Touren auf dem Bleilochstausee",
            blockId: "b4",
        },
        {
            titel: "Öffungszeiten und Preise",
            blockId: "b5",
        },
        {
            titel: "SUP Impressionen",
            blockId: "b6",
        },
        {
            titel: "Newsletter",
            blockId: "newsletter",
        },
        {
            titel: "Kontakt",
            blockId: "b8",
        },
    ];

    const [schema, setSchema] = useState("light");
    const [schemaHeader, setSchemaHeader] = useState("light");

    useEffect(() => {
        window.addEventListener("scroll", () => {
            if (window.scrollY > 300) {
                setSchema("dark");
            } else {
                setSchema("light");
            }
        });
    }, []);

    const [sup_karte_visible, setSup_karte_visible] = useState(false);

    const loadMap = (e) => {
        e.preventDefault();
        setSup_karte_visible(true);
    };

    return (
        <Fragment>
            <Helmet>
                <title>Stand Up Paddling in Saalburg | Carolin Pfeffer</title>
                <meta name="description" content="Stand Up Paddling in Saalburg » SUP auf dem Bleilochstausee ✓ Einsteigerkurse &amp; Kaufberatung ✓ SUP Verleih ✓  geführte Touren ✓ SUP Yoga &amp; mehr ✓"></meta>
                <meta
                    name="keywords"
                    content="stand up paddling,standup paddling,sup,sup kurs,sup einsteiger,stand up paddling einsteiger,sup saalburg,stand up paddling saalburg,stand up paddling thüringen,stand up paddling board, stand up paddling verleih"
                ></meta>
            </Helmet>
            <ScrollToTop />
            <Header schema={schemaHeader} />
            <SocialIcons schema={schema} />
            <BlockControls blockControlsData={blockControlsData} schema={schema} />
            <ContentBlock1
                titel="Stand Up Paddling"
                text="Spaß auf dem Wasser und Fitness in der Natur: Entdecke die Vielfalt vom Stand Up Paddling! Gemeinsam mit Freunden oder allein: Wir bieten dir zahlreiche Möglichkeiten den beliebten Wassersport auf dem Bleilochstausee in Saalburg auszuprobieren."
                usps={["kompetente Anlaufstelle für Verleih & Verkauf", "Verleih immer inkl. Einweisung", "hohe Flexibilität, Buchung spontan möglich", "Fitnesstraining & Kurzurlaub in einem", "wir beraten dich gern"]}
                buttonText="SUP-Board ausleihen"
                buttonLink={"#" + blockControlsData[4].blockId}
                targetDialog=""
                backgroundImg="/img/StartseiteLandingBlock_5.webp"
                alignBox="right"
                id={blockControlsData[0].blockId}
            />
            <ContentBlock2
                icon="/img/Leistungen_StandUpPaddling.svg"
                titel="Stand Up Paddling"
                subTitel="Stand Up Paddling in Saalburg auf dem Bleilochstausee"
                text="Aloha. Eine frische Brise um die Nase, die Sonne auf deiner Haut, umgeben von Natur pur. So schön kann Sport sein. Neben dem Erholungsfaktor trainierst du ganzen Körper in vielfältiger Weise. Mit dem richtigen Equipment und korrekter Paddeltechnik wirst auch du ein diese Sportart für dich entdecken. Wir halten zwischen 15 und 20 Inflatable & Hard Boards für dich parat."
                zitat="Mālama (Hawaiianisch) Respektiere den Ozean und der Ozean respektiert Dich."
                id={blockControlsData[1].blockId}
            />
            <div className="w-100" id={blockControlsData[2].blockId}>
                <div className="wrapper h-100">
                    <div className="content h-100 d-flex flex-column align-items-center justify-content-evenly">
                        <h2 className="h4 h4--spaceing h4--border h4--center mb-7">Ich biete Stand Up Paddling in den folgenden Formen an</h2>
                        <div className="row w-100 mb-4">
                            <div className="col d-flex flex-column justify-content-evenly text-uppercase mb-5">
                                <img src="/img/Content_PersonalTraining.svg" alt="Personal Training Icon" className="contentSpecItem mb-4" />
                                <span className="d-block text-center">Personal Training</span>
                            </div>
                            <div className="col d-flex flex-column justify-content-evenly text-uppercase mb-5">
                                <img src="/img/Content_GruppenTraining.svg" alt="Gruppen Training Icon" className="contentSpecItem mb-4" />
                                <span className="d-block text-center">Gruppen Training</span>
                            </div>
                            <div className="col d-flex flex-column justify-content-evenly text-uppercase mb-5">
                                <img src="/img/Content_FirmenFitness.svg" alt="Firmen Fitness Icon" className="contentSpecItem mb-4" />
                                <span className="d-block text-center">Firmen Fitness</span>
                            </div>
                            <div className="col d-flex flex-column justify-content-evenly text-uppercase mb-5">
                                <img src="/img/Content_Workshops.svg" alt="Workshops Icon" className="contentSpecItem mb-4" />
                                <span className="d-block text-center">Workshops</span>
                            </div>
                            <div className="col d-flex flex-column justify-content-evenly text-uppercase mb-5">
                                <img src="/img/Content_Vortraege.svg" alt="Vorträge Icon" className="contentSpecItem mb-4" />
                                <span className="d-block text-center">Vorträge</span>
                            </div>
                        </div>
                        <div className="row mb-6">
                            <div className="col-12 col-md-8 mb-4">
                                <h3 className="h4 h4--spaceing mb-4">Stand Up Paddling</h3>
                                <p className="mb-4">
                                    Stand Up Paddling oder auch einfach Stehpaddeln ist ein Wassersport bei dem man auf einer festen oder aufblasbaren größeren Variante eines Surfbrettes steht und sich mit Hilfe eines Stechpaddels
                                    fortbewegt. Klingt einfach, nichts desto trotz sind eine gute Beratung zur Handhabung und eventuell Kauf ein sinnvolles Angebot um möglichst viel aus der neuen Lieblingssportart herauszuholen.
                                </p>
                                <span className="d-block fw-bold mb-2">SUP-Verleih</span>
                                <p className="mb-4">Du möchtest gern allein oder mit Freunden auf’s Wasser? Dann kannst du dir bei mir ganz einfach dein SUP Board ausleihen. Ganz egal ob für ein paar Stunden oder gleich den ganzen Tag!</p>
                                <a href={"#" + blockControlsData[4].blockId} title="SUP-Board ausleihen" className="btn-dark-primary">
                                    SUP-Board ausleihen
                                </a>
                            </div>
                            <div className="col-12 col-md-4 mb-4">
                                <img src="/img/Content_SUP.webp" alt="Stand Up Paddling Foto" className="w-100" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <h4 className="d-block fw-bold mb-2 fs-5">SUP Einsteigerkurse und Kaufberatung</h4>
                                <p className="mb-4">
                                    Stand Up Paddling Einsteigerkurse und Kaufberatungen sollten genutzt werden. Stand up paddeln ist nicht nur ein trendiger Wasserspaß sondern hat viele weitere positive Eigenschaften. Mit dem Board nutzt
                                    man eine umweltschonende Möglichkeit verschiedenste Gewässer zu erkunden. Die Natur bietet einem zusätzlich den heilsamen Nebeneffekt beim Fitnesstraining. Ja, richtig gelesen, natürlich bietet der
                                    andauernde Balanceakt ein gutes Training für die Tiefenmuskulatur. Das richtige Paddeln bewirkt einen wunderbare Kräftigung unserer Rumpfmuskulatur, der Arme und Schultern.
                                </p>
                            </div>
                            <div className="col-12">
                                <h4 className="d-block fw-bold mb-2 fs-5">Stand Up Paddling gegen Rückenschmerzen</h4>
                                <p>
                                    Wer „Rücken“ hat sollte sich darin unbedingt ausprobieren. Neben all der Aktivität ist natürlich zu erwähnen das man sich auch einfach mal nur aufs Board legen, alle Viere von sich strecken und die Seele
                                    baumeln lassen kann…. klingt doch schon beim lesen wie Urlaub! ;-)
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ContentBlockUSPs
                headline="SUP-Board kostengünstig leihen oder kaufen"
                usps={["Verleih von Allround und Touring Boards", "Verkauf von SUP & Surf Boards & Zubehör", "Flexible Zeiten", "Ortsungebunden"]}
                cta="Lust bekommen?"
                buttonText="jetzt SUP-Board ausleihen"
                buttonLink={"#" + blockControlsData[4].blockId}
                alignment="left"
            />
            <div className="w-100" id={blockControlsData[3].blockId}>
                <div className="wrapper">
                    <div className="content">
                        <div className="row">
                            <div className="col-12 col-md-6 mb-4">
                                <h4 className="d-block fw-bold mb-4 fs-5">Stand Up Paddling Touren auf dem Bleilochstausee</h4>
                                {/* <span className="d-block mb-6">
                                    <span className="fw-bold">Start: </span>
                                    Verleihstation CSports in der Werft
                                    der&nbsp;
                                    <a
                                        href="https://goo.gl/maps/31cYWYwSTBXkx5fL7"
                                        title="Marina Fahrgastschifffahrt, Wetteraweg
                                        1, 07929 Saalburg auf Maps anzeigen"
                                        target="_blank"
                                        rel="noopener noreferrer">
                                        Marina Fahrgastschifffahrt, Wetteraweg
                                        1, 07929 Saalburg
                                    </a>
                                </span> */}
                                <h4 className="d-block fw-bold mb-4 fs-5">SUP - Tagestouren</h4>
                                <ul className="ul--supTour d-block mb-6">
                                    <li className="flag--red">
                                        <span className="ul--supTour__liContainer">
                                            <span className="d-block">
                                                <span className="fw-bold">Längste Tour - „Naturliebe“</span>
                                                &nbsp;(rot/orange auf Karte)
                                            </span>
                                            <span className="d-block">Strecke: Saalburg-Harra (Flussaufwärts)</span>
                                            <span className="d-block">Länge: 1 Strecke ca. 20km</span>
                                            <span className="d-block">Dauer: ca. 3:20h je nach Wind zzgl. Pausen</span>
                                        </span>
                                    </li>
                                    <li className="flag--yellow">
                                        <span className="ul--supTour__liContainer">
                                            <span className="d-block">
                                                <span className="fw-bold">Zweitlängste Tour- „Naturliebe #2“</span>
                                                &nbsp;(gelb auf Karte)
                                            </span>
                                            <span className="d-block">Strecke: Saalburg-Totenfels-Saaldorf (Flussaufwärts)</span>
                                            <span className="d-block">Länge: 1 Strecke ca. 13km</span>
                                            <span className="d-block">Dauer: ca. 2,5 - 3h je nach Wind zzgl. Pausen</span>
                                        </span>
                                    </li>
                                </ul>
                                <h4 className="d-block fw-bold mb-4 fs-5">Buchten</h4>
                                <ul className="ul--supTour">
                                    <li className="flag--green">
                                        <span className="ul--supTour__liContainer">
                                            <span className="d-block">
                                                <span className="fw-bold">2. Damm - „Wasser &amp; Wald“</span>
                                                &nbsp;(grün auf Karte)
                                            </span>
                                            <span className="d-block">Strecke: Saalburg - 2.Damm (Flussaufwärts)</span>
                                            <span className="d-block">Länge: 1 Strecke ca. 4km</span>
                                            <span className="d-block">Dauer: ca. 40min.</span>
                                        </span>
                                    </li>
                                    <li className="flag--blue">
                                        <span className="ul--supTour__liContainer">
                                            <span className="d-block">
                                                <span className="fw-bold">Künsdorfer Bucht - „Wasser &amp; Wald #2“</span>
                                                &nbsp;(blau auf Karte)
                                            </span>
                                            <span className="d-block">Strecke: Saalburg - Künsdorfer Bucht (Flussaufwärts)</span>
                                            <span className="d-block">Länge: 1 Strecke ca. 5km</span>
                                            <span className="d-block">Dauer: ca. 40min.</span>
                                        </span>
                                    </li>
                                    <li className="flag--yellow">
                                        <span className="ul--supTour__liContainer">
                                            <span className="d-block">
                                                <span className="fw-bold">Totenfels - „Sage vom Reiter und seinem Pferd“</span>
                                                &nbsp;(gelb auf Karte)
                                            </span>
                                            <span className="d-block">Strecke: Saalburg - Totenfels (Flussaufwärts)</span>
                                            <span className="d-block">Länge: 1 Strecke ca. 7,5km</span>
                                            <span className="d-block">Dauer: ca. 1h</span>
                                        </span>
                                    </li>
                                    <li className="flag--dark-violet">
                                        <span className="ul--supTour__liContainer">
                                            <span className="d-block">
                                                <span className="fw-bold">Gräfenwarther Bucht</span>
                                                &nbsp;(dunkellila)
                                            </span>
                                            <span className="d-block">Strecke: Saalburg - Gräfenwarth (Flussabwärts)</span>
                                            <span className="d-block">Länge: 1 Strecke ca. 4km</span>
                                            <span className="d-block">Dauer: ca. 40min.</span>
                                        </span>
                                    </li>
                                    <li className="flag--violet">
                                        <span className="ul--supTour__liContainer">
                                            <span className="d-block">
                                                <span className="fw-bold">Gräfenwarther Bucht über Staumauer zurück</span>
                                                &nbsp;(dunkellila + helllila)
                                            </span>
                                            <span className="d-block">Strecke: Saalburg - Gräfenwarth - Saalburg</span>
                                            <span className="d-block">Länge: ca. 5,3km</span>
                                            <span className="d-block">Dauer: ca. 1:20 h</span>
                                        </span>
                                    </li>
                                    <li className="flag--black">
                                        <span className="ul--supTour__liContainer">
                                            <span className="d-block">
                                                <span className="fw-bold">Remptendorfer Bucht</span>
                                                &nbsp;(schwarz auf Karte)
                                            </span>
                                            <span className="d-block">Strecke: Saalburg - Remptendorf</span>
                                            <span className="d-block">Länge: ca. 4km</span>
                                            <span className="d-block">Dauer: ca. 1 h</span>
                                        </span>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-12 col-md-6 mb-4">
                                <div className="tours">
                                    {!sup_karte_visible ? (
                                        <div className="tours__dialog">
                                            <form onSubmit={loadMap}>
                                                <input type="submit" className="light mb-2" value="Karte anzeigen" />
                                                <fieldset>
                                                    <input type="checkbox" className="me-2" id="karteSupDatenschutz" required />
                                                    <label className="d-inline color-background" htmlFor="karteSupDatenschutz">
                                                        Ich habe die{" "}
                                                        <Link to={"/datenschutz"} title="Datenschutzbestimmungen">
                                                            Datenschutzbestimmungen
                                                        </Link>{" "}
                                                        gelesen und akzeptiere diese.
                                                    </label>
                                                </fieldset>
                                            </form>
                                        </div>
                                    ) : (
                                        <iframe
                                            src="https://www.google.com/maps/d/embed?mid=1rjRP-I8wLiyAoNgn_COBVXAGSNbQbXIZ&hl=de&ehbc=2E312F"
                                            style={{
                                                width: "100%",
                                                height: "100%",
                                                position: "absolute",
                                                zIndex: 3,
                                            }}
                                            title="SUP - Tagestouren Bleilochtalsperre"
                                        ></iframe>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="w-100">
                <div className="wrapper">
                    <div className="content">
                        <div className="row flex-row-reverse flex-md-row">
                            <div className="col-3">
                                <a href="https://www.campz.de/info/sup-touren-deutschland/#thueringen-bleilochtalsperre" target="_blank" rel="noopener noreferrer" title="auf Campz.de nachlesen">
                                    <img src="/img/CAMPZ_badge_SUP-tipp.png" alt="CAMPZ Logo" title="CAMPZ Logo" style={{ width: "100%" }} />
                                </a>
                            </div>
                            <div className="col-9 d-flex flex-column justify-content-center">
                                <h4 className="d-block fw-bold mb-2 fs-5">Beitrag auf Campz.de zu den beliebtesten SUP-Touren in Deutschland:</h4>
                                <span className="d-block">
                                    SUP-Tipp #3 – Andreas und Jutta von SUPscout empfehlen: Bleilochtalsperre (Thüringen). Was die beiden zu unserer wundervollen Gegend sagen könnt ihr hier&nbsp;
                                    <a href="https://www.campz.de/info/sup-touren-deutschland/#thueringen-bleilochtalsperre" target="_blank" rel="noopener noreferrer" title="auf Campz.de nachlesen">
                                        auf Campz.de nachlesen
                                    </a>
                                    .
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="w-100" id={blockControlsData[4].blockId}>
                <div className="wrapper">
                    <div className="content">
                        <h4 className="d-block fw-bold mb-2 fs-5">Öffungszeiten und Preise für den SUP-Verleih</h4>
                        <span className="d-block mb-2">Aufgrund unseres Umzugs und anschließender Umbauarbeiten findet der SUP Verleih & Kurse in diesem Jahr etwas anders statt.</span>
                        <ul className="d-block mb-4">
                            <li>
                                <b>An bestimmten Wochenenden:</b> Die genauen Termine findet ihr in den unteren Tabellen
                            </li>
                            <li>
                                <b>Auf individuelle Anfrage:</b> Ihr habt Lust auf einen mehrtägigen SUP-Ausflug, einen Junggesell*innenabschied auf dem Board oder ein Firmenevent mit SUP-Yoga? Dann meldet euch gerne bei uns und wir
                                erstellen euch ein individuelles Angebot.
                            </li>
                        </ul>
                        <span className="d-block">Hinweis: Alle Preise verstehen sich inklusive 19% Mehrwertsteuer.</span>
                    </div>
                </div>
            </div>
            <div className="w-100">
                <div className="wrapper">
                    <div className="content d-flex flex-column align-items-center justify-content-evenly">
                        <ZeitPreiseTabelle id={1} />
                        <ZeitPreiseTabelle id={2} />
                        <ZeitPreiseTabelle id={4} />
                        {/* <ZeitPreiseTabelle id={5} /> */}
                        {/* <ZeitPreiseTabelle id={6} /> */}
                    </div>
                </div>
            </div>
            <ContentBlockGallery id={blockControlsData[5].blockId} img1={"/img/Content_GalerieSUP1.webp"} img2={"/img/Content_GalerieSUP2.webp"} img3={"/img/Content_GalerieSUP4.webp"} img4={"/img/Content_GalerieSUP3.webp"} />
            <div className="w-100">
                <div className="wrapper">
                    <div className="content">
                        <h4 className="h4 h4--spaceing h4--border h4--center mb-7">Das sagen meine Kunden</h4>
                        <Reviews url={"/cronjobs/googleReviewsSUP.json"} />
                    </div>
                </div>
            </div>
            <NewsletterFormular id={blockControlsData[6].blockId}></NewsletterFormular>
            <Footer id={blockControlsData[7].blockId} />
        </Fragment>
    );
}

export default StandUpPaddlingSaalburg;
