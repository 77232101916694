import React, { Fragment, useEffect } from "react";
import { useState } from "react";
import "./ZeitPreiseTabelle.css";

function ZeitPreiseTabelle({ id }) {
    const directusURL = "https://admin.carolinpfeffer.de";
    const [data, setData] = useState();
    useEffect(() => {
        const options = { method: "GET" };

        fetch(directusURL + "/items/preistabellen/" + id, options)
            .then((response) => response.json())
            .then((response) => {
                setData(response.data);
            })
            .catch((err) => console.error(err));
    }, [id]);

    function removeAttributes(html) {
        return html.replace(/<[^>]*>/g, function (tag) {
            return tag.replace(/ [^=]+="[^"]*"/g, "");
        });
    }

    return (
        <Fragment>
            {data ? (
                <Fragment>
                    <h3 className="h4 h4--spaceing h4--border h4--center mb-7">{data.titel}</h3>
                    <div className="row w-100 justify-content-center mb-7">
                        <div className="col-12 ZeitPreiseTabelle__Container" dangerouslySetInnerHTML={{ __html: removeAttributes(data.tabelle) }}></div>
                    </div>
                </Fragment>
            ) : null}
        </Fragment>
    );
}

export default ZeitPreiseTabelle;
