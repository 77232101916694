import React, { Fragment, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import BlockControls from "../components/BlockControls";
import Blog__fullSizeThumb from "../components/Blog__fullSizeThumb";
import Blog__halfSizeThumb from "../components/Blog__halfSizeThumb";
import ContentBlock1 from "../components/ContentBlock1";
import Footer from "../components/Footer";
import Header from "../components/Header";
import ScrollToTop from "../components/ScrollToTop";
import SocialIcons from "../components/SocialIcons";
import "./Blog.css";
import NewsletterFormular from "../components/NewsletterFormular";

function Blog() {
    const blockControlsData = [
        {
            titel: "News & Termine",
            blockId: "b1",
        },
        {
            titel: "Neuster Beitrag",
            blockId: "b2",
        },
        {
            titel: "Aktuelle News",
            blockId: "b3",
        },
        {
            titel: "Meine Rezepte",
            blockId: "b4",
        },
        {
            titel: "Aktuelle Events",
            blockId: "b5",
        },
        {
            titel: "Newsletter",
            blockId: "newsletter",
        },
        {
            titel: "Kontakt",
            blockId: "b7",
        },
    ];

    const [schema, setSchema] = useState("light");
    const [schemaHeader, setSchemaHeader] = useState("light");

    useEffect(() => {
        window.addEventListener("scroll", () => {
            if (window.scrollY > 300) {
                setSchema("dark");
            } else {
                setSchema("light");
            }
        });
    }, []);

    // get all Blog Posts

    const directusURL = "https://admin.carolinpfeffer.de";

    const [blogPosts, setBlogPosts] = useState(null);

    useEffect(() => {
        const options = { method: "GET" };

        fetch(directusURL + "/items/Blogbeitraege?filter[status]=published&sort=-Datum&limit=1", options)
            .then((response) => response.json())
            .then((response) => {
                setBlogPosts(response["data"]);
            })
            .catch((err) => console.error(err));
    }, []);

    const [blogPostsNews, setBlogPostsNews] = useState(null);

    useEffect(() => {
        const options = { method: "GET" };

        fetch(directusURL + "/items/Blogbeitraege?filter[status]=published&sort=-Datum&limit=2&filter[Kategorie]=News&page=1", options)
            .then((response) => response.json())
            .then((response) => {
                setBlogPostsNews(response["data"]);
            })
            .catch((err) => console.error(err));
    }, []);

    const [currentNewsPage, setCurrentNewsPage] = useState(2);

    function loadNextPageNews() {
        setCurrentNewsPage(currentNewsPage + 1);

        const options = { method: "GET" };

        fetch(directusURL + "/items/Blogbeitraege?filter[status]=published&sort=-Datum&limit=2&filter[Kategorie]=News&page=" + currentNewsPage, options)
            .then((response) => response.json())
            .then((response) => setBlogPostsNews((blogPostsNews) => [...blogPostsNews, ...response["data"]]))
            .catch((err) => console.error(err));
    }

    // Blog Posts Rezept

    const [blogPostsRezept, setBlogPostsRezept] = useState(null);

    useEffect(() => {
        const options = { method: "GET" };

        fetch(directusURL + "/items/Blogbeitraege?filter[status]=published&sort=-Datum&limit=2&filter[Kategorie]=Rezept&page=1", options)
            .then((response) => response.json())
            .then((response) => {
                setBlogPostsRezept(response["data"]);
            })
            .catch((err) => console.error(err));
    }, []);

    const [currentRezeptPage, setCurrentRezeptPage] = useState(2);

    function loadNextPageRezept() {
        setCurrentRezeptPage(currentRezeptPage + 1);

        const options = { method: "GET" };

        fetch(directusURL + "/items/Blogbeitraege?filter[status]=published&sort=-Datum&limit=2&filter[Kategorie]=Rezept&page=" + currentRezeptPage, options)
            .then((response) => response.json())
            .then((response) => setBlogPostsRezept((blogPostsRezept) => [...blogPostsRezept, ...response["data"]]))
            .catch((err) => console.error(err));
    }

    // Blog Posts Event

    const [blogPostsEvent, setBlogPostsEvent] = useState(null);

    useEffect(() => {
        const options = { method: "GET" };

        fetch(directusURL + "/items/Blogbeitraege?filter[status]=published&sort=-Datum&limit=2&filter[Kategorie]=Event&page=1", options)
            .then((response) => response.json())
            .then((response) => {
                setBlogPostsEvent(response["data"]);
            })
            .catch((err) => console.error(err));
    }, []);

    const [currentEventPage, setCurrentEventPage] = useState(2);

    function loadNextPageEvent() {
        setCurrentEventPage(currentEventPage + 1);

        const options = { method: "GET" };

        fetch(directusURL + "/items/Blogbeitraege?filter[status]=published&sort=-Datum&limit=2&filter[Kategorie]=Event&page=" + currentEventPage, options)
            .then((response) => response.json())
            .then((response) => setBlogPostsEvent((blogPostsEvent) => [...blogPostsEvent, ...response["data"]]))
            .catch((err) => console.error(err));
    }

    return (
        <Fragment>
            <Helmet>
                <title>Blog | Carolin Pfeffer</title>
                <meta name="description" content="▶▶ Regelmäßige Updates auf meinem Blog: Leckere Rezepte ✓ aktuelle Events ✓ alle Neuigkeiten ✓ und vieles mehr ✓"></meta>
                <meta
                    name="keywords"
                    content="news carolin pfeffer,carolin pfeffer,blog carolin pfeffer,events saalburg,termine saalburg,termine saalburg ebersdorf,rezepte vegan,rezepte gesund,rezepte vegetarisch,rezepte fitness food"
                ></meta>
            </Helmet>
            <ScrollToTop />
            <Header schema={schemaHeader} />
            <SocialIcons schema={schema} />
            <BlockControls blockControlsData={blockControlsData} schema={schema} />
            <ContentBlock1
                titel="News & Termine"
                text="Hier schreibe ich euch regelmäßig alle Infos, Tipps und Tricks zu meinen aktuellen Aktionen und Events. Ausserdem findet ihr hier leckere Rezept zum Nachmachen. Ich wünsche euch viel Spaß."
                usps={[]}
                buttonText="zu den Beiträgen"
                buttonLink="#b2"
                backgroundImg="/img/BlogLandingBlock.webp"
                // <a href="https://de.freepik.com/fotos-kostenlos/luftaufnahme-eines-mannes-der-auf-einer-retro-schreibmaschine-tippt_11428117.htm#query=blog&position=5&from_view=search">Bild von rawpixel.com</a> auf Freepik
                alignBox="right"
                id={blockControlsData[0].blockId}
            />
            <div className="w-100" id={blockControlsData[1].blockId}>
                <div className="wrapper">
                    <div className="content d-flex flex-column align-items-center">
                        <span className="h4 h4--spaceing h4--border h4--center mb-7">Neuster Beitrag</span>
                        <div className="row w-100">
                            <div className="col-12">
                                {blogPosts && (
                                    <Blog__fullSizeThumb
                                        backgroundImage={directusURL + "/assets/" + blogPosts[0].Titelbild}
                                        title={blogPosts[0].Titel}
                                        category={blogPosts[0].Kategorie}
                                        date={blogPosts[0].Datum}
                                        exzerpt={blogPosts[0].Exzerpt}
                                        link={blogPosts[0].slug}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="w-100" id={blockControlsData[2].blockId}>
                <div className="wrapper">
                    <div className="content d-flex flex-column align-items-center">
                        <span className="h4 h4--spaceing h4--border h4--center mb-7">Aktuelle News</span>
                        <div className="row w-100">
                            {blogPostsNews &&
                                blogPostsNews.map((post, index) => {
                                    return (
                                        <div className="col-12 col-md-6" key={index}>
                                            <Blog__halfSizeThumb backgroundImage={directusURL + "/assets/" + post.Titelbild} title={post.Titel} category={post.Kategorie} date={post.Datum} exzerpt={post.Exzerpt} link={post.slug} />
                                        </div>
                                    );
                                })}
                        </div>
                        <span className="align-self-end mt-4 me-2" id="loadMoreNews" onClick={() => loadNextPageNews()}>
                            <span>ältere Beiträge laden</span>
                            &nbsp;
                            <i className="bi bi-arrow-down"></i>
                        </span>
                    </div>
                </div>
            </div>
            <div className="w-100" id={blockControlsData[3].blockId}>
                <div className="wrapper">
                    <div className="content d-flex flex-column align-items-center">
                        <span className="h4 h4--spaceing h4--border h4--center mb-7">Meine Rezepte</span>
                        <div className="row w-100">
                            {blogPostsRezept &&
                                blogPostsRezept.map((post, index) => {
                                    if (post["Kategorie"] === "Rezept") {
                                        return (
                                            <div className="col-12 col-md-6" key={index}>
                                                <Blog__halfSizeThumb backgroundImage={directusURL + "/assets/" + post.Titelbild} title={post.Titel} category={post.Kategorie} date={post.Datum} exzerpt={post.Exzerpt} link={post.slug} />
                                            </div>
                                        );
                                    }
                                })}
                        </div>
                        <span className="align-self-end mt-4 me-2" id="loadMoreRezept" onClick={() => loadNextPageRezept()}>
                            <span>weitere Rezepte laden</span>
                            &nbsp;
                            <i className="bi bi-arrow-down"></i>
                        </span>
                    </div>
                </div>
            </div>
            <div className="w-100 mb-6" id={blockControlsData[3].blockId}>
                <div className="wrapper">
                    <div className="content d-flex flex-column align-items-center">
                        <span className="h4 h4--spaceing h4--border h4--center mb-7">Aktuelle Events</span>
                        <div className="row w-100">
                            {blogPostsEvent &&
                                blogPostsEvent.map((post, index) => {
                                    if (post["Kategorie"] === "Event") {
                                        return (
                                            <div className="col-12 col-md-6" key={index}>
                                                <Blog__halfSizeThumb backgroundImage={directusURL + "/assets/" + post.Titelbild} title={post.Titel} category={post.Kategorie} date={post.Datum} exzerpt={post.Exzerpt} link={post.slug} />
                                            </div>
                                        );
                                    }
                                })}
                        </div>
                        <span className="align-self-end mt-4 me-2" id="loadMoreEvent" onClick={() => loadNextPageEvent()}>
                            <span>weitere Events laden</span>
                            &nbsp;
                            <i className="bi bi-arrow-down"></i>
                        </span>
                    </div>
                </div>
            </div>
            <NewsletterFormular id={blockControlsData[5].blockId}></NewsletterFormular>
            <Footer id={blockControlsData[6].blockId} />
        </Fragment>
    );
}

export default Blog;
