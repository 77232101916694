import React, { Fragment, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import BlockControls from "../components/BlockControls";
import Footer from "../components/Footer";
import Header from "../components/Header";
import ScrollToTop from "../components/ScrollToTop";
import SocialIcons from "../components/SocialIcons";

function AnmeldungErfolgreich() {
    const blockControlsData = [
        {
            titel: "Anmeldung erfolgreich",
            blockId: "b1",
        },
        {
            titel: "Kontakt",
            blockId: "b2",
        },
    ];

    const [schema, setSchema] = useState("dark");
    const [schemaHeader, setSchemaHeader] = useState("dark");

    return (
        <Fragment>
            <Helmet>
                <title>Anmeldung erfolgreich</title>
                <meta name="description" content=""></meta>
                <meta name="keywords" content=""></meta>
            </Helmet>
            <ScrollToTop />
            <Header schema={schemaHeader} />
            <SocialIcons schema={schema} />
            <BlockControls blockControlsData={blockControlsData} schema={schema} />
            <div className="w-100" id={blockControlsData[0].blockId}>
                <div className="wrapper">
                    <div className="content d-flex flex-column align-items-center justify-content-center h-100vh">
                        <span className="h4 h4--spaceing">Anmeldung erfolgreich</span>
                        <span className="d-block mb-4">Danke, du hast dich erfolgreich für meinen Newsletter angemeldet!</span>
                        <div className="row">
                            <div className="col-12">
                                <Link to="/" title="zur Startseite" className="btn-dark-primary">
                                    zur Startseite
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer id={blockControlsData[1].blockId} />
        </Fragment>
    );
}

export default AnmeldungErfolgreich;
