import React, { useEffect, useState } from "react";
import "./Reviews.css";
import ReviewsItem from "./ReviewsItem";

function Reviews({ url }) {
    const defaulData = [
        {
            author_name: "stefan walther",
            author_url: "https://www.google.com/maps/contrib/107172269243100584949/reviews",
            language: "de",
            original_language: "de",
            profile_photo_url: "",
            rating: 5,
            relative_time_description: "vor 6 Monaten",
            text: "Super organisiert, sehr freundlich und kompetent 👌",
            time: 1661319444,
            translated: false,
        },
        {
            author_name: "Tim B",
            author_url: "https://www.google.com/maps/contrib/113644584267437619361/reviews",
            language: "de",
            original_language: "de",
            profile_photo_url: "",
            rating: 5,
            relative_time_description: "vor 6 Monaten",
            text: "Sehr sehr nette Leute, welche mir super spontan und unkompliziert geholfen haben.\nObwohl ich eine Gehbehinderung habe, haben sie mir geholfen und mir eine super schöne Zeit auf dem Wasser ermöglicht. Geht also auch im sitzen!(:\nKann ich mir jedem empfehlen mal auszuprobieren- bei Carolin seid ihr in guten Händen!",
            time: 1661179955,
            translated: false,
        },
        {
            author_name: "Jens „adventure-jones-tours.de“ Hein",
            author_url: "https://www.google.com/maps/contrib/108500374668461905343/reviews",
            language: "de",
            original_language: "de",
            profile_photo_url: "",
            rating: 5,
            relative_time_description: "vor 8 Monaten",
            text: "Vielen vielen vielen Dank für die Rettung in der Not\nund die geschenkte Pumpe! Du hast meinen Tag gerettet! :-)))\nViel Erfolg und Freude wünsche ich dir und deinem Team am schönen Thüringer Meer!!!",
            time: 1654939999,
            translated: false,
        },
        {
            author_name: "Manuela P.",
            author_url: "https://www.google.com/maps/contrib/109391571904565771127/reviews",
            language: "de",
            original_language: "de",
            profile_photo_url: "",
            rating: 5,
            relative_time_description: "vor einem Jahr",
            text: "Carolin bereitet sich auf jede Yoga 🧘‍♂️oder Zirkeltraining Stunde🤸🏾‍♀️ perfekt vor. Ihr Wissen ist unglaublich. In den unterschiedlichen Kursen ist für jeden etwas dabei, ob Anfänger oder Fortgeschritten. Erlebnisse wie  SUP Yoya oder Yoga -Wanderungen, Stand-Up Paddling in den Sonnenuntergang bleiben unvergessen. Absolute Empfehlung😊. Danke für dein immer offenes Ohr und lieben Tipps.",
            time: 1620036234,
            translated: false,
        },
        {
            author_name: "Andre Steinig",
            author_url: "https://www.google.com/maps/contrib/103785553921927473665/reviews",
            language: "de",
            original_language: "de",
            profile_photo_url: "",
            rating: 5,
            relative_time_description: "vor 6 Monaten",
            text: "Super Service - nettes Team - absolut zuverlässig - jederzeit wieder\nHätte am liebsten 6* gegeben.",
            time: 1661259533,
            translated: false,
        },
    ];

    const [data, setData] = useState(defaulData);

    // const loadNewReviews = (e) => {
    //     e.preventDefault();
    //     getReviewInfo();
    //     e.target.style.display = "none";
    // };

    useEffect(() => {
        function getReviewInfo() {
            fetch(url)
                .then((data) => data.json())
                .then((resp) => {
                    setData(resp.result.reviews);
                })
                .catch((error) => console.error(error));
        }
        try {
            getReviewInfo();
        } catch (error) {
            console.error(error);
        }
    }, [url]);

    return (
        <div className="reviewContainer">
            {data ? (
                <ReviewsItem img={data[0].profile_photo_url} name={data[0].author_name} rating={data[0].rating} text={data[0].text} url={data[0].author_url} />
            ) : null}
            {data ? (
                <ReviewsItem img={data[1].profile_photo_url} name={data[1].author_name} rating={data[1].rating} text={data[1].text} url={data[1].author_url} />
            ) : null}
            {data ? (
                <ReviewsItem img={data[2].profile_photo_url} name={data[2].author_name} rating={data[2].rating} text={data[2].text} url={data[2].author_url} />
            ) : null}
            {data ? (
                <ReviewsItem img={data[3].profile_photo_url} name={data[3].author_name} rating={data[3].rating} text={data[3].text} url={data[3].author_url} />
            ) : null}
            {/* <form onSubmit={loadNewReviews} className="w-100 text-center">
                <input
                    type="submit"
                    className="mb-2"
                    value="aktuelle Google Bewertungen anzeigen"
                    style={{ color: "rgb(150, 150, 150)", borderColor: "rgb(150, 150, 150)" }}
                />
                <fieldset>
                    <input type="checkbox" className="me-2" id="reviewsDatenschutz" style={{ opacity: "0.5" }} required />
                    <label className="d-inline" htmlFor="reviewsDatenschutz" style={{ color: "rgb(150, 150, 150)" }}>
                        Ich habe die{" "}
                        <Link to={"/datenschutz"} title="Datenschutzbestimmungen">
                            Datenschutz&shy;bestimmungen
                        </Link>{" "}
                        gelesen und akzeptiere diese.
                    </label>
                </fieldset>
            </form> */}
            <div className="d-flex justify-content-center w-100">
                <a
                    href="https://www.google.com/search?q=Carolin+Pfeffer+SUP+Verleih+%26+Kurse&rlz=1C1FKPE_deDE954DE954&oq=Carolin+Pfeffer+SUP+Verleih+%26+Kurse&aqs=chrome..69i57j69i59j69i60l3.664j0j1&sourceid=chrome&ie=UTF-8#lrd=0x47a147a5c5483479:0xb093c9bf9d72149e,1,,,,"
                    target="_blank"
                    rel="noreferrer"
                    className="text-decoration-none"
                    style={{ color: "rgb(150, 150, 150)", fontSize: "1rem" }}
                >
                    Quelle: Google-Rezensionen <i className="bi bi-box-arrow-up-right" style={{ color: "rgb(150, 150, 150)", fontSize: "1rem" }}></i>
                </a>
            </div>
        </div>
    );
}

export default Reviews;
