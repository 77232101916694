import React from "react";
import "./ReviewsItem.css";

function ReviewsItem({ name, img, rating, text, url }) {
    let ratingList = ["bi-star", "bi-star", "bi-star", "bi-star", "bi-star"];
    for (let i = 0; i < rating; i++) {
        ratingList[i] = "bi-star-fill";
    }
    return (
        <div className="ReviewsItem">
            <div className="ReviewsItem__topGrid">
                <div className="topGrid__img">
                    {img !== "" ? <img src={img} alt="" className="topGrid__img" /> : null}
                    <span className="topGrid__img--placeholder">{name.slice(0, 1)}</span>
                </div>
                <span className="topGrid__name">{name}</span>
                <div className="topGrid__rating">
                    <i className={"rating__star bi " + ratingList[0]}></i>
                    <i className={"rating__star bi " + ratingList[1]}></i>
                    <i className={"rating__star bi " + ratingList[2]}></i>
                    <i className={"rating__star bi " + ratingList[3]}></i>
                    <i className={"rating__star bi " + ratingList[4]}></i>
                </div>
            </div>
            <p className="ReviewsItem__text">{text}</p>
            <a href={url} target="_blank" rel="noreferrer" title={"zur Google Bewertung von " + name} className="ReviewsItem__keepReading">
                Weiter auf Google lesen
            </a>
        </div>
    );
}

export default ReviewsItem;
