import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./ContentBlock1.css";
import DialogKontakt from "./DialogKontakt";

function ContentBlock1({
    titel,
    text,
    usps,
    buttonText,
    buttonLink,
    backgroundImg,
    alignBox,
    id,
    targetDialog,
}) {
    function scrollDown() {
        const contentBlock1 = document.querySelector(".ContentBlock1");
        window.scrollTo(0, contentBlock1.offsetHeight);
    }
    // scrollDown();

    const openDialog = () => {
        document.getElementById(targetDialog).showModal();
        document.body.style.overflowY = "hidden";
    };

    const [contentBlock1Height, setContentBlock1Height] = useState(0);

    useEffect(() => {
        setContentBlock1Height(
            document.querySelector(".ContentBlock1__box").offsetHeight
        );
        // console.log(contentBlock1Height);
    }, []);

    return (
        <Fragment>
            <div
                className="ContentBlock1"
                id={id}
                style={{
                    backgroundImage: 'url("' + backgroundImg + '")',
                    backgroundPosition:
                        alignBox === "right" ? "35% center" : "65% center",
                    minHeight:
                        "calc(100vh + " + contentBlock1Height / 3 + "px)",
                }}>
                <div className={"ContentBlock1__overlay " + alignBox}></div>
                <div className="wrapper h-100">
                    <div className="content h-100">
                        <div
                            className="ContentBlock1__box"
                            style={{ [alignBox]: "0" }}>
                            <span className="font-rollerscript text-initial fs-60px h2 d-block mb-3">
                                {titel}
                            </span>
                            <span className="d-block mb-3">{text}</span>
                            <ul className="box__usps mb-5">
                                {usps.map((data, idx) => (
                                    <li key={idx}>{data}</li>
                                ))}
                            </ul>
                            {buttonLink.includes("#") ? (
                                <a
                                    href={buttonLink}
                                    title={buttonText}
                                    className="btn-dark-white">
                                    {buttonText}
                                </a>
                            ) : null}
                            {buttonLink === "dialog" ? (
                                <input
                                    type="button"
                                    className="btn-dark-white"
                                    value={buttonText}
                                    onClick={openDialog}
                                />
                            ) : null}
                            {!buttonLink.includes("#") &&
                            !buttonLink === "dialog" ? (
                                <Link
                                    to={buttonLink}
                                    title={buttonText}
                                    className="btn-dark-white">
                                    {buttonText}
                                </Link>
                            ) : null}
                        </div>
                    </div>
                </div>
                <div className="ContentBlock1__bottom" onClick={scrollDown}>
                    <i className="bi bi-arrow-down"></i>
                </div>
            </div>

            <DialogKontakt />
        </Fragment>
    );
}

export default ContentBlock1;
