import React from "react";
import { Link } from "react-router-dom";
import "./Blog__halfSizeThumb.css";

function Blog__halfSizeThumb({ backgroundImage, title, category, date, exzerpt, link }) {
    return (
        <div
            className="Blog__halfSizeThumb mb-4"
            style={{
                backgroundImage: 'url("' + backgroundImage + '")',
            }}
            onClick={() => (window.location = "/blog/" + link)}
        >
            <div className="halfSizeThumb__textContainer">
                <span className="h2">{title}</span>
                <span className="d-block mb-2">
                    <span className="text-uppercase">{category}</span>
                    <span>&nbsp;&#45;&nbsp;</span>
                    <span>{date.split("-").reverse().join(".")}</span>
                </span>
                <span>
                    <span>{exzerpt.slice(0, 100)} ...&nbsp;</span>
                    <Link to={link} title={"zu" + title}>
                        mehr lesen
                    </Link>
                </span>
            </div>
        </div>
    );
}

export default Blog__halfSizeThumb;
