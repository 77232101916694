import React, { Fragment, useEffect, useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import BlockControls from "../components/BlockControls";
import SocialIcons from "../components/SocialIcons";
import ContentBlock1 from "../components/ContentBlock1";
import ScrollToTop from "../components/ScrollToTop";
import "./Firmenfitness.css";
import { Helmet } from "react-helmet-async";
import ContentBlockGallery from "../components/ContentBlockGallery";
import Reviews from "../components/Reviews";
import NewsletterFormular from "../components/NewsletterFormular";

function Firmenfitness() {
    const blockControlsData = [
        {
            titel: "Vorteile von Firmenfitness",
            blockId: "b1",
        },
        {
            titel: "BGM / Firmenfitness",
            blockId: "b2",
        },
        {
            titel: "Workshops, Events & Vorträge",
            blockId: "b3",
        },
        {
            titel: "Der Gesundheitstag",
            blockId: "b4",
        },
        {
            titel: "Newsletter",
            blockId: "newsletter",
        },
        {
            titel: "Kontakt",
            blockId: "b6",
        },
    ];

    const [schema, setSchema] = useState("light");
    const [schemaHeader, setSchemaHeader] = useState("light");

    useEffect(() => {
        window.addEventListener("scroll", () => {
            if (window.scrollY > 300) {
                setSchema("dark");
            } else {
                setSchema("light");
            }
        });
    }, []);

    return (
        <Fragment>
            <Helmet>
                <title>BGM / Firmenfitness | Carolin Pfeffer</title>
                <meta name="description" content=""></meta>
                <meta name="keywords" content=""></meta>
            </Helmet>
            <ScrollToTop />
            <Header schema={schemaHeader} />
            <SocialIcons schema={schema} />
            <BlockControls blockControlsData={blockControlsData} schema={schema} />
            <ContentBlock1
                titel="BGM / Firmenfitness "
                text="Betriebliches Gesundheitsmanagement zählt heute nachweislich zu den Erfolgsfaktoren von führenden Unternehmen. "
                usps={[
                    "Reduzierung von Krankentagen",
                    "Reduzierung von Arbeitsunfällen",
                    "Höhere Produktivität",
                    "Bindung der Mitarbeiter*innen an den Betrieb stärken",
                    "Stresslevel senken",
                ]}
                buttonText="jetzt kontaktieren"
                buttonLink="dialog"
                targetDialog="DialogKontakt"
                backgroundImg="/img/Content_Firmenfitness.webp"
                //<a href="https://de.freepik.com/fotos-kostenlos/fluss-umgeben-von-waeldern-unter-einem-bewoelkten-himmel-in-thueringen-in-deutschland_13291760.htm#query=outdoor&position=11&from_view=search">Image by wirestock</a> on Freepik
                alignBox="right"
                id={blockControlsData[0].blockId}
            />
            <div className="w-100">
                <div className="wrapper">
                    <div className="content d-flex flex-column align-items-center justify-content-evenly">
                        <h2 className="h4 h4--spaceing h4--border h4--center mb-7">Ich biete BGM / Firmenfitness in den folgenden Formen an</h2>
                        <div className="row w-100 mb-4">
                            <div className="col d-flex flex-column justify-content-evenly text-uppercase mb-5">
                                <img src="/img/Content_PersonalTraining.svg" alt="Personal Training Icon" className="contentSpecItem mb-4" />
                                <span className="d-block text-center">Personal Training</span>
                            </div>
                            <div className="col d-flex flex-column justify-content-evenly text-uppercase mb-5">
                                <img src="/img/Content_GruppenTraining.svg" alt="Gruppen Training Icon" className="contentSpecItem mb-4" />
                                <span className="d-block text-center">Gruppen Training</span>
                            </div>
                            <div className="col d-flex flex-column justify-content-evenly text-uppercase mb-5">
                                <img src="/img/Content_Workshops.svg" alt="Workshops Icon" className="contentSpecItem mb-4" />
                                <span className="d-block text-center">Workshops</span>
                            </div>
                            <div className="col d-flex flex-column justify-content-evenly text-uppercase mb-5">
                                <img src="/img/Content_Vortraege.svg" alt="Vorträge Icon" className="contentSpecItem mb-4" />
                                <span className="d-block text-center">Vorträge</span>
                            </div>
                        </div>
                        <div className="row mb-6" id={blockControlsData[1].blockId}>
                            <div className="col-12">
                                <h3 className="d-block h4 h4--spaceing mb-4">BGM / Firmenfitness</h3>
                                <p className="mb-4">
                                    Die Erhaltung und Förderung der Mitarbeitergesundheit erzeugt positive wirtschaftlichen Ergebnisse für das ausführende
                                    Unternehmen. Gerade bei sehr einseitigen Bewegungsabläufen im Arbeitsalltag, ist es nochmal umso wichtiger, eine gesunde
                                    Balance zu etablieren. Hier bietet es sich an, den Mitarbeitern einen leicht zugänglichen und positiven Weg in eine
                                    aktivere, sportlichere und gesündere Lebensweise anzubieten, um aufzuzeigen, wie damit auch der Arbeitsalltag besser
                                    bewältigt werden kann. Somit gilt es hier nicht nur dem Körper positive Reize zu setzen, sondern auch dem Geist. Ich halte
                                    eine umfassende, ganzheitliche Betrachtungsweise des einzelnen Individuums für die beste Lösung, um Ziele nachhaltig zu
                                    erreichen.
                                </p>
                                <p>
                                    Im heutigen Arbeitsalltag geht es nicht nur darum, einen gesunden Rücken zu haben oder gesund zu essen. Eine ganzheitliche
                                    Betrachtung zu vermitteln ist ein wichtiger Grundansatz, um die Mitarbeiter langfristig dazu zu bewegen, an ihrer Gesundheit
                                    arbeiten zu wollen. Eine lebendige Arbeitsumgebung zu erschaffen, die inspiriert, verbindet und persönliche wie auch
                                    unternehmerische Bedürfnisse erfüllt. Vorbeugung ist der Schlüssel zu nachhaltigem Erfolg.
                                </p>
                            </div>
                        </div>
                        <div className="row mb-6">
                            <div className="col-12">
                                <h4 className="d-block fw-bold mb-4 fs-5">Davon profitieren Mitarbeiter und das Unternehmen</h4>
                            </div>
                            <div className="col-12">
                                <ul className="ul--usp">
                                    <li>Bewegungskurse zur Aufrichtung, Mobilisierung und Stärkung der verschiedenen Muskelpartien</li>
                                    <li>
                                        Aufbau von Handlungswissen und Vermittlung von Tipps und Übungen durch Ergonomieschulung und Training Neuer Rückenschule
                                    </li>
                                    <li>Entspannungskurse zur Förderung der Körperwahrnehmung, Stressbewältigung und Konzentration</li>
                                    <li>
                                        Aktive Pausen (z.B. bewegte Pause, BrainFormation) als erfrischende Abwechslung für Körper und Geist – auch ohne zu
                                        Schwitzen
                                    </li>
                                    <li>Workshops zur Stressbewältigung, Resilienz und Achtsamkeit</li>
                                </ul>
                            </div>
                        </div>
                        <div className="row mb-6" id={blockControlsData[2].blockId}>
                            <div className="col-12">
                                <h3 className="d-block h4 h4--spaceing mb-4">Workshops, Events & Vorträge</h3>
                                <p className="mb-4">
                                    Workshops, Vorträge, Events bieten eine wunderbare Möglichkeit tiefer in ein Thema einzusteigen, sowohl theoretisch und
                                    praktisch. In meinen Workshops finden Menschen mit denselben Interessen zusammen, verbringen eine gute Zeit mit
                                    konstruktivem Austausch und gehen am Ende mit neuen Eindrücken, Erfahrungen und reichlich Wissen nach Hause.
                                </p>
                                <p>
                                    Ich biete Workshops zum Thema Yoga, Fitness, Ernährung, Veganer Ernährung, Fasten, Meditation, Stressbewältigung,
                                    Darmgesundheit, u.v.m. Egal ob Du neue Ideen suchst, Dich inspirieren lassen möchtest oder tiefer in ein Thema eintauchen
                                    willst, bei mir findet sich sicher das richtige Angebot. Bei Firmenevents, Festivals und anderen Veranstaltungen rund um die
                                    Gesundheit trage ich gern meinen Teil dazu bei, um die Veranstaltung zu einem Highlight werden zu lassen.
                                </p>
                            </div>
                        </div>
                        <div className="row" id={blockControlsData[3].blockId}>
                            <div className="col-12">
                                <h3 className="d-block h4 h4--spaceing mb-4">Der Gesundheitstag für ihr Unternehmen</h3>
                                <p className="mb-4">
                                    Nutzen Sie die Möglichkeit eines Gesundheitstages, um den Mitarbeitern einen Impuls zu geben und deren Möglichkeiten
                                    aufzuzeigen, wie sie ihr körperliches und geistiges Wohlbefinden am Arbeitsplatz und in ihrer Freizeit fördern können, um so
                                    einen gesünderen Lebensstil zu erschaffen.
                                </p>
                                <div className="row mb-4">
                                    <div className="col-12 col-md-6 mb-3">
                                        <ul className="ul--usp">
                                            <li>Angestellte für einen gesunden Lebensstil sensibilisieren</li>
                                            <li>Bewegung, Resilienz und Entspannung etablieren</li>
                                            <li>Verbesserte Haltung am Arbeitsplatz</li>
                                            <li>neues Wissen vermitteln, um langfristig die persönlichen Gesundheitskompetenzen zu stärken</li>
                                        </ul>
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <ul className="ul--usp">
                                            <li>Gesundheitsrisiken vorbeugen und die Gesunderhaltung ein Leben lang zu fördern</li>
                                            <li>Mitarbeiterzufriedenheit stärken</li>
                                            <li>„Wir“ Gefühl stärken, Bindung ans Unternehmen stärken</li>
                                            <li>Interne Unternehmenskultur stärken</li>
                                        </ul>
                                    </div>
                                </div>
                                <p>
                                    Die Wichtigkeit, die Komplexität und die Wirkung von Gesundheit für jeden Teilnehmer greifbar zu machen, das ist das Ziel
                                    eines solchen Events. Inspirierende Workshops mit Theorie und Praxis sollen diese Veranstaltung nicht zu einem einmalig
                                    Ereignis werden lassen, sondern langfristig Mehrwert schaffen. Die Mitarbeiter können an dem Tag in Gruppen oder auch in
                                    Einzelgesprächen persönlich erfahren, was ihnen Spaß macht und verschiedene Möglichkeiten nutzen, um sich selbst
                                    auszuprobieren.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="w-100">
                <div className="wrapper">
                    <div className="content">
                        <h4 className="h4 h4--spaceing h4--border h4--center mb-7">Das sagen meine Kunden</h4>
                        <Reviews url={"/cronjobs/googleReviewsPersonal.json"} />
                    </div>
                </div>
            </div>
            <NewsletterFormular id={blockControlsData[4].blockId}></NewsletterFormular>
            <Footer id={blockControlsData[5].blockId} />
        </Fragment>
    );
}

export default Firmenfitness;
