import React from "react";
import "./ContentBlockGallery.css";

function ContentBlockGallery({ id, img1, img2, img3, img4 }) {
    return (
        <div className="wrapper" id={id}>
            <div className="content">
                <div className="row">
                    <div className="col-12">
                        <h3 className="h4 h4--spaceing h4--border h4--center mb-7">Impressionen</h3>
                        <div className="ContentBlockGallery">
                            <div
                                style={{
                                    backgroundImage: 'url("' + img1 + '")',
                                }}
                            ></div>
                            <div
                                style={{
                                    backgroundImage: 'url("' + img2 + '")',
                                }}
                            ></div>
                            <div
                                style={{
                                    backgroundImage: 'url("' + img3 + '")',
                                }}
                            ></div>
                            <div
                                style={{
                                    backgroundImage: 'url("' + img4 + '")',
                                }}
                            ></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ContentBlockGallery;
