import React from "react";
import { Link } from "react-router-dom";
import "./Blog__fullSizeThumb.css";

function Blog__fullSizeThumb({ backgroundImage, title, category, date, exzerpt, link }) {
    return (
        <div
            className="Blog__fullSizeThumb"
            style={{
                backgroundImage: "url(" + backgroundImage + ")",
            }}
        >
            <div className="fullSizeThumb__textContainer">
                <span className="h2">{title}</span>
                <span className="d-block mb-2">
                    <span className="text-uppercase">{category}</span>
                    <span>&nbsp;&#45;&nbsp;</span>
                    <span>{date.split("-").reverse().join(".")}</span>
                </span>
                <span>
                    <span>{exzerpt.slice(0, 100)} ...&nbsp;</span>
                    <Link to={link} title={"zu" + title}>
                        mehr lesen
                    </Link>
                </span>
            </div>
        </div>
    );
}

export default Blog__fullSizeThumb;
