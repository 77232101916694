import React, { Fragment, useEffect, useLayoutEffect, useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import BlockControls from "../components/BlockControls";
import SocialIcons from "../components/SocialIcons";
import ContentBlock1 from "../components/ContentBlock1";
import ContentBlock2 from "../components/ContentBlock2";
import ScrollToTop from "../components/ScrollToTop";
import LoadingAnimation from "../components/LoadingAnimation";
import { Helmet } from "react-helmet-async";
import InstagramFeed from "../components/InstagramFeed";
import { Link } from "react-router-dom";
import Reviews from "../components/Reviews";
import NewsletterFormular from "../components/NewsletterFormular";

function UeberMich() {
    const blockControlsData = [
        {
            titel: "Über mich",
            blockId: "b1",
        },
        {
            titel: "Meine Philosophie",
            blockId: "b2",
        },
        {
            titel: "Vertrauen, Mut, ...",
            blockId: "b3",
        },
        {
            titel: "Instagram Galerie",
            blockId: "b4",
        },
        {
            titel: "Newsletter",
            blockId: "newsletter",
        },
        {
            titel: "Kontakt",
            blockId: "b6",
        },
    ];

    const [schema, setSchema] = useState("light");
    const [schemaHeader, setSchemaHeader] = useState("light");

    useEffect(() => {
        window.addEventListener("scroll", () => {
            if (window.scrollY > 300) {
                setSchema("dark");
            } else {
                setSchema("light");
            }
        });
    }, []);

    const [instagramFeed_visible, setInstagramFeed_visible] = useState(false);

    const loadInstagramFeed = (e) => {
        e.preventDefault();
        setInstagramFeed_visible(true);
    };

    // instafeed data laden

    const directusURL = "https://admin.carolinpfeffer.de";

    const [instafeedData, setInstafeedData] = useState();

    useEffect(() => {
        const options = { method: "GET" };

        fetch(directusURL + "/items/instafeed", options)
            .then((response) => response.json())
            .then((response) => {
                setInstafeedData(response["data"]);
            })
            .catch((err) => console.error(err));
    }, []);

    // instagram token aus lokaler json laden (druch cronjob generiert)

    const [instafeedTokenLocal, setInstafeedTokenLocal] = useState();

    useEffect(() => {
        const options = { method: "GET" };

        fetch("/cronjobs/access_token.json", options)
            .then((response) => response.json())
            .then((response) => {
                setInstafeedTokenLocal(response["access_token"]);
            })
            .catch((err) => console.error(err));
    }, []);

    return (
        <Fragment>
            <Helmet>
                <title>Über mich | Carolin Pfeffer</title>
                <meta name="description" content="Carolin Pfeffer – Fitnessökonomin B.A. aus Saalburg. Personal Trainerin ✓ Gruppenkurse ✓ Fitness &amp; Yoga ✓ Ernährungsberaterin ✓"></meta>
                <meta
                    name="keywords"
                    content="carolin pfeffer,carolin pfeffer sport,carolin pfeffer yoga,carolin pfeffer fitness,carolin pfeffer saalburg,carolin pfeffer saalburg ebersdorf,carolin pfeffer schleiz,carolin pfeffer thüringen,carolin pfeffer stand up paddling,carolin pfeffer bleilochtalsperre"
                ></meta>
            </Helmet>
            <ScrollToTop />
            {/* <LoadingAnimation /> */}
            <Header schema={schemaHeader} />
            <SocialIcons schema={schema} />
            <BlockControls blockControlsData={blockControlsData} schema={schema} />
            <ContentBlock1
                titel="Über mich"
                text="Ich bin Carolin Pfeffer aus Saalburg im wunderschönen Thüringer Schiefergebirge, am größten Stausee Deutschlands. Als deine persönliche Coachin stehe ich dir mit einer ganzheitlichen Betreuung zur Seite. Denn du bist nicht nur eine Zahl auf der Waage, ein Schmerz, der täglich auftaucht, oder der tiefe Wunsch, kraftvoller und mit mehr Kondition deinen Alltag zu bestreiten. Deine Gesundheit ist ein Gesamtpaket aus dem richtigen Mindset, einer gesunden Ernährung und Bewegung. Wenn du deine Ziele in Angriff nehmen willst, bin ich deine perfekte Ansprechpartnerin. Nimm gern gleich Kontakt auf per WhatsApp oder Anruf."
                usps={[]}
                buttonText="Hier Kontakt aufnehmen"
                buttonLink="dialog"
                targetDialog="DialogKontakt"
                backgroundImg="/img/StartseiteLandingBlock_1.webp"
                alignBox="right"
                id={blockControlsData[0].blockId}
            />
            <ContentBlock2
                icon=""
                titel="Meine Philosophie"
                subTitel="Dein Leben"
                text='„Hüte dich vor den Wilddruden und den Graugnomen und den Borkaräubern«, sagte er.
                    „Woher soll ich wissen, wer die Wilddruden und die Graugnomen und die Borkaräuber
                    sind?“ fragte Ronja. „Das merkst du schon“, antwortete Mattis. „Na, dann“, sagte Ronja.
                    
                    „Und dann hütest du dich davor, dich im Wald zu verirren“, sagte Mattis. „Was tu ich, wenn
                    ich mich im Wald verirre?“ fragte Ronja. „Suchst dir den richtigen Pfad“, antwortete Mattis.
                    „Na, dann“, sagte Ronja.
                    
                    „Und dann hütest du dich davor, in den Fluss zu plumpsen“, sagte Mattis. „Und was tu ich,
                    wenn ich in den Fluss plumpse?“ fragte Ronja. „Schwimmst“, sagte Mattis. „Na, dann“,
                    sagte Ronja. (…)
                    
                    „Sonst noch was?“ „Oh ja“, sagte Mattis. „Aber das merkst du schon selber so allmählich.
                    Geh jetzt!“
                    
                    (Quelle: Astrid Lindgren „Ronja Räubertochter“, Oetinger Verlag)"'
                zitat="Wenn du die Absicht hast dich zu erneuern, tu es jeden Tag – Konfuzius"
                id={blockControlsData[1].blockId}
            />
            <div className="w-100" id={blockControlsData[2].blockId}>
                <div className="wrapper">
                    <div className="content d-flex flex-column align-items-center justify-content-evenly">
                        <h2 className="h4 h4--spaceing h4--border h4--center mb-7">Vertrauen, Mut, Disziplin, intrinsische Motivation</h2>
                        <div className="row">
                            <div className="col-12 mb-6">
                                <p className="mb-4">
                                    Als Kind war ich begeistert von Pippi Langstrumpf und Ronja Räubertochter. Wünsche und Träume einfach realisieren, nicht die Probleme, sondern die Lösungen sehen, mutig sein und Mut machen. Dass du hier
                                    bist und diesen Text liest, zeigt, dass du auf deinem Weg bist und einen Begleiter suchst, der dir Mut macht und dir Hoffnung schenkt, wenn es dir unmöglich scheint, jemanden, der das Licht anmacht, wenn
                                    es um dich dunkel ist, dir die Hand reicht, wenn das Aufstehen schwerfällt.
                                </p>
                                <p className="mb-4">
                                    Genauso wie Ronja und Pippi bin ich ehrgeizig, emphatisch, zielstrebig, direkt, eine Optimistin & Kämpferin, voll mit Visionen und Ideen. Als deine Personal-Coachin werde ich dir zur Seite stehen,
                                    bergauf-bergab, lachend-weinend und steht's mit einem offenen Ohr für dich und deine Anliegen. Mit meinem Wissen und meiner Erfahrung erwachsen aus dem Studium zur B.A. Fitnessökonomin, Yogalehrerin,
                                    Ernährungsberaterin, vielen Weiterbildungen und mittlerweile 12 Jahren als Coach erreichen wir gemeinsam Schritt für Schritt dein Ziel.
                                </p>
                                <p className="mb-4">
                                    Du wirst nicht immer Lust haben oder motiviert sein. Oft wirst Du übers Aufgeben nachdenken und die Couch wird Dir augenscheinlich verlockendere Angebote machen als ich es tue. Aber sei Dir gewiss, all
                                    die Arbeit und der Schweiß, die Disziplin, welche anstelle von Motivation einspringt, die vielen Tage, die scheinbar keinen Fortschritt bringen, werden Dir schlussendlich ein Ergebnis liefern, wie Du es
                                    Dir schöner nicht hättest erträumen können. Nichts geht über einen gesunden, starken, beweglichen, Alltags-resistenten Körper.
                                </p>
                                <p className="mb-4">Durch Aufklärung, Motivation und Inspiration möchte ich dir die Wege zu deinem beschwerdefreien, gesunden und fitten Leben zeigen.</p>
                                <p className="mb-4">
                                    Der Alltag stellt uns fast täglich Hürden in den Weg, die uns von Jahr zu Jahr schwerer fallen zu überwinden. Ich weiß wie schwierig es sein kann, neben der Sorge um Liebsten, die Arbeit und den ganz
                                    normalen Alltagswahnsinn, die Wichtigkeit sich um sich selbst zu kümmern, im Auge zu behalten. Doch wenn du jetzt nicht in deine Gesundheit investierst, wirst du später womöglich in deine Krankheit
                                    investieren müssen.
                                </p>
                                <p className="mb-4">
                                    Gesundheit ist nicht nur das intakte Skelett, die kräftigen Muskeln, die Abstinenz von Krankheit, schöne Haut, eine gute Verdauung. Gesundheit ist komplex und betrifft den Mensch auf physischer und
                                    psychischer Ebene. Wenn du gesund und leistungsfähig bist, bist du resistent gegen die Belastungen des Alltags. Gesundheit ist etwas Ganzheitliches und das ist mein Ansatz. Fitness, mentale Gesundheit und
                                    Ernährung. Wir finden gemeinsam ein Konzept, was für dich passt, alle Komponenten in Balance bringt und damit nachhaltige Ergebnisse für dein Leben schafft.
                                </p>
                                <h4 className="d-block fw-bold mb-3 fs-5">Deine Vorteile:</h4>
                                <ul className="ul--usp">
                                    <li className="color-primary-dark">Ich betrachte Deine Anliegen ganzheitlich und individuell: Fitness, mentale Gesundheit und Ernährung gehen Hand in Hand</li>
                                    <li className="color-primary-dark">Mit dem ganzheitlichen Ansatz wirst Du langfristig Erfolg haben</li>
                                    <li className="color-primary-dark">Du gestaltest deinen Alltag mit mehr Energie und einem leistungsfähigeren Körper und Geist</li>
                                </ul>
                            </div>
                            <div className="col-12">
                                <h4 className="d-block fw-bold mb-2 fs-5">Kompetenzen & Werdegang</h4>
                                <p>
                                    Als Dorfkind meiner Generation, ist es fast unmöglich gewesen, sich nicht den ganzen Tag draußen im Wald, Wasser und auf Bäumen aufgehalten zu haben. Aufgewachsen am Bleilochstausee war ich schon immer
                                    vernarrt in den Wassersport. Aber auch mit dem Bike stundenlang die Wälder des Thüringer Schiefergebirges zu erkunden und das frühzeitig lieb gewonnene funktionelle Training hielten stets das Feuer in mir
                                    am lodern. In der Schulzeit nahm ich an allen Sportevents/Wettkämpfen teil. Leider hat mich als Teenager bereits eine langwierige Knieverletzung für einige Zeit lahmgelegt und ich hatte schwer mit meinem
                                    gesunkenen Leistungsniveau zu kämpfen. Ich kann sehr gut nachfühlen, wie sich der Kampf mit der körperlichen Fitness anfühlt. Nach meinem Studium zur B.A. Fitnessökonomin begann ein ununterbrochener
                                    Weiterbildungsmarathon. Nach dem Motto „Wer rastet, der rostet“. Ich bin der Meinung, dass man nie auslernt und sich deshalb stetig um neues Wissen bemühen sollte. Gerade in der Gesundheitsbranche bringt
                                    die Forschung ständig neues Licht ins Dunkle. Sei dir also sicher, dass ich bemüht bin, dich immer auf dem aktuellsten Stand der Dinge zu beraten.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="w-100" id={blockControlsData[3].blockId}>
                <div className="wrapper">
                    <div className="content d-flex flex-column align-items-center justify-content-evenly">
                        <span className="h4 h4--spaceing h4--border h4--center mb-7">Aus meinem Instagram Feed</span>
                        <div className="row">
                            <div className="col-12">
                                {!instagramFeed_visible ? (
                                    <div className="instafeed--placeholder">
                                        <div className="instafeed--placeholder__pb">
                                            {instafeedData ? (
                                                <img
                                                    src={directusURL + "/assets/" + instafeedData.profilbild}
                                                    alt="Instagram Profilbild"
                                                    onClick={() => (window.location = "https://www.instagram.com/carolin.pfeffer/")}
                                                    title="Carolin auf Instagram"
                                                />
                                            ) : null}
                                        </div>
                                        <form onSubmit={loadInstagramFeed}>
                                            <input type="submit" className="mb-2" value="Instagram Posts anzeigen" />
                                            <fieldset>
                                                <input type="checkbox" className="me-2" id="instagramDatenschutz" required />
                                                <label className="d-inline" htmlFor="instagramDatenschutz">
                                                    Ich habe die{" "}
                                                    <Link to={"/datenschutz"} title="Datenschutzbestimmungen">
                                                        Datenschutz&shy;bestimmungen
                                                    </Link>{" "}
                                                    gelesen und akzeptiere diese.
                                                </label>
                                            </fieldset>
                                        </form>
                                    </div>
                                ) : (
                                    <InstagramFeed accessToken={instafeedTokenLocal} />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="w-100">
                <div className="wrapper">
                    <div className="content">
                        <h4 className="h4 h4--spaceing h4--border h4--center mb-7">Das sagen meine Kunden</h4>
                        <Reviews url={"/cronjobs/googleReviewsPersonal.json"} />
                    </div>
                </div>
            </div>
            <NewsletterFormular id={blockControlsData[4].blockId}></NewsletterFormular>
            <Footer id={blockControlsData[5].blockId} />
        </Fragment>
    );
}

export default UeberMich;
