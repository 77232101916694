import React, { useRef } from "react";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./Footer.css";

function Footer({ id }) {
    const [footer_karte_visible, setFooter_karte_visible] = useState(false);
    const [footer_credits, setFooter_credits] = useState("");

    const form = useRef();

    // send email, update send button text and reset inputs
    const sendEmail = (e) => {
        e.preventDefault();
        let elem = e.target;
        let dataProt = elem[3].checked;

        if (!dataProt) return;

        let data = {
            name: elem[0].value,
            email: elem[1].value,
            msg: elem[2].value,
            dataProt,
        };

        elem[0].disabled = true;
        elem[1].disabled = true;
        elem[2].disabled = true;
        elem[3].disabled = true;
        elem[4].disabled = true;

        elem[4].value = "sendet ...";

        console.log(data);

        const options = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data),
        };

        fetch("https://admin.carolinpfeffer.de/flows/trigger/989456ca-23b9-4d17-87c7-08d9957361e9", options)
            .then(() => {
                elem[0].disabled = false;
                elem[0].value = null;

                elem[1].disabled = false;
                elem[1].value = null;

                elem[2].disabled = false;
                elem[2].value = null;

                elem[3].disabled = false;
                elem[3].checked = false;

                // setTimeout(() => {
                elem[4].disabled = false;
                elem[4].value = "gesendet";
                // }, 5000);
            })
            .catch((err) => console.error(err));
    };

    const loadMap = (e) => {
        e.preventDefault();
        setFooter_karte_visible(true);
    };

    useEffect(() => {
        fetch("https://api.ment.works/items/impressum_credit", {
            mode: "cors",
        })
            .then((response) => response.json())
            .then((data) => setFooter_credits(data["data"][1]));
    }, []);

    return (
        <footer className="footer" id={id}>
            <div className="wrapper">
                <div className="content content--narrow">
                    <div className="row">
                        <div className="col-md-6 mb-6">
                            <span className="text-initial font-rollerscript d-block fs-60px h2 mb-5">Kontakt</span>
                            <span className="d-block mb-6">
                                <span className="h4 fs-5">Studio</span>
                                <span className="d-block">
                                    Sperrmauer 6 <br /> 07907 Gräfenwarth
                                </span>
                            </span>
                            <span className="d-block mb-6">
                                <span className="h4 fs-5">Büro</span>
                                <span className="d-block">
                                    Tummelgasse 95 <br /> 07929 Saalburg-Ebersdorf
                                </span>
                            </span>
                            <span className="d-block">
                                <span className="d-block">
                                    <i className="bi bi-envelope-fill"></i>
                                    &nbsp;info@carolinpfeffer.de
                                </span>
                                <span className="d-block">
                                    <i className="bi bi-whatsapp"></i>
                                    &nbsp;+49(0)163 88 02 363
                                </span>
                            </span>
                        </div>
                        <div className="col-md-6 d-flex">
                            <form ref={form} onSubmit={sendEmail} className="my-auto">
                                <input type="text" name="kontakt__name" placeholder="Name" className="d-block w-100 mb-3" required />
                                <input type="email" name="kontakt__email" placeholder="E-Mail Adresse" className="d-block w-100 mb-5" required />
                                <textarea name="kontakt__message" rows="8" placeholder="Nachrichteninhalt" className="d-block w-100 mb-3" required></textarea>
                                <span className="d-block mb-3">
                                    <input type="checkbox" id="datenschutzCheckbox" className="me-2" required />
                                    <label className="d-inline" htmlFor="datenschutzCheckbox">
                                        Ich habe die{" "}
                                        <Link to={"/datenschutz"} title="Datenschutzbestimmungen">
                                            Datenschutzbestimmungen
                                        </Link>{" "}
                                        gelesen und akzeptiere diese.
                                    </label>
                                </span>
                                <input type="submit" className="btn-dark-primary float-end" id="button" value="absenden" />
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div className="wrapper h-100">
                <div className="footer__karte">
                    {!footer_karte_visible ? (
                        <div className="karte__dialog">
                            <form onSubmit={loadMap}>
                                <input type="submit" className="light mb-2" value="Karte anzeigen" />
                                <fieldset>
                                    <input type="checkbox" className="me-2" id="karteFooterDatenschutz" required />
                                    <label className="d-inline color-background" htmlFor="karteFooterDatenschutz">
                                        Ich habe die{" "}
                                        <Link to={"/datenschutz"} title="Datenschutzbestimmungen">
                                            Datenschutz&shy;bestimmungen
                                        </Link>{" "}
                                        gelesen und akzeptiere diese.
                                    </label>
                                </fieldset>
                            </form>
                        </div>
                    ) : (
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d40614.42890361492!2d11.672884936771432!3d50.48947911441044!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47a14147e2a12f05%3A0xda47480f65f291d1!2sCSports%20Fitness%20%26%20more!5e0!3m2!1sde!2sde!4v1649934199335!5m2!1sde!2sde"
                            title="Karte mit Standort"
                            style={{
                                width: "100%",
                                height: "100%",
                                position: "absolute",
                                zIndex: 3,
                            }}
                            allowFullScreen=""
                            loading="lazy"
                            referrerPolicy="no-referrer-when-downgrade"
                        ></iframe>
                    )}
                </div>
            </div>
            {/* <div className="wrapper footer__sub">
                <div className="content pt-2 pb-3">
                    <div className="row gap-3">
                        <div className="col-12 col-md-2"></div>
                        <div className="col-12 col-md-8">
                            <span className="fs-6 d-flex flex-column align-items-center gap-3">
                                <span className="text-center">
                                    Copyright &copy; {new Date().getFullYear()} Carolin Pfeffer | {footer_credits["text"]}&nbsp;
                                </span>
                                {footer_credits && (
                                    <Link to={footer_credits["link"]} target="_blank">
                                        <img className="ms-1" src={"https://api.ment.works/assets/" + footer_credits["image"]} alt={footer_credits["alt"]} />
                                    </Link>
                                )}
                            </span>
                        </div>
                        <div className="col-12 col-md-2 d-flex justify-content-end">
                            <span className="fs-6">
                                <Link to="/datenschutz">Datenschutz</Link>
                                <span>&nbsp;|&nbsp;</span>
                                <Link to="/impressum">Impressum</Link>
                            </span>
                        </div>
                    </div>
                </div>
            </div> */}
            <div className="wrapper footer__sub">
                <div className="content d-flex flex-column flex-lg-row align-items-center justify-content-lg-center gap-5 gap-lg-3 pt-4 pb-4 pt-lg-3 pb-lg-3">
                    <span className="fs-6 d-flex flex-column align-items-center flex-lg-row gap-2">
                        <span className="text-center mb-3 mb-lg-0">Copyright &copy; {new Date().getFullYear()} Carolin&nbsp;Pfeffer</span>
                        <span className="d-none d-lg-block">|</span>
                        <span className="text-ceter">{footer_credits["text"]}</span>
                        {footer_credits && (
                            <a href={footer_credits["link"]} target="_blank" rel="noreferrer" title={footer_credits["alt"]}>
                                <img src={"https://api.ment.works/assets/" + footer_credits["image"]} title={footer_credits["alt"]} alt={footer_credits["alt"]} />
                            </a>
                        )}
                    </span>
                    <span className="fs-6 ms-lg-auto">
                        <Link to="/agb" title="Allgemeine Geschäftsbedingungen">
                            AGB
                        </Link>
                        <span>&nbsp;|&nbsp;</span>
                        <Link to="/datenschutz" title="Datenschutzbestimmungen">
                            Datenschutz
                        </Link>
                        <span>&nbsp;|&nbsp;</span>
                        <Link to="/impressum" title="Impressum">
                            Impressum
                        </Link>
                    </span>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
