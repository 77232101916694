import React, { Fragment, useEffect, useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import StartseiteLandingBlock from "../components/StartseiteLandingBlock";
import StartseiteLeistungenBlock from "../components/StartseiteLeistungenBlock";
import SocialIcons from "../components/SocialIcons";
import BlockControls from "../components/BlockControls";
import ScrollToTop from "../components/ScrollToTop";
import LoadingAnimation from "../components/LoadingAnimation";
import { Helmet } from "react-helmet-async";
import NewsletterFormular from "../components/NewsletterFormular";

function Startseite() {
    const blockControlsData = [
        {
            titel: "Über mich",
            blockId: "b1",
        },
        {
            titel: "Leistungen",
            blockId: "b2",
        },
        {
            titel: "Yoga",
            blockId: "b3",
        },
        {
            titel: "Fitness",
            blockId: "b4",
        },
        {
            titel: "Ernährungs-Coaching",
            blockId: "b5",
        },
        {
            titel: "Stand Up Paddling",
            blockId: "b6",
        },
        {
            titel: "Newsletter",
            blockId: "newsletter",
        },
        {
            titel: "Kontakt",
            blockId: "b8",
        },
    ];

    const [schema, setSchema] = useState("light");
    const [schemaHeader, setSchemaHeader] = useState("light");

    return (
        <Fragment>
            <Helmet>
                <title>Carolin Pfeffer - Yoga, Fitness & Food</title>
                <meta
                    name="description"
                    content="Carolin Pfeffer – Fitnessökonomin B.A. aus Saalburg. Personal Trainerin ✓ Gruppenkurse ✓ Fitness &amp; Yoga ✓ Ernährungsberaterin ✓"
                ></meta>
                <meta
                    name="keywords"
                    content="fitness,sport,yoga,Ernährungscoaching,personal training,gruppenkurse fitness,fitness gruppe, fitness in der natur,stand up paddling,saalburg,saalburg ebersdorf,stand up paddling thüringen,fintess coach"
                ></meta>
            </Helmet>
            <ScrollToTop />
            {/* <LoadingAnimation /> */}
            <Header schema={schemaHeader} />
            <SocialIcons schema={schema} />
            <BlockControls blockControlsData={blockControlsData} schema={schema} />
            <StartseiteLandingBlock
                titel="Hey, ich bin Carolin."
                subTitel="Schön, dass Du da bist."
                h50px={true}
                text="Als Personal Trainerin bin ich darauf geschult einen besonderen Blick für den Einzelnen zu haben. Ich biete individuelle,
                auf Dich und Deine Ziele ausgerichtete ..."
                buttonText="weiterlesen"
                buttonLink="/ueber-mich"
                backgroundImg="/img/StartseiteLandingBlock_1_2.webp"
                alignBox="right"
                id={blockControlsData[0].blockId}
                jump={"#" + blockControlsData[1].blockId}
            />
            <StartseiteLeistungenBlock titel="Meine Leistungen" id={blockControlsData[1].blockId} />
            <StartseiteLandingBlock
                titel={blockControlsData[2].titel}
                text="Es gibt viele Arten von Yoga. Bei mir findest du eine gute Mischung, um deinen eigenen Yogaweg zu ..."
                buttonText="weiterlesen"
                buttonLink="/yoga"
                backgroundImg="/img/StartseiteLandingBlock_2.webp"
                alignBox="left"
                id={blockControlsData[2].blockId}
            />
            <StartseiteLandingBlock
                titel={blockControlsData[3].titel}
                text="Fitnesstraining verändert Deine Muskeln, Faszien, Dein Skelettsystem. Es schüttet Glückshormone aus, es stimuliert Dein Immunsystem, regt den Aufbau von Knochensubstanz an und ..."
                buttonText="weiterlesen"
                buttonLink="/fitness"
                backgroundImg="/img/StartseiteLandingBlock_3.webp"
                alignBox="right"
                id={blockControlsData[3].blockId}
            />
            <StartseiteLandingBlock
                titel={blockControlsData[4].titel}
                text="Du möchtest endlich eine gesunde Routine in deinem Ernährungsverhalten etablieren? Du fühlst dich abgeschlagen und deine Vitalität lässt zu wünschen übrig? Vielleicht haben sich auch ..."
                buttonText="weiterlesen"
                buttonLink="/ernaehrungscoaching"
                backgroundImg="/img/StartseiteLandingBlock_4.webp"
                alignBox="left"
                id={blockControlsData[4].blockId}
            />
            <StartseiteLandingBlock
                titel={blockControlsData[5].titel}
                text="Stand Up Paddling oder auch einfach Stehpaddeln ist ein Wassersport bei dem man auf einer festen oder aufblasbaren größeren
                Variante eines Surfbrettes steht und ..."
                buttonText="weiterlesen"
                buttonLink="/stand-up-paddling-in-saalburg"
                backgroundImg="/img/StartseiteLandingBlock_5.webp"
                alignBox="right"
                id={blockControlsData[5].blockId}
            />
            <NewsletterFormular id={blockControlsData[6].blockId}></NewsletterFormular>
            <Footer id={blockControlsData[7].blockId} />
        </Fragment>
    );
}

export default Startseite;
