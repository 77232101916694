import React, { useEffect } from "react";
import "./DialogKontakt.css";

function DialogKontakt() {
    const closeDialog = (e) => {
        e.target.parentNode.close();
        document.body.style.overflowY = "unset";
    };

    useEffect(() => {
        const dialog = document.getElementById("DialogKontakt");
        dialog.addEventListener("click", function (event) {
            var rect = dialog.getBoundingClientRect();
            var isInDialog =
                rect.top <= event.clientY && event.clientY <= rect.top + rect.height && rect.left <= event.clientX && event.clientX <= rect.left + rect.width;
            if (!isInDialog) {
                dialog.close();
                document.body.style.overflowY = "unset";
            }
        });
    }, []);

    return (
        <dialog id="DialogKontakt">
            <img src="/img/icons/close_primary-dark.svg" alt="Dialog schliessen" onClick={closeDialog}></img>
            <span className="h4 h4--spaceing h4--border h4--center mb-7">So erreichst du mich am besten</span>
            <div className="row mb-6 gap-4 gap-md-0">
                <div className="col-12 col-md-5">
                    <div className="d-flex flex-column align-items-center">
                        <i className="bi bi-telephone fs-medium-icon mb-4"></i>
                        <a href="tel:01638802363" title="jetzt anrufen" className="btn-dark-primary">
                            jetzt&nbsp;anrufen
                        </a>
                    </div>
                </div>
                <div className="col-12 col-md-2">
                    <div className="d-flex flex-column align-items-center justify-content-center h-100">
                        <span className="d-block fw-bold">oder</span>
                    </div>
                </div>
                <div className="col-12 col-md-5">
                    <div className="d-flex flex-column align-items-center">
                        <i className="bi bi-whatsapp fs-medium-icon mb-4"></i>
                        <a href="https://wa.me/+491638802363" title="WhatsApp schreiben" target="_blank" rel="noreferrer" className="btn-dark-primary">
                            WhatsApp&nbsp;schreiben
                        </a>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <span className="d-block fw-bold mb-3">Melde dich, wenn Du …</span>
                    <ul className="ul--usp mb-5">
                        <li>dich zum kostenfreien Erstgespräch oder Probetraining anmelden möchtest (Yoga, Fitness)</li>
                        <li>ein Board zum Stand-Up-Paddling ausleihen oder kaufen möchtest</li>
                        <li>dich zum Ernährungscoaching anmelden möchtest</li>
                    </ul>
                    <span className="d-block">
                        Du kannst mir auch jederzeit eine E-Mail schreiben:{" "}
                        <a href="mailto:info@carolinpfeffer.de" title="e-mail schreiben">
                            info@carolinpfeffer.de
                        </a>
                    </span>
                </div>
            </div>
        </dialog>
    );
}

export default DialogKontakt;
