import React from "react";
import "./ContentBlock2.css";

function ContentBlock2({ icon, titel, subTitel, text, zitat, id }) {
    return (
        <div className="ContentBlock2" id={id}>
            <div className="wrapper">
                <div className="content d-flex flex-column align-items-center justify-content-evenly gap-4">
                    {icon ? (
                        <div className="ContentBlock2__icon">
                            <img src={icon} alt={titel + " Icon"} />
                        </div>
                    ) : null}
                    <h1 className="h2 h2--center">{titel}</h1>
                    <span className="h4 h4--spaceing h4--border h4--center">{subTitel}</span>
                    <p className="text-center text-md-start mb-md-5">{text}</p>
                    <p className="ContentBlock2__zitat">{zitat}</p>
                </div>
            </div>
        </div>
    );
}

export default ContentBlock2;
