import React, { Fragment, useEffect, useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import BlockControls from "../components/BlockControls";
import SocialIcons from "../components/SocialIcons";
import ContentBlock1 from "../components/ContentBlock1";
import ContentBlock2 from "../components/ContentBlock2";
import ScrollToTop from "../components/ScrollToTop";
import ContentBlockUSPs from "../components/ContentBlockUSPs";
import { Helmet } from "react-helmet-async";
import ContentBlockGallery from "../components/ContentBlockGallery";
import ZeitPreiseTabelle from "../components/ZeitPreiseTabelle";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import Reviews from "../components/Reviews";
import NewsletterFormular from "../components/NewsletterFormular";

function Fitness() {
    const blockControlsData = [
        {
            titel: "Kostenfreies Erstgespräch",
            blockId: "b1",
        },
        {
            titel: "Fitness",
            blockId: "b2",
        },
        {
            titel: "Fitness & Yoga - Personal Training",
            blockId: "b3",
        },
        {
            titel: "Fitness & Yoga - Gruppen Training",
            blockId: "b4",
        },
        {
            titel: "Vorteile von Fitness Training",
            blockId: "b6",
        },
        {
            titel: "Aktuelle Fitness Kurse",
            blockId: "b8",
        },
        {
            titel: "Fitness Impressionen",
            blockId: "b9",
        },
        {
            titel: "Newsletter",
            blockId: "newsletter",
        },
        {
            titel: "Kontakt",
            blockId: "b11",
        },
    ];

    const [schema, setSchema] = useState("light");
    const [schemaHeader, setSchemaHeader] = useState("light");

    useEffect(() => {
        window.addEventListener("scroll", () => {
            if (window.scrollY > 300) {
                setSchema("dark");
            } else {
                setSchema("light");
            }
        });
    }, []);

    return (
        <Fragment>
            <Helmet>
                <title>Fitness | Carolin Pfeffer</title>
                <meta name="description" content="▶▶ Jetzt zum Fitness anmelden! Gruppenkurse oder Personal Training ✔ Muskulatur aufbauen ✔ Traumfigur erreichen ✔ vieles mehr!"></meta>
                <meta
                    name="keywords"
                    content="fitness,fitness coach,fintess trainerin,fitness trainer,fitness kurs,fitness personal trainer,fitness einzeltraining,fitness gruppe,fitness kurse,fitess ernährung,fitness in der natur,fitness am see,fitness draußen,fit bleiben,fitness und gesundheit"
                ></meta>
            </Helmet>
            <ScrollToTop />
            <Header schema={schemaHeader} />
            <SocialIcons schema={schema} />
            <BlockControls blockControlsData={blockControlsData} schema={schema} />
            <ContentBlock1
                titel="Fitness"
                text="Vereinbare jetzt dein kostenfreies Erstgespräch für ein Personal Training oder eine Schnupperstunde beim Gruppentraining. Ich freue mich auf Dich!"
                usps={["100% individuell", "Ganzheitliches Training", "Ortsungebunden", "Höchste Flexibilität"]}
                buttonText="kostenfreies Erstgespräch vereinbaren"
                buttonLink="dialog"
                targetDialog="DialogKontakt"
                backgroundImg="/img/StartseiteLandingBlock_3.webp"
                alignBox="right"
                id={blockControlsData[0].blockId}
            />
            <ContentBlock2
                icon="/img/Leistungen_Fitness.svg"
                titel="Fitness"
                subTitel="Krafttraining, Funktionelles Training und vieles mehr"
                text="Warum? Fitnesstraining verändert Deine Muskeln, Faszien, Dein Skelettsystem. Es schüttet Glückshormone aus, es stimuliert Dein Immunsystem, regt den Aufbau von Knochensubstanz an und wirkt somit Osteoporose entgegen. Fitnesstraining reguliert den Blutdruck, fördert einen gesunden Schlaf, baut Stress ab, fördert die Körperwahrnehmung und so vieles mehr wozu Du einfach nicht nein sagen kannst. Fitnesstraining ist vielfältig und so individuell wie Du. Zu einer ganzheitlichen Fitness gehören unter anderem Ausdauer, Kraft, Beweglichkeit, Koordination …"
                zitat="Ein optimaler Trainingsplan beinhaltet verschiedene motorische Fähigkeiten, aber ist immer individuell auf Dich abgestimmt."
                id={blockControlsData[1].blockId}
            />
            <div className="w-100" id={blockControlsData[2].blockId}>
                <div className="wrapper">
                    <div className="content d-flex flex-column align-items-center justify-content-evenly">
                        <h2 className="h4 h4--spaceing h4--border h4--center mb-7">Ich biete Fitness in den folgenden Formen an</h2>
                        <div className="row w-100 mb-4">
                            <HashLink className="col d-flex flex-column justify-content-evenly text-uppercase mb-5 text-decoration-none" to={"#" + blockControlsData[2].blockId}>
                                <img src="/img/Content_PersonalTraining.svg" alt="Personal Training Icon" className="contentSpecItem mb-4" />
                                <span className="d-block text-center">Personal Training</span>
                            </HashLink>
                            <HashLink className="col d-flex flex-column justify-content-evenly text-uppercase mb-5 text-decoration-none" to={"#" + blockControlsData[3].blockId}>
                                <img src="/img/Content_GruppenTraining.svg" alt="Gruppen Training Icon" className="contentSpecItem mb-4" />
                                <span className="d-block text-center">Gruppen Training</span>
                            </HashLink>
                            <Link className="col d-flex flex-column justify-content-evenly text-uppercase mb-5 text-decoration-none" to={"/firmenfitness"}>
                                <img src="/img/Content_FirmenFitness.svg" alt="Firmen Fitness Icon" className="contentSpecItem mb-4" />
                                <span className="d-block text-center">Firmen Fitness</span>
                            </Link>
                            <HashLink className="col d-flex flex-column justify-content-evenly text-uppercase mb-5 text-decoration-none" to={"/firmenfitness/#b3"}>
                                <img src="/img/Content_Workshops.svg" alt="Workshops Icon" className="contentSpecItem mb-4" />
                                <span className="d-block text-center">Workshops</span>
                            </HashLink>
                            <HashLink className="col d-flex flex-column justify-content-evenly text-uppercase mb-5 text-decoration-none" to={"/firmenfitness/#b3"}>
                                <img src="/img/Content_Vortraege.svg" alt="Vorträge Icon" className="contentSpecItem mb-4" />
                                <span className="d-block text-center">Vorträge</span>
                            </HashLink>
                        </div>
                        <div className="row">
                            <div className="col-12 col-md-8">
                                <h3 className="h4 h4--spaceing mb-4">Fitness & Yoga - Personal Training</h3>
                                <p className="mb-2">
                                    Personal Training heißt 1:1 Training - Nur Du und ich trainieren gemeinsam. Bei dieser Art Training konzentrieren wir uns gemeinsam auf deine individuellen Bedürfnisse. Mit dem Personal Training bist du
                                    nicht mehr allein mit deinen Wünschen. Wir gehen gemeinsam in die richtige Richtung und fokusieren uns auf deine Ziele. Beim Personal Training trainierst Du effizient und erreichst schnell deine Ziele. Du
                                    lernst, wie man alle Übungen richtig auszuführt und erhälst professionelle Experten-Tipps rund um dein Workout. Mit mir als Personal Trainer bleibst du am Ball und wirst super motiviert!
                                </p>
                                <p className="mb-4">
                                    Du wünschst dir dein Personal Training an einem bestimmten Ort? Kein Problem: Unser gemeinsames Personal Training kann bei Dir zu Hause, im Büro, im Fitnessstudio, in meinem Fitnessraum oder in der freien
                                    Natur stattfinden. Wir suchen uns den geeigneten Platz oder Raum, in dem Du dich am wohlsten fühlst.
                                </p>
                                <h4 className="d-block fw-bold mb-2 fs-5">Tipp: kostenfreies Erstgespräch</h4>
                                <p className="mb-4">
                                    Für den bestmöglichen Erfolg, werden wir gemeinsam in einem kostenfreien Erstgespräch über deine Bedürfnisse sprechen, um diese zu bestimmen. Wenn so weit alles passt und die Chemie stimmt vereinbaren wir
                                    einen Termin für eine Einzel-Sessions oder du nimmst an einen der zahlreichen Gruppenkurse teil.
                                </p>
                            </div>
                            <div className="col-12 col-md-4 mb-4">
                                <img src="/img/Content_Fitness1.webp" alt="Fitness Foto" className="w-100" />
                            </div>
                            <div className="col-12">
                                <h4 className="d-block fw-bold mb-2 fs-5">Personal Training in der Natur, Zuhause oder im Fitness-Studio</h4>
                                <p className="mb-4">
                                    Um deine Bedürfnisse zu definieren, werden wir uns gemeinsam zu einem kostenfreien Erstgespräch mit Einblick in einen möglichen Trainingsablauf treffen. Wenn die Chemie stimmt vereinbaren wir einen ersten
                                    Termin. Zu diesem Termin erfolgt die Anamnese deines Personal-Trainings. Dieses Einführungsgespräch bildet die Grundlage, um Dir ein individuelles, auf Dich zu geschnittenes Trainingsprogramm zu
                                    erstellen. Wir erörtern deine Ziele und Voraussetzungen, damit dein Personal Training zielgerichtet beginnen kann. Mit einem abwechslungsreichen und zielorientierten Training werden wir mit viel Spaß und
                                    Schweiß dein gesamtes Potenzial nutzen, wie es dir vorher unmöglich erschien. Worauf also noch warten?
                                </p>
                            </div>
                            <div className="col-12">
                                <h4 className="d-block fw-bold mb-2 fs-5">Kosten &amp; Spaß teilen: Personal Training mit Partner, Familienmitglied oder Freund*in</h4>
                                <p>
                                    Du möchtest nicht allein, aber auch nicht in einer größeren Gruppe trainieren? Natürlich ist auch ein spezielles Personal Training mit Partner, Familienmitglied oder Freund*in möglich. So kannst du nicht
                                    nur die Kosten, sondern auch den Spaß teilen.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ContentBlockUSPs
                headline="Vorteile des Personal Trainings"
                usps={[
                    "kostenfreies Erstgespräch mit Probetraining",
                    "ausführliches Einführungsgespräch mit Anamnese",
                    "individuell auf Dich zugeschnittenes Trainingsprogramm",
                    "Ort und Zeit bestimmst Du",
                    "hohe Flexibilität",
                    "ein ausführliches Ernährungscoaching",
                    "Begleitung auf dem Weg zur dauerhaften Umstellung Deiner Lebensgewohnheiten",
                ]}
                cta="Lass uns gleich loslegen!"
                buttonText="kostenfreies Erstgepräch vereinbaren"
                buttonLink="dialog"
                targetDialog="DialogKontakt"
                alignment="right"
            />
            <div className="w-100" id={blockControlsData[3].blockId}>
                <div className="wrapper">
                    <div className="content d-flex flex-column align-items-center justify-content-evenly">
                        <div className="row flex-column-reverse flex-md-row mb-4">
                            <div className="col-12 col-md-4 mb-4">
                                <img src="/img/Content_Fitness2.webp" alt="Fitness Foto" className="w-100" />
                            </div>
                            <div className="col-12 col-md-8 mb-4">
                                <h3 className="h4 h4--spaceing mb-4">Fitness & Yoga - Gruppen Training</h3>
                                <p className="mb-4">
                                    Du willst nicht allein trainieren, sondern lieber im Rausch der Gruppendynamik deine Ziele erreichen? Dann bist Du hier genau richtig! Gruppentraining heißt trainieren mit Gleichgesinnten — Beim
                                    gemeinsamen Training in der Gruppe herrscht ein hoher Motivationsfaktor. Durch gegenseitiges Anspornen erreichst du mit viel Spaß und abwechslungsreichen Übungen deine Ziele. Bei dieser Art Training
                                    unterstützen wir dein Gemeinschaftsgefühl. Gruppentraining eignet sich perfekt für Menschen, die sich in der Gruppe wohlfühlen. Ob Fitness, Yoga oder Ernährungscoaching: In der Gruppe trainieren und
                                    lernen sorgt für ein gutes Gefühl. Mit dem Gruppentraining bist du nicht mehr allein mit deinen Wünschen. Wir gehen gemeinsam in die richtige Richtung und fokussieren uns auf eure Ziele. Beim
                                    Gruppentraining trainiert ihr effizient und erreicht schnell eure Ziele. Gemeinsam lernt ihr, wie man die Übungen korrekt ausführt und erhaltet professionelle Experten-Tipps rund um ein effektives
                                    Training. Mit mir als ausgebildete Gruppentrainerin bleibt ihr am Ball und stets hochmotiviert!
                                </p>
                                <h4 className="d-block fw-bold mb-2 fs-5">Tipp: kostenfreies Probetraining - Deine Schnupperstunde</h4>
                                <p>
                                    Damit du einen umfangreichen Einblick in mein Gruppentraining bekommst, lade ich dich gern zu einem gratis Probetraining ein. Du bekommst einen ersten Einblick in einen möglichen Trainingsablauf. Wenn dir
                                    die Schnupperstunde gefallen hat, kann es auch schon direkt losgehen: Gemeinsam vereinbaren wir dein Trainingsbeginn und klären, was du für das bevorstehende Training benötigst. Mit einem
                                    abwechslungsreichen und zielorientierten Training werden wir gemeinsam mit viel Spaß und Schweiß das gesamte Potenzial der Gruppe nutzen. Worauf also noch warten?
                                </p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <h4 className="d-block fw-bold mb-2 fs-5">Gruppentraining in der Natur oder im Fitness-Studio</h4>
                                <p className="mb-4">
                                    Du wünschst dir ein Gruppentraining im Freien? Kein Problem: Es gibt sowohl Indoor- als auch Outdoor-Kurse. Bei geeigneten Bedingungen kann das Gruppentraining in der Natur stattfinden. Zusätzlich
                                    trainieren wir in meinem Fitnessraum. Einen Überblick zu den aktuellen Kursen findest du hier: aktuelle Gruppentraining Kursangebote
                                </p>
                            </div>
                            <div className="col-12">
                                <h4 className="d-block fw-bold mb-2 fs-5">Training für deine persönliche Gruppe</h4>
                                <p>Du möchtest gern einen eigenen Kurs für deinen Mädelsabend, fürs Büro oder im Freundeskreis arrangieren? Melde Dich einfach bei mir und wir richten dir ein individuelles Gruppentraining ein.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ContentBlockUSPs
                headline="Vorteile des Gruppentrainings"
                usps={[
                    "gratis Schnupper-Training für Einsteiger",
                    "perfekt für Menschen, die sich in der Gruppe wohlfühlen",
                    "gegenseitiges Anspornen - geimeinsam Ziele erreichen",
                    "effektives Training mit Teilnehmern, die sich den gleichen Herausforderungen stellen",
                    "günstig in der Gruppe trainieren statt hohe Einzelkosten",
                    "starkes Gemeinschaftsgefühl mit Motivationsfaktor",
                ]}
                cta="Lass uns gleich loslegen!"
                buttonText="kostenfreies Erstgepräch vereinbaren"
                buttonLink="dialog"
                targetDialog="DialogKontakt"
                alignment="left"
            />
            <div className="w-100">
                <div className="wrapper">
                    <div className="content">
                        <div className="row">
                            <div className="col-12">
                                <h4 className="d-block fw-bold mb-2 fs-5">Nicht nur die Kosten teilen - weitere Vorteile des Gruppentrainings</h4>
                                <p className="fst-italic mb-4">
                                    Du möchtest gern einen eigenen Kurs für deinen Mädelsabend, fürs Büro oder im Freundeskreis arrangieren? Melde Dich einfach bei mir und wir richten dir ein individuelles Gruppentraining ein.
                                </p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-md-6">
                                <ul className="ul--usp">
                                    <li>Gruppendynamik</li>
                                    <li>Training mit Menschen mit gleichen Zielen</li>
                                    <li>Spaß und Motivation durch ein intensives Teamgefühl</li>
                                </ul>
                            </div>
                            <div className="col-12 col-md-6">
                                <ul className="ul--usp">
                                    <li>feste, vorgegeben, reguläre Termine</li>
                                    <li>Ganzheitliches Training</li>
                                    <li>günstige Gruppentarife</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="w-100" id={blockControlsData[4].blockId}>
                <div className="wrapper h-100">
                    <div className="content h-100 d-flex flex-column align-items-center justify-content-evenly">
                        <h2 className="h4 h4--spaceing h4--border h4--center mb-7">Dein individuelles Fitness Training in den folgenden Disziplinen</h2>
                        <div className="row w-100">
                            <div className="col-12 col-md-6">
                                <div className="div--usp">
                                    <i className="bi bi-bar-chart"></i>
                                    <span>Fitness kräftigt und dehnt die Muskulatur</span>
                                </div>
                                <div className="div--usp">
                                    <i className="bi bi-heart-pulse"></i>
                                    <span>Löst Verspannung und Blockaden und hilft damit bei Schmerzen</span>
                                </div>
                                <div className="div--usp">
                                    <i className="bi bi-person-check-fill"></i>
                                    <span>Verbessert die Durchblutung</span>
                                </div>
                            </div>
                            <div className="col-12 col-md-6">
                                <div className="div--usp">
                                    <i className="bi bi-person-dash"></i>
                                    <span>Verbessert die Konzentrations- und Leistungsfähigkeit</span>
                                </div>
                                <div className="div--usp">
                                    <i className="bi bi-flower1"></i>
                                    <span>Hilft bei Verdauungsproblemen</span>
                                </div>
                                <div className="div--usp">
                                    <i className="bi bi-brightness-high"></i>
                                    <span>Vertieft den Atem und schult das korrekte und ökonomische Atmen</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="w-100" id={blockControlsData[5].blockId}>
                <div className="wrapper">
                    <div className="content d-flex flex-column align-items-center">
                        <ZeitPreiseTabelle id={11} />
                        {/* <ZeitPreiseTabelle id={7} /> */}
                        <ZeitPreiseTabelle id={8} />
                    </div>
                </div>
            </div>
            <div className="w-100">
                <div className="wrapper">
                    <div className="content">
                        <h4 className="h4 h4--spaceing h4--border h4--center mb-7">Das sagen meine Kunden</h4>
                        <Reviews url={"/cronjobs/googleReviewsPersonal.json"} />
                    </div>
                </div>
            </div>
            <ContentBlockGallery
                id={blockControlsData[6].blockId}
                img1={"/img/Content_GalerieFitness1.webp"}
                img2={"/img/Content_GalerieFitness2.webp"}
                img3={"/img/Content_GalerieFitness3.webp"}
                img4={"/img/Content_GalerieFitness4.webp"}
            />
            <NewsletterFormular id={blockControlsData[7].blockId}></NewsletterFormular>
            <Footer id={blockControlsData[8].blockId} />
        </Fragment>
    );
}

export default Fitness;
