import React, { Fragment, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import Header from "../components/Header";
import Footer from "../components/Footer";
import ScrollToTop from "../components/ScrollToTop";
import NewsletterFormular from "../components/NewsletterFormular";

function AGB() {
    const [schema, setSchema] = useState("dark");
    const [schemaHeader, setSchemaHeader] = useState("dark");

    return (
        <Fragment>
            <Helmet>
                <title>AGB | Carolin Pfeffer</title>
                <meta name="description" content=""></meta>
                <meta name="keywords" content=""></meta>
            </Helmet>
            <ScrollToTop />
            <Header schema={schemaHeader} />
            <main className="w-100">
                <div className="wrapper">
                    <div className="content" style={{ paddingTop: "15rem" }}>
                        <h1>Allgemeine Geschäftsbedingungen</h1>
                        <h2>Allgemeine Geschäftsbedingungen Carolin Pfeffer</h2>
                        <h3>1. Leistungsgegenstand</h3>
                        <p>
                            1.1. Carolin Pfeffer verpflichtet sich, den Klienten im Rahmen der vereinbarten Trainings- und Gesundheitsbetreuung individuell zu
                            beraten und zu betreuen.
                        </p>
                        <p>
                            1.2. Ist keine andere Vereinbarung getroffen kann die Trainings- und Gesundheitsbetreuung nur durch den Klienten persönlich in
                            Anspruch genommen werden.
                        </p>
                        <p>
                            1.3. Die vereinbarte Trainings- und Betreuungsleistung versteht sich als zeitbestimmte, dienstvertragliche Verpflichtung
                            entsprechend § 611 BGB.
                        </p>
                        <h3>2. Training</h3>
                        <p>
                            2.1. Die Dauer einer Trainingseinheit beträgt 60 Minuten oder länger (gilt nur bei Personal Training; Gruppentraining ab 30min.).
                            Kürzere Trainingseinheiten müssen ausdrücklich vereinbart werden.
                        </p>
                        <p>
                            2.2. Art, Umfang und Ort jeder Trainingseinheit werden mit dem Klienten abgesprochen. Mögliche Trainingsinhalte und -ziele werden
                            vorab in einem Beratungsgespräch mit dem Klienten abgestimmt.
                        </p>
                        <p>2.3. Der Beginn des Trainings ist nur nach einem obligatorischen Gesundheits-Check-Up durch Carolin Pfeffer möglich.</p>
                        <p>2.4. Die Trainingszeiten sind:</p>
                        <p>
                            Mo - Fr : 06.00 - 22.00 Uhr
                            <br />
                            Sa - So : 09.00 - 20.00 Uhr
                        </p>
                        <p>
                            In Ausnahmefällen kann von den genannten Trainingszeiten abgewichen werden. Die vereinbarten Trainingstermine sind für beide Seiten
                            verbindlich.
                        </p>
                        <h3>3. Verhinderung und Ausfall</h3>
                        <p>
                            3.1 Trainingstermine, die von einem Klienten innerhalb der letzten 24-Stunden vor dem vereinbarten Termin abgesagt oder geändert
                            werden, verfallen zu Lasten des Klienten.
                        </p>
                        <p>
                            Das vereinbarte Honorar (brutto) wird als Stornogebühr fällig. Gleiches gilt, wenn der Klient innerhalb der oben genannten Frist
                            nicht zum vereinbarten Termin erscheint. Erfolgt die Absage oder Verschiebung außerhalb der 24-Stunden-Frist, wird ein
                            Ausweichtermin vereinbart. Werden Trainingstermine durch den Trainer abgesagt, so wird hierfür grundsätzlich ein Ersatztermin
                            angeboten. In Ausnahmefällen kann von den o.g. Regelungen abgewichen werden. Terminänderungen/Terminabsagen müssen telefonisch (auch
                            Mailbox) oder schriftlich (E-Mail, SMS) erfolgen.
                        </p>
                        <p>
                            3.2. Bei Verhinderung hat der Klient schnellstmöglich, spätestens aber 8 Stunden vor Trainingsbeginn abzusagen. Andernfalls wird das
                            vereinbarte Honorar für die gebuchte Trainingseinheit in voller Höhe berechnet.
                        </p>
                        <p>
                            3.3. Sollte die Durchführung einer Trainingseinheit aufgrund unvorhersehbarer Umstände (Wetterverhältnisse etc.) zu gefährlich bzw.
                            unmöglich sein, findet die Trainingseinheit gegebenenfalls Indoor statt oder wird nach Absprache verschoben. Die Entscheidung über
                            die Durchführung wird grundsätzlich einvernehmlich mit dem Klienten getroffen.
                        </p>
                        <p>
                            3.4. In Ausnahmefällen (Krankheit, Urlaubszeit etc.) kann nach vorheriger Absprache mit dem Klienten ein gleichwertig qualifizierter
                            Trainer die Betreuung übernehmen.
                        </p>
                        <h3>4. Ersatzansprüche</h3>
                        <p>
                            Bei einer kurzfristigen Trainingsabsage durch Carolin Pfeffer können keine Ersatzansprüche geltend gemacht werden. Bereits gezahlte
                            Trainingseinheiten werden gutgeschrieben oder auf Wunsch erstattet.
                        </p>
                        <h3>5. Außentermine</h3>
                        <p>
                            Wurde ein Außentermin vereinbart, kann dieser, wegen schlechtem Wetter, nur durch den Trainer abgesagt, verschoben oder an einen
                            alternativen Trainingsort verlegt werden. Erfolgt keine Absage, Verschiebung oder Verlegung durch den Trainer, findet der Termin am
                            vereinbarten Ort und zum vereinbarten Zeitpunkt statt. Der Klient ist für temperatur- und wettergerechte Kleidung selbst
                            verantwortlich.
                        </p>
                        <h3>6. Sonstige Leistungen</h3>
                        <p>
                            Carolin Pfeffer steht ihren Klienten außerhalb der Trainingseinheiten von Mo.- Fr. zwischen 8.00 und 18.00 Uhr im Rahmen der
                            vereinbarten Trainings- und Gesundheitsbetreuung per Telefon und E-Mail zur Verfügung. Hieraus ergibt sich kein Anspruch auf
                            ständige Erreichbarkeit von Carolin Pfeffer.
                        </p>
                        <h3>7. Sporttauglichkeit/Haftung</h3>
                        <p>
                            7.1. Carolin Pfeffer schließt gegenüber dem Klienten jegliche Haftung für einen Personen- oder Sachschaden aus, der nicht auf einer
                            grob fahrlässigen oder vorsätzlichen Pflichtverletzung auch etwaiger Erfüllungsgehilfen beruht.
                        </p>
                        <p>
                            7.2. Eine Haftungsausschlusserklärung ist vom Klienten zusätzlich zu unterschreiben und gilt als Gegenstand der vertraglichen
                            Vereinbarungen.
                        </p>
                        <p>
                            7.3. Carolin Pfeffer haftet nicht über die Erbringung ihrer geschuldeten Leistung hinaus für eine etwaige Nichterreichung des vom
                            Klienten mit der Eingehung des Vertrages verfolgten Zwecks.
                        </p>
                        <p>
                            7.4. Nimmt der Klient die Leistungen von Kooperationspartnern oder anderen von Carolin Pfeffer vermittelten Firmen oder Personen in
                            Anspruch, tut er dies auf eigene Verantwortung. Carolin Pfeffer übernimmt keine Gewährleistung für Waren und Leistungen, die der
                            Klient von diesen erhalten hat.
                        </p>
                        <p>
                            7.5. Der Klient hat sich eigenverantwortlich gegen Unfälle und Verletzungen, die im Rahmen des Personal Trainings auftreten können,
                            zu versichern. Gleiches gilt für den direkten Weg von und zum Trainingsort.
                        </p>
                        <p>
                            7.6. Der Klient versichert sportgesund zu sein. Er hat sich bei einem Arzt seines Gesundheitszustandes versichert. Er verpflichtet
                            sich, sich in regelmäßigen Abständen auf seine Sporttauglichkeit ärztlich untersuchen zu lassen. Jede Erkrankung, Schmerz,
                            Schwindel, Unwohlsein usw., ist dem Trainer sofort mitzuteilen.
                        </p>
                        <p>
                            Alle Anfragen zum derzeitigen/bisherigen Gesundheitszustand und zu den aktuellen Lebensumständen sind wahrheitsgemäß und vollständig
                            zu beantworten. Alle Änderungen sind dem Trainer sofort mitzuteilen. Die Teilnahme an den Trainingseinheiten erfolgt auf eigenes
                            Risiko des Klienten.
                        </p>
                        <h3>8. Zahlungsbedingungen</h3>
                        <p>
                            8.1. Sämtliche anfallenden Kosten und Gebühren sind vor Trainingsbeginn zwischen dem Klienten und Trainer in einem verbindlichen
                            Gespräch (persönlich, telefonisch) oder schriftlich festgelegt. Werden während der laufenden Trainingseinheiten Zusatzleistungen
                            (Ernährungsberatung o.ä.) durch den Klienten wahrgenommen oder in Anspruch genommen, so werden diese gesondert abgerechnet.
                        </p>
                        <p>Die Abrechnung der ersten 5 Trainingseinheiten erfolgt im Voraus.</p>
                        <p>
                            Alle weiteren Trainingseinheiten werden jeweils am Monatsende abgerechnet, ausschließlich Trainingseinheiten in der Gruppe, diese
                            werden vor Beginn des Kurses entrichtet.
                        </p>
                        <p>
                            8.2. Der Klient erhält von Carolin Pfeffer eine schriftliche Rechnung, die ohne Abzüge innerhalb von 14 Tagen zu zahlen ist.
                            Zusätzlich zur Rechnung erhält der Klient eine detaillierte Aufstellung der durchgeführten Maßnahmen und Leistungen.
                        </p>
                        <p>
                            8.3. Es gilt die jeweils aktuelle Preisliste. Carolin Pfeffer behält sich eine Änderung der Preisgestaltung vor und verpflichtet
                            sich etwaige Änderungen dem Klienten umgehend, mindestens vier Wochen vor Inkrafttreten, schriftlich mitzuteilen.
                        </p>
                        <h3>9. Sonstige Kosten</h3>
                        <p>
                            9.1. Entstehen aufgrund der gewünschten Sportarten und/oder Trainingsinhalte des Klienten weitere Kosten (Eintrittsgelder,
                            Platzmieten, etc.), so sind diese vom Klienten zu tragen.
                        </p>
                        <p>Anfahrtskosten: Ab dem 10ten km wird eine Kilometerpauschale von 50 Cent pro gefahrenem Kilometer erhoben.</p>
                        <p>
                            9.2. Die Kosten für einen Arzt, Physiotherapeuten, Ernährungsberater o.ä., die zur ganzheitlichen Betreuung konsultiert werden,
                            übernimmt der Klient in Höhe der Abrechnungsmodalitäten des jeweiligen Dienstleisters.
                        </p>
                        <p>
                            9.3. Werden anderweitige Trainings- oder Dienstleistungen (z.B. Kinderbetreuung, Trainingsbetreuung auf Reisen etc.) in Anspruch
                            genommen, so werden vorab gesonderte Tarife vereinbart.
                        </p>
                        <p>
                            9.4. Kauft Carolin Pfeffer im Auftrag des Klienten Produkte (Sportartikel etc.) ein, so bleibt die Ware bis zur vollständigen
                            Bezahlung durch den Auftraggeber, Eigentum von Carolin Pfeffer.
                        </p>
                        <h3>10. Datenschutz</h3>
                        <p>
                            10.1. Die personenbezogen Daten des Klienten werden von Carolin Pfeffer gespeichert und ausschließlich zur Erfüllung des
                            vorgenannten Leistungsgegenstandes verwendet.
                        </p>
                        <p>
                            10.2. Die gespeicherten Daten werden auf Wunsch, spätestens aber 24 Monate nach der letzten gebuchten Trainingseinheit gelöscht. Im
                            Übrigen gelten die Bestimmungen des Bundesdatenschutzgesetzes.
                        </p>
                        <p>
                            Zur
                            <a href="/datenschutz" title="Datenschutzerklärung">
                                Datenschutzerklärung
                            </a>
                            .
                        </p>
                        <h3>11. Geheimhaltung</h3>
                        <p>
                            11.1. Der Klient verpflichtet sich, über etwaige Geschäfts- und Betriebsgeheimnisse von Carolin Pfeffer Stillschweigen zu bewahren,
                            auch über die Beendigung der Rahmenvereinbarung hinaus.
                        </p>
                        <p>
                            11.2. Carolin Pfeffer hat über alle im Zusammenhang mit der Erfüllung der Trainings- und Betreuungsmaßnahmen bekannt gewordenen
                            Informationen des Klienten Stillschweigen zu bewahren, auch über die Beendigung der Rahmenvereinbarung hinaus.
                        </p>
                        <h3>12. Sonstige Vereinbarungen</h3>
                        <p>
                            12.1. Beide Parteien erkennen Absprachen und Vereinbarungen zur Buchung von Trainingseinheiten als verbindlich an, sofern diese
                            beiderseitig bestätigt wurden. Dies gilt für alle verwendeten Kommunikationsmittel, wie Telefon, Fax oder E-Mail.
                        </p>
                        <p>
                            12.2. Beide Parteien verpflichten sich zu gegenseitiger Loyalität und werden sich keinesfalls negativ über die Person bzw. Produkte
                            oder Dienstleistungen des anderen äußern oder dessen Ruf und Prestige beeinträchtigen.
                        </p>
                        <h3>13. Schlussbestimmungen</h3>
                        <p>
                            13.1. Änderungen, Ergänzungen und Nebenabreden bedürfen, sofern in diesen AGB nichts anderes bestimmt ist, zu ihrer Wirksamkeit der
                            Schriftform. Das Schriftformerfordernis gilt auch für den Verzicht auf dieses Formerfordernis.
                        </p>
                        <p>
                            13.2. Sollte eine der vorangehenden Bestimmungen unwirksam oder undurchführbar sein, bleibt die Wirksamkeit der übrigen Bestimmungen
                            davon unberührt. Anstelle der unwirksamen oder undurchführbaren Bestimmung wird einvernehmlich eine geeignete, dem wirtschaftlichen
                            Erfolg möglichst nahe kommende rechtswirksame Ersatzbestimmung getroffen.
                        </p>
                        <h2>Allgemeine Geschäftsbedingungen Carolin Pfeffer SUP</h2>
                        <p>Touren – Trainings – Events – Sports</p>
                        <p>
                            Es gelten ausschließlich unsere hier aufgeführten Allgemeinen Geschäftsbedienungen. Abweichungen oder Sonderregelungen bedürfen
                            stets einer Bestätigung in Schriftform durch Carolin Pfeffer SUP. Es gilt das recht der Bundesrepublik Deutschland. Wir empfehlen
                            alle Wertgegenstände (z.B. Schmuck, Handy usw.) die nicht Wasserfest sind im Auto oder an Land zu lassen. Das Stand Up Paddling
                            unter Einfluss von bewusstseinsverändernden Medikamenten, Alkohol und Drogen ist untersagt.
                        </p>
                        <h3>1. Teilnahmeanforderungen</h3>
                        <p>1.1. Voraussetzungen sind die Schwimmkenntnis und körperliche Gesundheit.</p>
                        <p>
                            1.2. Sollte ein Teilnehmer die Voraussetzungen erkennbar nicht erfüllen oder sich und andere Teilnehmer gefährden, ist Carolin
                            Pfeffer jederzeit berechtigt, jenen von der Tour, Training oder Event ganz oder teilweise auszuschließen.
                        </p>
                        <p>
                            1.3. Stand Up Paddling ist Wassersport unter körperlicher Belastung: Daher sollten Sie im Zweifelsfalle durch einen Arzt überprüfen
                            lassen, ob Ihre Gesundheit den Anforderungen einer solchen Tour, Training oder Event gewachsen ist. Für die Einhaltung der
                            Gesundheitsvorschriften sind Sie selbst verantwortlich.
                        </p>
                        <p>
                            1.4. Alle Nachteile, die aus der Nichtbefolgung dieser Gesundheitsvorschriften entstehen, gehen zu Ihren Lasten. Dies gilt auch,
                            wenn diese Vorschriften nach der Buchung sich ändern sollten. Sollten Sie deshalb Ihre Tour, Training bzw. Event nicht antreten, so
                            muss dies wie ein Rücktritt von Leistung behandelt werden.
                        </p>
                        <p>
                            1.5. Stand Up Paddling ist eine Risikosportart: Für Schäden, die Sie sich oder anderen zufügen, sind Sie selbst verantwortlich. Ein
                            gewisses Restrisiko lässt sich auch bei umsichtiger Betreuung durch den Guide nicht gänzlich ausschließen. Dessen muss sich jeder
                            Teilnehmer stets bewusst sein.
                        </p>
                        <p>
                            1.6. Für gemietetes SUP Equipment, auch für jene Mietsachen, die im Tour-, Training- oder Event-Preis eingeschlossen sind, haftet
                            der Teilnehmer in vollem Umfang. Sollte es dem Mieter aus irgendwelchen Gründen (z.B. Beschädigung, Verlust), auch wenn er diese
                            nicht zu vertreten hat, sowie in Fällen höherer Gewalt unmöglich sein, die ihm obliegende Verpflichtung zur Rückgabe einschließlich
                            Zubehör einzuhalten, so ist er verpflichtet, Ersatz in Höhe des jeweils marktgültigen Reparatur-, Neuherstellungspreises zu leisten.
                        </p>
                        <p>
                            1.7. Jeder Schaden an der Mietsache ist dem Vermieter sofort anzuzeigen. Die Benutzung einer beschädigten oder nicht
                            betriebssicheren Mietsache ist unzulässig. Der Mieter ist ohne vorherige Zustimmung des Vermieters nicht befugt, das SUP Equipment
                            selbst oder durch Dritte reparieren zu lassen. Dieses Recht ist ausschließlich dem Vermieter oder einem Fachhändler vorbehalten.
                        </p>
                        <h3>2. Vertragsentstehung / Anmeldung</h3>
                        <p>
                            2.1. Mit Zugang einer Buchungsbestätigung oder der ausgefüllten Anmeldung via E-mail, vor Ort beim Veranstalter ist verbindlich ein
                            Vertrag entstanden. Der Anmeldende hat daraufhin für alle in der Anmeldung aufgeführten Teilnehmer in vollem vertraglichen
                            Verpflichtungsumfang einzustehen. Mit Zugang der Buchungsbestätigung beim Anmeldenden wird der Vertrag ebenfalls in vollem Umfange
                            für den Veranstalter verbindlich.
                        </p>
                        <p>
                            2.2. Nach Eingang Ihrer Anmeldung übersendet Carolin Pfeffer umgehend eine Anmelde- oder Buchungsbestätigung. Die gesamte
                            Teilnahmegebühr ist bei der Anmeldung zu unseren Touren, Training oder Event fällig, der Teilnehmer erhält unmittelbar nach seiner
                            Anmeldung die Rechnung zur Tour, Training oder Event welche zum aufgedrucktem Zahlungstermin fällig und zu zahlen ist. Der
                            Teilnehmer kann per Überweisung seiner Zahlungspflicht nachkommen.
                        </p>
                        <p>
                            2.3. Die Miet-, Tour-, Training- oder Event-Gebühren werden mit Anmeldung und Teilnehmerunterschrift unwiderruflich fällig. Der
                            Teilnehmer verpflichtet sich durch seine Unterschrift, die gesamten Gebühren zu bezahlen, auch bei nicht fristgerechtem Rücktritt
                            vor oder während der Tour, Training bzw. dem Event.
                        </p>
                        <p>2.4. Auf Wunsch informieren wir Sie gern über Unterkünfte vor Ort.</p>
                        <h3>3. Reservierung</h3>
                        <p>
                            Wird eine Reservierung nicht eingehalten, nicht frühzeitig telefonisch storniert oder verschoben, wird das reservierte Material nach
                            15 min über der reservierten Zeit an andere weiter verliehen.
                        </p>
                        <h3>4. Rücktritt / Wiederruf</h3>
                        <p>
                            4.1. Der Teilnehmer kann jederzeit von der Tour, Training, Miete oder Event zurücktreten. Der Rücktritt muss schriftlichen erfolgen.
                            Innerhalb von 1 Woche nach erhalten der Anmelde- oder Buchungsbestätigung ist ein Rücktritt ohne angaben von Gründen möglich.
                        </p>
                        <p>
                            4.2. Infolge entstandener Aufwendungen bei Carolin Pfeffer entstehen folgende Storno Kosten: Bis 30 Tage vor Tour, Training, Miet-
                            oder Event Beginn: 10 % des Tour-, Training-, Miet- oder Event-Preises, ab 21 Tage vor Tour, Training, Miet- oder Event Beginn: 30 %
                            des Tour-, Training-, Miet- oder Event-Preises, ab 14 Tage vor Tour, Training, Miet- oder Event Beginn: 60 % des Tour-, Training-,
                            Miet- oder Event-Preises, ab 7 Tage vor Tour, Training, Miet- oder Event Beginn: 90 % des Tour-, Training-, Miet- oder
                            Event-Preises, bei Nichtantritt 100 % des Tour-, Training-, Miet- oder Event-Preises.
                        </p>
                        <p>
                            4.3. Bei versäumter Zahlung des vollen Betrages bis 14 Tage vor Tour, Training oder Event Beginn hat der Teilnehmer seinen Anspruch
                            auf Erbringung der Tourenleistung verwirkt und ist gleichzeitig zur Zahlung von Storno Gebühren verpflichtet.
                        </p>
                        <p>
                            4.4. Kann der Teilnehmer aus gesundheitlichen Gründen die Tour, Training, Mietzeitraum oder Event nicht antreten, wird die bereits
                            bezahlte Gebühr auf einen späteren Termin übertragen. Dies gilt nur für den Zeitraum von einem Jahr. Ein ärztliches Attest ist
                            vorzulegen.
                        </p>
                        <h3>5. Umbuchungen</h3>
                        <p>
                            Umbuchungen sind nur mit Zustimmung von Carolin Pfeffer möglich. Bei bestätigter Umbuchung, ist diese nur bis 7 Tage vor antritt des
                            Termins gebührenlos möglich. Bei Umbuchungen 7 Tage vor Termin, werden dem Teilnehmer die angefallenen Bearbeitungskosten berechnet,
                            mindestens jedoch 15,00 Euro.
                        </p>
                        <h3>6. Event</h3>
                        <p>
                            6.1. Eine Änderung des vereinbarten Eventpreises bis zu einer 7%igen Erhöhung ist zulässig, wenn zwischen Vertragsabschluss und
                            Event Beginn mehr als 3 Monate verstrichen sind oder unvorhersehbare nachgewiesene Gründe vorliegen, insbesondere
                            Versicherungszuschläge, behördliche Anordnungen, Gesetzesänderungen, Ölpreisänderungen, etc. Falls diese Preisänderungen zu einer
                            Preissteigerung über 10 % führen, hat der Teilnehmer das Recht, kostenlos vom Vertrag zurückzutreten.
                        </p>
                        <p>
                            6.2. Mit Zugang der Anmelde- oder Buchungsbestätigung beim Anmeldenden für das Event wird eine Anzahlungssumme i.H.v. 30 % der
                            Gesamtsumme fällig. Der restliche Betrag wird spätestens 14 Wochen Tage vor Event antritt fällig. Eine Fälligkeit für die Unterlagen
                            besteht frühestens 21 Tage vor dem Event Beginn, wobei diese erst nach Eingang des vollen Event-Preises ausgehändigt werden.
                        </p>
                        <h3>7. Leistungen Carolin Pfeffer</h3>
                        <p>
                            7.1. Wird im Zeitpunkt des Vertragsabschlusses nicht vorhersehbarer höherer Gewalt die Tour, Training oder Event erheblich
                            erschwert, gefährdet oder beeinträchtigt, so behält sich Carolin Pfeffer das Recht vor, die Tour, Training oder Event abzubrechen
                            oder einen neuen Termin festzulegen. Dasselbe gilt, wenn Tour, Training oder Event wegen äußerer Umstände, insb. extremer
                            Wetterbedingungen, Verletzung eines Teilnehmers usw., nicht angetreten werden können oder abgebrochen werden müssen. Eventuell
                            entstehende Mehrkosten für Rückbeförderung gehen zu Lasten des Teilnehmers.
                        </p>
                        <p>
                            7.2. Kann im Fall von höherer Gewalt eine komplette Tour, Training oder Event von Anfang an nicht durchgeführt werden, erhält der
                            Teilnehmer einen Gutschein. Bei Vorlage des Gutscheins wird die Tour, Training oder Event zu einem späteren Zeitpunkt durchgeführt.
                        </p>
                        <p>
                            7.3. Carolin Pfeffer ist berechtigt, bis 2 Wochen vor Tour Beginn von dem Vertrag zurückzutreten, wenn die jeweils festgelegte
                            Mindestteilnehmerzahl nicht erreicht wurde.
                        </p>
                        <p>7.4. Carolin Pfeffer haftet für die gewissenhafte Tourenvorbereitung, Leistungsbeschreibung und Abwicklung.</p>
                        <p>
                            7.5. Änderungen und Abweichungen einzelner Touren-, Training- bzw. Event- Leistungen von dem vertraglich vereinbarten Inhalt sind
                            gestattet, soweit diese nicht erheblich sind und den Gesamtcharakter des Vertrages nicht beeinträchtigen. Notwendige Änderungen der
                            Fahrtroute, der Übernachtung, Zwischenübernachtungen bis hin zu einem anderen Tour-, Training- oder Event- Ablauf können sich aus
                            witterungsbedingten Gründen ergeben. Hierbei entstandene Kosten, welche vom Veranstalter nicht zu vertreten sind, gehen zu Lasten
                            des Teilnehmers. Der Veranstalter behält sich notwendige Änderungen des Transportmittels zur Gewährleistung eines reibungslosen
                            Ablaufes der Tour, Training oder Event vor, soweit sie dem Teilnehmer gegenüber zumutbar sind.
                        </p>
                        <p>
                            7.6. Carolin Pfeffer haftet nicht für Schäden oder Verlust von Stand Up Paddling Equipment, Gepäck und Wertgegenständen (Geldbörse,
                            Uhr, Handy, usw.) während der Tour, Training, Event oder beim Transport des Teilnehmers. Für Leistungsstörungen im Zusammenhang mit
                            den vermittelten Fremdleistungen haftet Carolin Pfeffer ebenfalls nicht.
                        </p>
                        <p>
                            7.7. Eine Haftung seitens Carolin Pfeffer oder Ihrer Mitarbeiter auf materielle oder immaterielle Schäden ist ausgeschlossen,
                            Vorsatz und grobe Fahrlässigkeit bleiben hiervon unberührt. Eine Haftung von Carolin Pfeffer für Körper- und Sachschäden, die durch
                            Dritte verursacht wurden, ist ebenfalls ausgeschlossen.
                        </p>
                        <p>
                            7.8. Wird die Tour, Training oder Event nicht vertragsmäßig erbracht, kann der Teilnehmer dies beanstanden. Dazu muss er die
                            Beanstandungen unverzüglich Carolin Pfeffer mitgeteilt werden.
                        </p>
                        <p>
                            7.9. Ansprüche wegen Nichterbringung oder nicht vertragsgemäßer Erbringung von Touren-, Training- oder Event-Leistungen müssen
                            innerhalb von 14 Tagen nach erbrachter Leistung Carolin Pfeffer gegenüber schriftlich geltend gemacht werden. Nach Ablauf der Frist,
                            ist dies nicht mehr möglich. Dies gilt auch, wenn der Mangel bereits während der Tour, Training oder Event beanstandet wurde.
                        </p>
                        <p>
                            7.10. Werden einzelne Leistungen wie Führungen, Tickets, Mietbike, etc. oder sonstige Veranstaltungen von Carolin Pfeffer
                            vermittelt, gelten die jeweiligen Bedingungen des fremden Vertragspartners.
                        </p>
                        <h3>Datenschutz</h3>
                        <p>
                            Der Teilnehmer ist, sofern er nichts anderes erklärt, mit der Weitergabe seines Namens seiner Adresse und Telefonnummer zur
                            Erstellung einer Teilnehmerliste einverstanden. Alle übrigen Daten werden vertraulich behandelt. Siehe Datenschutzerklärung.
                        </p>
                        <h3>Veranstalter</h3>
                        <p>Der Veranstalter ist die Firma Carolin Pfeffer, Inh: Carolin Pfeffer, Tummelgasse 95, 07929 Saalburg-Ebersdorf</p>
                        <h2>Carolin Pfeffer Shop</h2>
                        <p>
                            Bei Carolin Pfeffer Shop auf unserer Facebook Seite oder vor Ort in der Tummelgasse 95, 07929 Saalburg Ebersdorf, können SIe
                            Wassersportequipment einkaufen. Hierfür gelten folgende AGB.
                        </p>
                        <h3>Allgemeine Geschäftsbedingungen und Kundeninformationen</h3>
                        <p>
                            Carolin Pfeffer
                            <br />
                            Inhaber: Carolin Pfeffer
                            <br />
                            Tummelgasse 95
                            <br />
                            07929 Saalburg-Ebersdorf
                            <br />
                            Telefon: +49 163 88 02 363
                            <br />
                            E-Mail: Carolin Pfeffer@sportsadventures.eu
                            <br />
                            <br />- nachfolgend Anbieter -
                        </p>
                        <h3>§ 1 Geltungsbereich</h3>
                        <p>
                            (1) Die nachfolgenden Allgemeinen Geschäftsbedingungen (AGB) gelten für den Verkauf und die Versendung von Waren aus unserem Online
                            – Shop unter https://www.facebook.com/sportsadventures.eu/.
                        </p>
                        <p>
                            (2) Unsere Allgemeinen Geschäftsbedingungen gelten ausschließlich. Von unseren Allgemeinen Geschäftsbedingungen abweichende AGB des
                            Bestellers haben keine Gültigkeit, es sei denn, wir stimmen diesen ausdrücklich zu.
                        </p>
                        <p>
                            (2) Verbraucher im Sinne der nachstehenden Regelungen ist jede natürliche Person, die ein Rechtsgeschäft zu Zwecken abschließt, die
                            überwiegend weder ihrer gewerblichen noch ihrer selbständigen beruflichen Tätigkeit zugerechnet werden kann. Unternehmer ist jede
                            natürliche oder juristische Person oder eine rechtsfähige Personengesellschaft, die bei Abschluss eines Rechtsgeschäfts in Ausübung
                            ihrer selbständigen beruflichen oder gewerblichen Tätigkeit handelt.
                        </p>
                        <h3>§ 2 Vertragsschluss</h3>
                        <p>(1) Gegenstand des Vertrages ist der Verkauf von Waren.</p>
                        <p>(2) Unsere Angebote im Internet stellen lediglich eine unverbindliche Aufforderung an den Kunden dar, Waren zu bestellen.</p>
                        <p>
                            (3) Waren aus unserem Online – Shop können Sie direkt über den Online – Shop, per Telefon oder nach persönlichem Test der Ware vor
                            Ort (oben genannte Anschrift) bestellen/kaufen.
                        </p>
                        <p>
                            (4) Der Vertrag zwischen uns und Ihnen kommt noch nicht durch Ihre Bestellung, und auch nicht durch die Eingangsbestätigung
                            zustande, sondern erst durch die Annahme Ihrer Bestellung durch uns. Die Annahme Ihrer Bestellung erfolgt innerhalb von maximal 3
                            Werktagen durch Zusendung einer Annahmebestätigung (schriftlich oder per E-Mail) oder durch Lieferung der Ware. Nach fruchtlosem
                            Ablauf der Frist gilt Ihr Angebot als abgelehnt.
                        </p>
                        <p>
                            (5) Die Abwicklung der Bestellung und Übermittlung aller im Zusammenhang mit dem Vertragsschluss erforderlichen Informationen
                            erfolgt per E-Mail zum Teil automatisiert. Sie haben deshalb sicherzustellen, dass die von Ihnen bei uns hinterlegte E-Mail-Adresse
                            zutreffend ist, der Empfang der E-Mails technisch sichergestellt und insbesondere nicht durch SPAM-Filter verhindert wird.
                        </p>
                        <h3>§ 3 Preise und Zahlungsmodalitäten</h3>
                        <p>
                            (1) Es gelten die zum Zeitpunkt der Bestellung auf unserer Website aufgeführten Preise. Alle Preise verstehen sich inklusive der
                            gesetzlichen Mehrwertsteuer sowie zuzüglich der jeweils aufgeführten Versandkosten.
                        </p>
                        <p>(2) Die Zahlung des Kaufpreises ist möglich per Banküberweisung/ Vorauskasse oder dem Dienst von PayPal.</p>
                        <p>
                            (3) Im Falle des Widerrufs (Ziffer 9) haben Sie die unmittelbaren Kosten der Rücksendung zu tragen. Diese richten sich bei
                            paketversandfähiger Ware nach den jeweils aktuellen Tarifen der Versandunternehmen. Bei Rücksendung aus dem Ausland können ggf.
                            höhere Kosten anfallen.
                        </p>
                        <p>
                            (4)Soweit bei den einzelnen Zahlungsarten nicht anders angegeben, sind die Zahlungsansprüche aus dem geschlossenen Vertrag sofort
                            zur Zahlung fällig.
                        </p>
                        <h3>§ 4 Zurückbehaltungsrecht, Eigentumsvorbehalt</h3>
                        <p>(1) Ein Zurückbehaltungsrecht können Sie nur ausüben, soweit es sich um Forderungen aus demselben Vertragsverhältnis handelt.</p>
                        <p>
                            (2) Die Ware bleibt bis zur vollständigen Zahlung des Kaufpreises unser Eigentum. Dementsprechend dürfen gelieferte Gegenstände ohne
                            unsere Zustimmung nicht verändert oder veräußert werden.
                        </p>
                        <p>
                            (3) Ist der Besteller Kaufmann, so gilt der Vorbehalt des Eigentums bis zum Ausgleich sämtlicher Forderungen aus der laufenden
                            Geschäftsbeziehung mit uns. Der Besteller ist in diesem Fall berechtigt, die Ware im ordentlichen Geschäftsgang weiter zu veräußern.
                            Er tritt uns jedoch bereits jetzt alle Forderungen in Höhe des Rechnungsbetrages ab, die ihm durch die Weiterveräußerung gegen den
                            Erwerber erwachsen. Bei vertragswidrigem Verhalten des Bestellers, insbesondere bei Zahlungsverzug, sind wir berechtigt, die
                            Vorbehaltsware auf Kosten des Bestellers wegzunehmen. Der Besteller verzichtet insoweit bereits heute auf sein Recht zum Besitz. Wir
                            nehmen diesen Verzicht an.
                        </p>
                        <h3>§ 5 Gewährleistung</h3>
                        <p>(1) Es bestehen die gesetzlichen Mängelhaftungsrechte.</p>
                        <p>
                            (2) Bei gebrauchten Sachen beträgt die Gewährleistungsfrist abweichend von der gesetzlichen Regelung ein Jahr ab Ablieferung der
                            Sache. Die Fristverkürzung gilt nicht: - für uns zurechenbare schuldhaft verursachte Schäden aus der Verletzung des Lebens, des
                            Körpers oder der Gesundheit und bei vorsätzlich oder grob fahrlässig verursachten sonstigen Schäden; - soweit wir den Mangel
                            arglistig verschwiegen oder eine Garantie für die Beschaffenheit der Sache übernommen haben.
                        </p>
                        <p>
                            (3) Als Verbraucher werden Sie gebeten, die Sache bei Lieferung umgehend auf Vollständigkeit, offensichtliche Mängel und
                            Transportschäden zu überprüfen und uns sowie dem Spediteur Beanstandungen schnellstmöglich mitzuteilen. Kommen Sie dem nicht nach,
                            hat dies keine Auswirkung auf Ihre gesetzlichen Gewährleistungsansprüche.
                        </p>
                        <h3>§ 6 Rechtswahl</h3>
                        <p>
                            Anwendbar ist das Recht der Bundesrepublik Deutschland unter Ausschluss des UN-Kaufrechts. Wenn und soweit das Heimatrecht des
                            Verbrauchers zwingende gesetzliche Bestimmungen enthält, die ihm einen weiter gehenden Schutz gewähren, so gelten diese ergänzend.
                            Sind die Vertragsparteien Kaufleute, eine juristische Person des öffentlichen Rechts oder ein öffentlich-rechtliches Sondervermögen,
                            ist das Gericht an unserem Sitz in Berlin zuständig, sofern nicht für die Streitigkeit ein ausschließlicher Gerichtsstand begründet
                            ist. Dies gilt auch, wenn der Kunde keinen Wohnsitz innerhalb der Europäischen Union hat. Soweit eine oder mehrere der vorstehenden
                            Bestimmungen ungültig oder undurchsetzbar ist oder wird, bleibt die Wirksamkeit dieses Vertrages im Übrigen hiervon unberührt.
                        </p>
                        <h3>§ 7 Lieferung</h3>
                        <p>
                            (1) Die Lieferbedingungen, der Liefertermin sowie gegebenenfalls bestehende Lieferbeschränkungen finden sich unter einer
                            entsprechend bezeichneten Schaltfläche auf unserer Internetpräsenz oder im jeweiligen Angebot. Der Beginn der von uns angegebenen
                            Lieferzeit setzt die rechtzeitige und ordnungsgemäße Erfüllung der Verpflichtungen des Kunden voraus, insbesondere die korrekte
                            Angabe der Lieferadresse im Rahmen der Bestellung.
                        </p>
                        <p>(2) Wir liefern unsere Ware ausschließlich an Kunden mit Sitz in Europa. Andere Länder auf Anfrage.</p>{" "}
                        <p>
                            (3) Sofern nicht anders vereinbart, erfolgt die Lieferung ab unserem Lager oder ab Lager eines unserer Lieferanten. Soweit Sie
                            Verbraucher sind ist gesetzlich geregelt, dass die Gefahr des zufälligen Untergangs und der zufälligen Verschlechterung der
                            verkauften Sache während der Versendung erst mit der Übergabe der Ware an Sie übergeht, unabhängig davon, ob die Versendung
                            versichert oder unversichert erfolgt. Dies gilt nicht, wenn Sie eigenständig ein nicht vom Unternehmer benanntes
                            Transportunternehmen oder eine sonst zur Ausführung der Versendung bestimmte Person beauftragt haben.
                        </p>
                        <p>
                            (4) Wir behalten uns vor, Teillieferungen zu erbringen, es sei denn, dass dies für Sie erkennbar unzumutbar ist. Mehrkosten
                            entstehen Ihnen hierdurch nicht.
                        </p>
                        <h3>§ 8 Haftungsbegrenzung</h3>
                        <p>
                            (1) Wir haften für Personenschäden (Schäden an Leben, Körper und Gesundheit) stets gemäß den gesetzlichen Vorschriften. Für Sach-
                            oder Vermögensschäden, welche nicht an der Kaufsache selbst eingetreten sind, haften wir nur bei vorsätzlichem und grob fahrlässigem
                            Verhalten gemäß den gesetzlichen Vorschriften. In Fällen einfacher Fahrlässigkeit haften wir für Sach- und Vermögensschäden nur bei
                            Verletzung vertragswesentlicher Pflichten (Kardinalpflichten). Die Haftung beschränkt sich insoweit auf den bei Vertragsschluss
                            vorhersehbaren typischen Vertragsschaden. Eine Haftung für vertragsuntypische mittelbare oder Folgeschäden ist in einem solchen Fall
                            ausgeschlossen.
                        </p>
                        <p>
                            (2) Soweit unsere Haftung ausgeschlossen oder beschränkt ist, gilt dies auch für die Haftung von Erfüllungsgehilfen und sonstiger
                            Personen, deren Verhalten uns zugerechnet werden kann.
                        </p>
                        <p>
                            (3) Zwingende gesetzliche Haftungsvorschriften, insbesondere die Haftung bei Abgabe einer Beschaffenheitsgarantie, arglistigem
                            Verschweigen eines Mangels sowie die Haftung nach dem Produkthaftungsgesetz, bleiben von den vorstehenden Regelungen unberührt.
                        </p>
                        <h3>§ 9 Widerrufsrecht</h3>
                        <p>
                            Sie haben Sie das Recht, binnen vierzehn Tagen ohne Angabe von Gründen diesen Vertrag zu widerrufen. Die Widerrufsfrist beträgt
                            vierzehn Tage ab dem Tag, an dem Sie oder ein von Ihnen benannter Dritter, der nicht der Beförderer ist, die Waren (bei einem
                            Vertrag über mehrere Waren: die letzte Ware) in Besitz genommen haben bzw. hat.
                        </p>
                        <p>Um Ihr Widerrufsrecht auszuüben, müssen Sie uns</p>
                        <p>
                            Carolin Pfeffer
                            <br />
                            Inhaber: Carolin Pfeffer
                            <br />
                            Tummelgasse 95
                            <br />
                            07929 Saalburg-Ebersdorf
                            <br />
                            Telefon: +49 163 88 02 363
                            <br />
                            E-Mail: info@carolinpfeffer.de
                        </p>
                        <p>
                            mittels einer eindeutigen Erklärung (z.B. ein mit der Post versandter Brief, Telefax oder E-Mail) über Ihren Entschluss, diesen
                            Vertrag zu widerrufen, informieren. Sie können dafür das angehängte Muster-Widerrufsformular verwenden, das jedoch nicht
                            vorgeschrieben ist. Zur Wahrung der Widerrufsfrist reicht es aus, dass Sie die Mitteilung über die Ausübung des Widerrufsrechts vor
                            Ablauf der Widerrufsfrist absenden.
                        </p>
                        <p className="fw-bold">Folgen des Widerrufs:</p>
                        <p>
                            Wenn Sie diesen Vertrag widerrufen, haben wir Ihnen alle Zahlungen, die wir von Ihnen erhalten haben, einschließlich der
                            Lieferkosten (mit Ausnahme der zusätzlichen Kosten, die sich daraus ergeben, dass Sie eine andere Art der Lieferung als die von uns
                            angebotene, günstigste Standardlieferung gewählt haben), unverzüglich und spätestens binnen vierzehn Tagen ab dem Tag
                            zurückzuzahlen, an dem die Mitteilung über Ihren Widerruf dieses Vertrags bei uns eingegangen ist. Für diese Rückzahlung verwenden
                            wir dasselbe Zahlungsmittel, das Sie bei der ursprünglichen Transaktion eingesetzt haben, es sei denn, mit Ihnen wurde ausdrücklich
                            etwas anderes vereinbart; in keinem Fall werden Ihnen wegen dieser Rückzahlung Entgelte berechnet. Wir können die Rückzahlung
                            verweigern, bis wir die Waren wieder zurückerhalten haben oder bis Sie den Nachweis erbracht haben, dass Sie die Waren zurückgesandt
                            haben, je nachdem, welches der frühere Zeitpunkt ist. Sie haben die Waren unverzüglich und in jedem Fall spätestens binnen vierzehn
                            Tagen ab dem Tag, an dem Sie uns über den Widerruf dieses Vertrags unterrichten, an uns zurückzusenden oder zu übergeben. Die Frist
                            ist gewahrt, wenn Sie die Waren vor Ablauf der Frist von vierzehn Tagen absenden. Sie tragen die unmittelbaren Kosten der
                            Rücksendung der Waren. Die Kosten werden auf höchstens etwa 100 EUR geschätzt. Sie müssen für einen etwaigen Wertverlust der Waren
                            nur aufkommen, wenn dieser Wertverlust auf einen zur Prüfung der Beschaffenheit, Eigenschaften und Funktionsweise der Waren nicht
                            notwendigen Umgang mit ihnen zurückzuführen ist.
                        </p>
                        <p>Ein Widerrufsrecht besteht nicht, und Sie können den Vertrag nicht widerrufen bei folgenden Verträgen:</p>
                        <ul>
                            <li>
                                Verträge zur Lieferung von Waren, die nicht vorgefertigt sind und für deren Herstellung eine individuelle Auswahl oder
                                Bestimmung durch den Verbraucher maßgeblich ist oder die eindeutig auf die persönlichen Bedürfnisse des Verbrauchers
                                zugeschnitten sind.
                            </li>
                            <li>
                                Verträge zur Lieferung versiegelter Waren, die aus Gründen des Gesundheitsschutzes oder der Hygiene nicht zur Rückgabe geeignet
                                sind, wenn ihre Versiegelung nach der Lieferung entfernt wurde.
                            </li>
                        </ul>
                        <h3>§ 10 Datenschutz</h3>
                        <p>
                            Wir behandeln Ihre personenbezogenen Daten vertraulich und entsprechend der gesetzlichen Datenschutzvorschriften. Eine Weitergabe
                            Ihrer Daten ohne Ihre ausdrückliche Einwilligung erfolgt nicht bzw. nur im Rahmen der notwendigen Abwicklung des Vertrages, etwa an
                            die mit der Lieferung der Ware betrauten Unternehmen. Weitere Informationen finden Sie unter Datenschutz.
                        </p>
                        <h3>§ 11 Schlussbestimmungen</h3>
                        <p>
                            Sollte eine Bestimmung dieser Allgemeinen Geschäftsbedingungen unwirksam sein, so bleibt der Vertrag im Übrigen wirksam. Anstelle
                            der unwirksamen Bestimmung gelten die einschlägigen gesetzlichen Vorschriften.
                        </p>
                        <h3>Kundeninformationen</h3>
                        <h4>Vertragssprache und Textspeicherung</h4>
                        <p>(1) Vertragssprache ist deutsch.</p>
                        <p>
                            (2) Der vollständige Vertragstext wird von uns nicht gespeichert. Vor Absenden der Bestellung über das Online - Warenkorbsystem
                            können die Vertragsdaten über die Druckfunktion des Browsers ausgedruckt oder elektronisch gesichert werden. Nach Zugang der
                            Bestellung bei uns werden die Bestelldaten, die gesetzlich vorgeschriebenen Informationen bei Fernabsatzverträgen und die
                            Allgemeinen Geschäftsbedingungen nochmals per E-Mail an Sie übersandt.
                        </p>
                        <p>
                            (3) Bei Angebotsanfragen außerhalb des Online-Warenkorbsystems erhalten Sie alle Vertragsdaten im Rahmen eines verbindlichen
                            Angebotes in Textform übersandt, z.B. per E-Mail, welche Sie ausdrucken oder elektronisch sichern können.
                        </p>
                        <h4>Gesetzliches Mängelhaftungsrecht </h4>
                        <p>Die Mängelhaftung richtet sich nach der Regelung "Gewährleistung" in unseren Allgemeinen Geschäftsbedingungen (Teil I).</p>
                        <p>
                            Carolin Pfeffer
                            <br />
                            Inhaber: Carolin Pfeffer
                            <br />
                            Tummelgasse 95
                            <br />
                            07929 Saalburg-Ebersdorf
                            <br />
                            Telefon: +49 163 88 02 363
                            <br />
                            E-Mail: info@carolinpfeffer.de
                            <br />
                            Ust.Nr.: DE311129040
                        </p>
                        <h3>Muster Widerrufsformular</h3>
                        <p>
                            An die
                            <br />
                            Carolin Pfeffer
                            <br />
                            Inhaber: Carolin Pfeffer
                            <br />
                            Tummelgasse 95
                            <br />
                            07929 Saalburg-Ebersdorf
                            <br />
                            Telefon: +49 163 88 02 363
                            <br />
                            E-Mail: info@carolinpfeffer.de
                        </p>
                        <br />
                        <br />
                        <p>Hiermit widerrufe(n) ich/wir den von mir/uns abgeschlossenen Vertrag über den Kauf folgender Waren:</p>
                        <br />
                        <br />
                        <p>...................................................</p>
                        <p>(Beschreibung Ware)</p>
                        <br />
                        <br />
                        <p>...................................................</p>
                        <p>(Bestellnummer und Preis)</p>
                        <br />
                        <br />
                        <p>Bestellt am:</p>
                        <p>Erhalten am:</p>
                        <br />
                        <br />
                        <p>Name und Anschrift (des Kunden)</p>
                        <p>...................................................</p>
                        <p>...................................................</p>
                        <p>...................................................</p>
                        <br />
                        <br />
                        <p>...................................................</p>
                        <p>Ort, Datum</p>
                        <br />
                        <br />
                        <p>...................................................</p>
                        <p>Unterschrift des Kunden</p>
                    </div>
                </div>
            </main>
            <NewsletterFormular></NewsletterFormular>
            <Footer />
        </Fragment>
    );
}

export default AGB;
