import React, { useRef, useState } from "react";
import "./NewsletterFormular.css";
import { Link } from "react-router-dom";

function NewsletterFormular({ id }) {
    const formNewsletter = useRef();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [responseAlert, setResponseAlert] = useState(false);

    const handleFormNewsletterSubmit = async (e) => {
        e.preventDefault();

        const formData = new FormData(formNewsletter.current);

        setIsSubmitting(true);

        try {
            const response = await fetch(
                "https://3c8386f3.sibforms.com/serve/MUIFAF6uCsH8PPxyu4g4zuXU4ZkB22HgfGSUSHHj3WnQqLpA4YXx_zqQzlw5sdU_Q3V1Vy1wpaC1U5LGbVNq5H8yKOCfTeV7XkS3mC4l5uGJrMEApvOunK8apMKvdxsoVaLu6qGZk9vrx1HKyZvjK32P3psq0N25iK7r4UxR4BJQ5Wt3oFiWpUfqiNrDyn8ajJYNzGUtPn12X2-t",
                {
                    method: "POST",
                    mode: "no-cors",
                    body: formData,
                }
            );

            if (!response.ok) {
                throw new Error("Network response was not ok"); // fetch wirft diesen Fehler, Post geht aber durch
            }

            const data = await response.json();
            console.log("Response from server:", data);
        } catch (error) {
            console.error("Error:", error);
        } finally {
            setIsSubmitting(false);
            setResponseAlert(true);
            formNewsletter.current["EMAIL"].value = "";
            formNewsletter.current["VORNAME"].value = "";
            formNewsletter.current["lists_30[]"].forEach((element) => (element.checked = false));
            formNewsletter.current["OPT_IN"].checked = false;
        }
    };

    return (
        <div className="bg-primary-dark wrapper py-7 px-4" id={id}>
            <div className="newsletter-content">
                <span className="d-flex gap-3 align-items-center justify-content-center flex-wrap position-relative mb-7 h2--border">
                    <span className="h2 mb-0 text-initial color-secondary">Werde Teil der</span>
                    <span className="fs-60px font-rollerscript color-secondary">Community</span>
                </span>
                <p className="color-background mb-5">
                    Hier kannst du dich zu meinem Newsletter anmelden. Als Teil der Community erhältst du regelmäßig exklusive Inhalte, Tipps und News rund um
                    Yoga, Fitness und einen gesunden Lifestyle.
                </p>
                <form ref={formNewsletter} onSubmit={handleFormNewsletterSubmit} className="d-flex flex-column">
                    <div className="d-flex flex-wrap gap-3 mb-4">
                        <input className="bg-background p-2 flex-grow-1" type="email" name="EMAIL" placeholder="Deine E-Mail-Adresse*" required />
                        <input className="bg-background p-2 flex-grow-1" type="text" name="VORNAME" placeholder="Dein Vorname*" required />
                    </div>
                    <span className="color-background mb-4">Wähle deine Interessen aus, für die du dich anmelden möchtest.*</span>
                    <div className="d-flex gap-5 mb-5">
                        <div>
                            <div className="d-flex align-items-center gap-4 mb-3">
                                <input type="checkbox" name="lists_30[]" id="list-check-1" value="7" className="form-checkbox" />
                                <label className="fs-body color-background" htmlFor="list-check-1">
                                    Ernährung
                                </label>
                            </div>
                            <div className="d-flex align-items-center gap-4">
                                <input type="checkbox" name="lists_30[]" id="list-check-2" value="6" className="form-checkbox" />
                                <label className="fs-body color-background" htmlFor="list-check-2">
                                    Fitness
                                </label>
                            </div>
                        </div>
                        <div>
                            <div className="d-flex align-items-center gap-4 mb-3">
                                <input type="checkbox" name="lists_30[]" id="list-check-3" value="5" className="form-checkbox" />
                                <label className="fs-body color-background" htmlFor="list-check-3">
                                    Yoga
                                </label>
                            </div>
                            <div className="d-flex align-items-center gap-4">
                                <input type="checkbox" name="lists_30[]" id="list-check-4" value="4" className="form-checkbox" />
                                <label className="fs-body color-background" htmlFor="list-check-4">
                                    Stand Up Paddling
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex align-items-start gap-4 mb-5">
                        <input type="checkbox" name="OPT_IN" value="1" id="datenschutz-check" className="form-checkbox" required />
                        <label htmlFor="datenschutz-check">
                            <span className="d-block color-background">
                                Ich möchte den Newsletter erhalten und akzeptiere die{" "}
                                <a href="/datenschutz" title="zur Datenschutzerklärung" target="_blank">
                                    Datenschutzerklärung
                                </a>
                                .*
                            </span>
                            <span className="d-block color-secondary">Du kannst den Newsletter jederzeit über den Link im Newsletter abbestellen.</span>
                        </label>
                    </div>
                    <div className="d-flex gap-5 align-items-start flex-wrap">
                        <button type="submit" className="btn-dark-primary-border" disabled={isSubmitting}>
                            {isSubmitting ? "Wird gesendet..." : "jetzt anmelden"}
                        </button>
                        <div>
                            <span className="color-background">Ich freue mich auf dich.</span>
                            <br />
                            <span className="color-secondary fs-1 font-rollerscript">Carolin</span>
                        </div>
                    </div>
                    <input type="hidden" name="locale" value="de" />
                    <input type="hidden" name="html_type" value="simple" />
                </form>
                <span className="fs-6 color-background">*Pflichtfeld</span>
                {responseAlert ? (
                    <div className="alert alert-success d-flex align-items-center mt-4" role="alert">
                        <i className="bi bi-check-circle-fill flex-shrink-0 me-3"></i>
                        <div>
                            Schau in dein E-Mail-Postfach, um die Anmeldung gleich abzuschließen!<br></br>Keine E-Mail bekommen? Achte auf Tippfehler.
                        </div>
                    </div>
                ) : null}
            </div>
        </div>
    );
}

export default NewsletterFormular;
