import React, { useEffect, useState } from "react";
import "./SocialIcons.css";

function SocialIcons({ schema }) {
    const [data, setData] = useState();
    const url = "https://admin.carolinpfeffer.de/items/Social_Media_Icons?sort=sort";

    useEffect(() => {
        function getSocialIcons() {
            fetch(url)
                .then((data) => data.json())
                .then((resp) => {
                    setData(resp.data);
                })
                .catch((error) => console.error(error));
        }
        try {
            getSocialIcons();
        } catch (error) {
            console.error(error);
        }
    }, [url]);
    return (
        <div className={"SocialIcons"}>
            {data
                ? data.map((item, index) => {
                      return (
                          <a className={schema} href={item.Link} target="_blank" title={item.Platform} rel="noopener noreferrer nofollow" key={index}>
                              <span>{item.Platform} -</span>

                              <i className={"bi " + item.icon}></i>
                          </a>
                      );
                  })
                : null}
        </div>
    );
}

export default SocialIcons;
