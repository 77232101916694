import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import "./ContentBlockUSPs.css";
import DialogKontakt from "./DialogKontakt";

function ContentBlockUSPs({ headline, usps, cta, buttonText, buttonLink, alignment, targetDialog }) {
    const styles = {
        content: {
            position: "relative",
            flexDirection: alignment === "right" ? "row" : "row-reverse",
        },
        ContentBlockUSPs__ctaBackground: {
            right: alignment === "left" ? "67%" : "-50vw",
            left: alignment === "right" ? "67%" : "-50vw",
        },
    };

    const openDialog = () => {
        document.getElementById(targetDialog).showModal();
        document.body.style.overflowY = "hidden";
    };

    return (
        <Fragment>
            <div className="ContentBlockUSPs">
                <div className="wrapper">
                    <div className="content d-flex align-items-center justify-content-between gap-4" style={styles.content}>
                        <div className="ContentBlockUSPs__left">
                            <span className="h4 d-block mb-5">{headline}</span>
                            <ul className="left__usps">
                                {usps.map((data, idx) => (
                                    <li key={idx}>{data}</li>
                                ))}
                            </ul>
                        </div>
                        <div className="ContentBlockUSPs__right">
                            <span className="right__cta d-block mb-2 mb-md-4">{cta}</span>
                            {buttonLink.includes("#") ? (
                                <a href={buttonLink} title={buttonText} className="btn-dark-white">
                                    {buttonText}
                                </a>
                            ) : null}
                            {buttonLink === "dialog" ? <input type="button" className="btn-dark-white" value={buttonText} onClick={openDialog} /> : null}
                            {!buttonLink.includes("#") && !buttonLink === "dialog" ? (
                                <Link to={buttonLink} title={buttonText} className="btn-dark-white">
                                    {buttonText}
                                </Link>
                            ) : null}
                        </div>
                        <div className="ContentBlockUSPs__ctaBackground" style={styles.ContentBlockUSPs__ctaBackground}></div>
                    </div>
                </div>
            </div>
            <DialogKontakt />
        </Fragment>
    );
}

export default ContentBlockUSPs;
