import React, { useEffect, useState } from "react";
import Instafeed from "instafeed.js";
import "./InstagramFeed.css";

const InstagramFeed = ({ accessToken }) => {
    const [feed, setFeed] = useState(
        new Instafeed({
            get: "user",
            accessToken: accessToken,
            resolution: "standard_resolution",
            limit: 4,
            template:
                '<div className="instafeed-cont"><div className="instafeed-item"><a target="_blank" href="{{link}}" title="zum Beitrag"><div className="instafeed-img" style="background-image: url({{image}})"></div></a><p>{{caption}}</p></div></div>',
        })
    );
    useEffect(() => {
        feed.run();
    }, []);

    return <div id="instafeed"></div>;
};

export default InstagramFeed;
