import React, { Fragment, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import Header from "../components/Header";
import Footer from "../components/Footer";
import ScrollToTop from "../components/ScrollToTop";
import NewsletterFormular from "../components/NewsletterFormular";

function Datenschutz() {
    const [schema, setSchema] = useState("light");
    const [schemaHeader, setSchemaHeader] = useState("dark");

    useEffect(() => {
        window.addEventListener("scroll", () => {
            if (window.scrollY > 300) {
                setSchema("dark");
            } else {
                setSchema("light");
            }
        });
    }, []);

    return (
        <Fragment>
            <Helmet>
                <title>Datenschutz | Carolin Pfeffer</title>
                <meta name="description" content=""></meta>
                <meta name="keywords" content=""></meta>
            </Helmet>
            <ScrollToTop />
            <Header schema={schemaHeader} />
            <main className="w-100">
                <div className="wrapper">
                    <div className="content" style={{ paddingTop: "15rem" }}>
                        <h1 className="mb-5">Datenschutz</h1>
                        <h2 className="mb-4">Datenschutz auf einen Blick</h2>
                        <h3>Allgemeine Hinweise</h3>
                        <p className="mb-5">
                            Die folgenden Hinweise geben einen einfachen Überblick darüber, was mit Ihren personenbezogenen Daten passiert, wenn Sie unsere
                            Website besuchen. Personenbezogene Daten sind alle Daten, mit denen Sie persönlich identifiziert werden können. Ausführliche
                            Informationen zum Thema Datenschutz entnehmen Sie unserer unter diesem Text aufgeführten Datenschutzerklärung.
                        </p>
                        <h3>Datenerfassung auf unserer Website</h3>
                        <p className="mb-5">
                            Wer ist verantwortlich für die Datenerfassung auf dieser Website? Die Datenverarbeitung auf dieser Website erfolgt durch den
                            Websitebetreiber. Dessen Kontaktdaten können Sie dem Impressum dieser Website entnehmen.
                        </p>
                        <p className="fw-bold">Wie erfassen wir Ihre Daten?</p>
                        <p className="mb-5">
                            Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese mitteilen. Hierbei kann es sich z.B. um Daten handeln, die Sie in
                            ein Kontaktformular eingeben. Andere Daten werden automatisch beim Besuch der Website durch unsere IT-Systeme erfasst. Das sind vor
                            allem technische Daten (z.B. Internetbrowser, Betriebssystem oder Uhrzeit des Seitenaufrufs). Die Erfassung dieser Daten erfolgt
                            automatisch, sobald Sie unsere Website betreten.
                        </p>
                        <p className="fw-bold">Wofür nutzen wir Ihre Daten?</p>
                        <p className="mb-5">
                            Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung der Website zu gewährleisten. Andere Daten können zur Analyse
                            Ihres Nutzerverhaltens verwendet werden.
                        </p>
                        <p className="fw-bold">Welche Rechte haben Sie bezüglich Ihrer Daten?</p>
                        <p className="mb-5">
                            Sie haben jederzeit das Recht unentgeltlich Auskunft über Herkunft, Empfänger und Zweck Ihrer gespeicherten personenbezogenen Daten
                            zu erhalten. Sie haben außerdem ein Recht, die Berichtigung, Sperrung oder Löschung dieser Daten zu verlangen. Hierzu sowie zu
                            weiteren Fragen zum Thema Datenschutz können Sie sich jederzeit unter der im Impressum angegebenen Adresse an uns wenden. Des
                            Weiteren steht Ihnen ein Beschwerderecht bei der zuständigen Aufsichtsbehörde zu
                        </p>
                        <h3>Analyse-Tools und Tools von Drittanbietern</h3>
                        <p className="mb-5">
                            Beim Besuch unserer Website kann Ihr Surf-Verhalten statistisch ausgewertet werden. Das geschieht vor allem mit Cookies und mit
                            sogenannten Analyseprogrammen. Die Analyse Ihres Surf-Verhaltens erfolgt in der Regel anonym; das Surf-Verhalten kann nicht zu Ihnen
                            zurückverfolgt werden. Sie können dieser Analyse widersprechen oder sie durch die Nichtbenutzung bestimmter Tools verhindern.
                            Detaillierte Informationen dazu finden Sie in der folgenden Datenschutzerklärung. Sie können dieser Analyse widersprechen. Über die
                            Widerspruchsmöglichkeiten werden wir Sie in dieser Datenschutzerklärung informieren
                        </p>
                        <h2 className="mb-4">Allgemine Hinweise und Pflichtinformationen</h2>
                        <h3>Datenschutz</h3>
                        <p className="mb-5">
                            Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen Daten sehr ernst. Wir behandeln Ihre personenbezogenen Daten
                            vertraulich und entsprechend der gesetzlichen Datenschutzvorschriften sowie dieser Datenschutzerklärung. Wenn Sie diese Website
                            benutzen, werden verschiedene personenbezogene Daten erhoben. Personenbezogene Daten sind Daten, mit denen Sie persönlich
                            identifiziert werden können. Die vorliegende Datenschutzerklärung erläutert, welche Daten wir erheben und wofür wir sie nutzen. Sie
                            erläutert auch, wie und zu welchem Zweck das geschieht. Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B. bei der
                            Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht
                            möglich.
                        </p>
                        <h3>Hinweis zur verantwortlichen Stelle</h3>
                        <p className="mb-5">
                            Die verantwortliche Stelle für die Datenverarbeitung auf dieser Website ist:
                            <br />
                            Carolin Pfeffer
                            <br />
                            Tummelgasse 95
                            <br />
                            07929 Saalburg-Ebersdorf
                            <br />
                            +49 163 88 02 363
                            <br />
                            mail: info@carolinpfeffer.de
                        </p>
                        <p className="mb-5">
                            Verantwortliche Stelle ist die natürliche oder juristische Person, die allein oder gemeinsam mit anderen über die Zwecke und Mittel
                            der Verarbeitung von personenbezogenen Daten (z.B. Namen, E-Mail-Adressen o. Ä.) entscheidet.
                        </p>
                        <h3>Widerruf ihrer einwilligung zur Datenverarbeitung</h3>
                        <p className="mb-5">
                            Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen Einwilligung möglich. Sie können eine bereits erteilte
                            Einwilligung jederzeit widerrufen. Dazu reicht eine formlose Mitteilung per E-Mail an uns. Die Rechtmäßigkeit der bis zum Widerruf
                            erfolgten Datenverarbeitung bleibt vom Widerruf unberührt.
                        </p>
                        <h3>Beschwerderecht bei der zuständigen Aufsichtsbehörde</h3>
                        <p className="mb-5">
                            Im Falle datenschutzrechtlicher Verstöße steht dem Betroffenen ein Beschwerderecht bei der zuständigen Aufsichtsbehörde zu.
                            Zuständige Aufsichtsbehörde in datenschutzrechtlichen Fragen ist der Landesdatenschutzbeauftragte des Bundeslandes, in dem unser
                            Unternehmen seinen Sitz hat. Eine Liste der Datenschutzbeauftragten sowie deren Kontaktdaten können folgendem Link entnommen werden:
                            <a href="https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html">
                                https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html
                            </a>
                            .
                        </p>
                        <h3>Recht auf Datenübertragbarkeit</h3>
                        <p className="mb-5">
                            Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung oder in Erfüllung eines Vertrags automatisiert verarbeiten, an
                            sich oder an einen Dritten in einem gängigen, maschinenlesbaren Format aushändigen zu lassen. Sofern Sie die direkte Übertragung der
                            Daten an einen anderen Verantwortlichen verlangen, erfolgt dies nur, soweit es technisch machbar ist.
                        </p>
                        <h3>SSL- bzw. TLS-Verschlüsselung</h3>
                        <p className="mb-5">
                            Diese Seite nutzt aus Sicherheitsgründen und zum Schutz der Übertragung vertraulicher Inhalte, wie zum Beispiel Bestellungen oder
                            Anfragen, die Sie an uns als Seitenbetreiber senden, eine SSL-bzw. TLS-Verschlüsselung. Eine verschlüsselte Verbindung erkennen Sie
                            daran, dass die Adresszeile des Browsers von “http://” auf “https://” wechselt und an dem Schloss-Symbol in Ihrer Browserzeile. Wenn
                            die SSL- bzw. TLS-Verschlüsselung aktiviert ist, können die Daten, die Sie an uns übermitteln, nicht von Dritten mitgelesen werden.
                        </p>
                        <h3>Auskunft, Sperrung, Löschung</h3>
                        <p className="mb-5">
                            Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit das Recht auf unentgeltliche Auskunft über Ihre gespeicherten
                            personenbezogenen Daten, deren Herkunft und Empfänger und den Zweck der Datenverarbeitung und ggf. ein Recht auf Berichtigung,
                            Sperrung oder Löschung dieser Daten. Hierzu sowie zu weiteren Fragen zum Thema personenbezogene Daten können Sie sich jederzeit
                            unter der im Impressum angegebenen Adresse an uns wenden.
                        </p>
                        <h3>Widerspruch gegen Werbe-Mails</h3>
                        <p className="mb-5">
                            Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten Kontaktdaten zur Übersendung von nicht ausdrücklich angeforderter
                            Werbung und Informationsmaterialien wird hiermit widersprochen. Die Betreiber der Seiten behalten sich ausdrücklich rechtliche
                            Schritte im Falle der unverlangten Zusendung von Werbeinformationen, etwa durch Spam-E-Mails, vor.
                        </p>
                        <h2 className="mb-4">Datenerfassung auf unserer Website</h2>
                        <h3>Cookies</h3>
                        <p className="mb-5">
                            Die Internetseiten verwenden teilweise so genannte Cookies. Cookies richten auf Ihrem Rechner keinen Schaden an und enthalten keine
                            Viren. Cookies dienen dazu, unser Angebot nutzerfreundlicher, effektiver und sicherer zu machen. Cookies sind kleine Textdateien,
                            die auf Ihrem Rechner abgelegt werden und die Ihr Browser speichert. Die meisten der von uns verwendeten Cookies sind so genannte
                            “Session-Cookies”. Sie werden nach Ende Ihres Besuchs automatisch gelöscht. Andere Cookies bleiben auf Ihrem Endgerät gespeichert
                            bis Sie diese löschen. Diese Cookies ermöglichen es uns, Ihren Browser beim nächsten Besuch wiederzuerkennen. Sie können Ihren
                            Browser so einstellen, dass Sie über das Setzen von Cookies informiert werden und Cookies nur im Einzelfall erlauben, die Annahme
                            von Cookies für bestimmte Fälle oder generell ausschließen sowie das automatische Löschen der Cookies beim Schließen des Browser
                            aktivieren. Bei der Deaktivierung von Cookies kann die Funktionalität dieser Website eingeschränkt sein. Cookies, die zur
                            Durchführung des elektronischen Kommunikationsvorgangs oder zur Bereitstellung bestimmter, von Ihnen erwünschter Funktionen (z.B.
                            Warenkorbfunktion) erforderlich sind, werden auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO gespeichert. Der Websitebetreiber hat ein
                            berechtigtes Interesse an der Speicherung von Cookies zur technisch fehlerfreien und optimierten Bereitstellung seiner Dienste.
                            Soweit andere Cookies (z.B. Cookies zur Analyse Ihres Surfverhaltens) gespeichert werden, werden diese in dieser
                            Datenschutzerklärung gesondert behandelt.
                        </p>
                        <h3>Server-Log-Dateien</h3>
                        <p className="mb-5">
                            Der Provider der Seiten erhebt und speichert automatisch Informationen in so genannten Server-Log-Dateien, die Ihr Browser
                            automatisch an uns übermittelt. Dies sind:
                            <ul>
                                <li>Browsertyp und Browserversion</li>
                                <li>verwendetes Betriebssystem</li>
                                <li>Referrer URL</li>
                                <li>Hostname des zugreifenden Rechners</li>
                                <li>Uhrzeit der Serveranfrage</li>
                                <li>IP-Adresse</li>
                            </ul>
                            Eine Zusammenführung dieser Daten mit anderen Datenquellen wird nicht vorgenommen. Grundlage für die Datenverarbeitung ist Art. 6
                            Abs. 1 lit. f DSGVO, der die Verarbeitung von Daten zur Erfüllung eines Vertrags oder vorvertraglicher Maßnahmen gestattet.
                        </p>
                        <h3>Kontaktformular</h3>
                        <p className="mb-5">
                            Wenn Sie uns per Kontaktformular Anfragen zukommen lassen, werden Ihre Angaben aus dem Anfrageformular inklusive der von Ihnen dort
                            angegebenen Kontaktdaten zwecks Bearbeitung der Anfrage und für den Fall von Anschlussfragen bei uns gespeichert. Diese Daten geben
                            wir nicht ohne Ihre Einwilligung weiter. Die Verarbeitung der in das Kontaktformular eingegebenen Daten erfolgt somit ausschließlich
                            auf Grundlage Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO). Sie können diese Einwilligung jederzeit widerrufen. Dazu reicht eine
                            formlose Mitteilung per E-Mail an uns. Die Rechtmäßigkeit der bis zum Widerruf erfolgten Datenverarbeitungsvorgänge bleibt vom
                            Widerruf unberührt. Die von Ihnen im Kontaktformular eingegebenen Daten verbleiben bei uns, bis Sie uns zur Löschung auffordern,
                            Ihre Einwilligung zur Speicherung widerrufen oder der Zweck für die Datenspeicherung entfällt (z.B. nach abgeschlossener Bearbeitung
                            Ihrer Anfrage). Zwingende gesetzliche Bestimmungen – insbesondere Aufbewahrungsfristen – bleiben unberührt.
                        </p>
                        <h2 className="mb-4">Analyse Tools und Werbung</h2>
                        <h3>Google Analytics</h3>
                        <p className="mb-5">
                            Diese Website nutzt Funktionen des Webanalysedienstes Google Analytics. Anbieter ist die Google Inc., 1600 Amphitheatre Parkway,
                            Mountain View, CA 94043, USA. Google Analytics verwendet so genannte "Cookies". Das sind Textdateien, die auf Ihrem Computer
                            gespeichert werden und die eine Analyse der Benutzung der Website durch Sie ermöglichen. Die durch den Cookie erzeugten
                            Informationen über Ihre Benutzung dieser Website werden in der Regel an einen Server von Google in den USA übertragen und dort
                            gespeichert. Die Speicherung von Google-Analytics-Cookies erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Der Websitebetreiber
                            hat ein berechtigtes Interesse an der Analyse des Nutzerverhaltens, um sowohl sein Webangebot als auch seine Werbung zu optimieren.
                        </p>
                        <h3>IP Anonymisierung</h3>
                        <p className="mb-5">
                            Wir haben auf dieser Website die Funktion IP-Anonymisierung aktiviert. Dadurch wird Ihre IP-Adresse von Google innerhalb von
                            Mitgliedstaaten der Europäischen Union oder in anderen Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum vor der
                            Übermittlung in die USA gekürzt. Nur in Ausnahmefällen wird die volle IP-Adresse an einen Server von Google in den USA übertragen
                            und dort gekürzt. Im Auftrag des Betreibers dieser Website wird Google diese Informationen benutzen, um Ihre Nutzung der Website
                            auszuwerten, um Reports über die Websiteaktivitäten zusammenzustellen und um weitere mit der Websitenutzung und der Internetnutzung
                            verbundene Dienstleistungen gegenüber dem Websitebetreiber zu erbringen. Die im Rahmen von Google Analytics von Ihrem Browser
                            übermittelte IP-Adresse wird nicht mit anderen Daten von Google zusammengeführt.
                        </p>
                        <h3>Browser Plugin</h3>
                        <p className="mb-5">
                            Sie können die Speicherung der Cookies durch eine entsprechende Einstellung Ihrer Browser-Software verhindern; wir weisen Sie jedoch
                            darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche Funktionen dieser Website vollumfänglich werden nutzen können.
                            Sie können darüber hinaus die Erfassung der durch den Cookie erzeugten und auf Ihre Nutzung der Website bezogenen Daten (inkl. Ihrer
                            IP-Adresse) an Google sowie die Verarbeitung dieser Daten durch Google verhindern, indem Sie das unter dem folgenden Link verfügbare
                            Browser-Plugin herunterladen und installieren:{" "}
                            <a href="https://tools.google.com/dlpage/gaoptout?hl=de">https://tools.google.com/dlpage/gaoptout?hl=de</a>.
                        </p>
                        <h3>Widerspruch gegen Datenerfassung</h3>
                        <p className="mb-5">
                            Sie können die Erfassung Ihrer Daten durch Google Analytics verhindern, indem Sie auf folgenden Link klicken. Es wird ein
                            Opt-Out-Cookie gesetzt, der die Erfassung Ihrer Daten bei zukünftigen Besuchen dieser Website verhindert: Google Analytics
                            deaktivieren. Mehr Informationen zum Umgang mit Nutzerdaten bei Google Analytics finden Sie in der Datenschutzerklärung von Google:
                            <a href="https://support.google.com/analytics/answer/6004245?hl=de">https://support.google.com/analytics/answer/6004245?hl=de</a>.
                        </p>
                        <h3>Auftragsdatenverarbeitung</h3>
                        <p className="mb-5">
                            Wir haben mit Google einen Vertrag zur Auftragsdatenverarbeitung abgeschlossen und setzen die strengen Vorgaben der deutschen
                            Datenschutzbehörden bei der Nutzung von Google Analytics vollständig um.
                        </p>
                        <h3>Demografische Merkmale bei Google Analytics</h3>
                        <p className="mb-5">
                            Diese Website nutzt die Funktion “demografische Merkmale” von Google Analytics. Dadurch können Berichte erstellt werden, die
                            Aussagen zu Alter, Geschlecht und Interessen der Seitenbesucher enthalten. Diese Daten stammen aus interessenbezogener Werbung von
                            Google sowie aus Besucherdaten von Drittanbietern. Diese Daten können keiner bestimmten Person zugeordnet werden. Sie können diese
                            Funktion jederzeit über die Anzeigeneinstellungen in Ihrem Google-Konto deaktivieren oder die Erfassung Ihrer Daten durch Google
                            Analytics wie im Punkt “Widerspruch gegen Datenerfassung” dargestellt generell untersagen.
                        </p>
                        <h2 className="mb-4">Plugins und Tools</h2>
                        <h3>YouTube</h3>
                        <p className="mb-5">
                            Unsere Website nutzt Plugins der von Google betriebenen Seite YouTube. Betreiber der Seiten ist die YouTube, LLC, 901 Cherry Ave.,
                            San Bruno, CA 94066, USA. Wenn Sie eine unserer mit einem YouTube-Plugin ausgestatteten Seiten besuchen, wird eine Verbindung zu den
                            Servern von YouTube hergestellt. Dabei wird dem YouTube-Server mitgeteilt, welche unserer Seiten Sie besucht haben. Wenn Sie in
                            Ihrem YouTube-Account eingeloggt sind, ermöglichen Sie YouTube, Ihr Surfverhalten direkt Ihrem persönlichen Profil zuzuordnen. Dies
                            können Sie verhindern, indem Sie sich aus Ihrem YouTube-Account ausloggen. Die Nutzung von YouTube erfolgt im Interesse einer
                            ansprechenden Darstellung unserer Online-Angebote. Dies stellt ein berechtigtes Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO
                            dar. Weitere Informationen zum Umgang mit Nutzerdaten finden Sie in der Datenschutzerklärung von YouTube unter:
                            <a href="https://www.google.de/intl/de/policies/privacy">https://www.google.de/intl/de/policies/privacy</a>.
                        </p>
                        <h3>Google Web Fonts</h3>
                        <p className="mb-5">
                            Diese Seite nutzt zur einheitlichen Darstellung von Schriftarten so genannte Web Fonts, die von Google bereitgestellt werden. Beim
                            Aufruf einer Seite lädt Ihr Browser die benötigten Web Fonts in ihren Browsercache, um Texte und Schriftarten korrekt anzuzeigen. Zu
                            diesem Zweck muss der von Ihnen verwendete Browser Verbindung zu den Servern von Google aufnehmen. Hierdurch erlangt Google Kenntnis
                            darüber, dass über Ihre IP-Adresse unsere Website aufgerufen wurde. Die Nutzung von Google Web Fonts erfolgt im Interesse einer
                            einheitlichen und ansprechenden Darstellung unserer Online-Angebote. Dies stellt ein berechtigtes Interesse im Sinne von Art. 6 Abs.
                            1 lit. f DSGVO dar. Wenn Ihr Browser Web Fonts nicht unterstützt, wird eine Standardschrift von Ihrem Computer genutzt. Weitere
                            Informationen zu Google Web Fonts finden Sie unter
                            <a href="https://developers.google.com/fonts/faq">https://developers.google.com/fonts/faq</a> und in der Datenschutzerklärung von
                            Google: <a href="https://www.google.com/policies/privacy/">https://www.google.com/policies/privacy/</a>.
                        </p>
                        <h3>Google Maps</h3>
                        <p className="mb-5">
                            Diese Seite nutzt über eine API den Kartendienst Google Maps. Anbieter ist die Google Inc., 1600 Amphitheatre Parkway, Mountain
                            View, CA 94043, USA. Zur Nutzung der Funktionen von Google Maps ist es notwendig, Ihre IP Adresse zu speichern. Diese Informationen
                            werden in der Regel an einen Server von Google in den USA übertragen und dort gespeichert. Der Anbieter dieser Seite hat keinen
                            Einfluss auf diese Datenübertragung. Die Nutzung von Google Maps erfolgt im Interesse einer ansprechenden Darstellung unserer
                            Online-Angebote und an einer leichten Auffindbarkeit der von uns auf der Website angegebenen Orte. Dies stellt ein berechtigtes
                            Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO dar. Mehr Informationen zum Umgang mit Nutzerdaten finden Sie in der
                            Datenschutzerklärung von Google:
                            <a href="https://www.google.de/intl/de/policies/privacy/">https://www.google.de/intl/de/policies/privacy/</a>.
                        </p>
                        <h2 className="mb-4">Newsletterversand über Brevo</h2>
                        <p className="mb-3">
                            Ich verwende Brevo als Marketing-Plattform. Wenn Du das Newsletter-Formular auf meiner Website ausfüllst und absendest, bestätigst
                            Du, dass die von Dir angegebenen Informationen an Brevo zur Bearbeitung gemäß den
                            <a href="https://www.brevo.com/de/legal/termsofuse/" target="_blank" rel="noopener noreferrer">
                                Nutzungsbedingungen
                            </a>
                            übertragen werden.
                        </p>
                        <p className="mb-5">
                            Die Anmeldung zu meinem Newsletter ist kostenlos. Für meine Newsletter verwende ich den E-Mail-Versanddienst Brevo von der
                            Sendinblue GmbH, Köpenicker Str. 126, 10179 Berlin. Ich nutze das E-Mail-Marketing-Tool Brevo, damit ich Euch maßgeschneiderte
                            Newsletter schicken kann. Brevo bietet hilfreiche Analysemöglichkeiten. Ich erfahre, ob und wann meine Newsletter von Euch geöffnet
                            wurden. Brevo zeichnet ebenfalls auf, ob und auf welchen Link ihr innerhalb des Newsletters geklickt habt. Dank dieser gespeicherten
                            Informationen zu Eurem User- und Klickverhalten kann ich meinen Service besser an Eure Wünsche anpassen und verbessern.
                        </p>
                        <h3>Folgende Daten werden durch Brevo verarbeitet</h3>
                        <p className="mb-5">
                            Alle Daten, die ihr beim Anmeldevorgang zum Newsletter eingebt, zum Beispiel Ihre E-Mail-Adresse oder Ihr Vor- und Nachname, werden
                            bei Brevo und auf meinem genutzten Server gespeichert und verwaltet. Es handelt sich dabei auch um personenbezogene Daten. Neben dem
                            Anmeldedatum und -uhrzeit wird beispielsweise auch Eure IP-Adresse gespeichert. Durch die Anmeldung willigt ihr den
                            Newsletterversand an Eure E-Mail-Adresse ein. Bei der Anmeldung werdet ihr zusätzlich auf meine Datenschutzerklärung hingewiesen,
                            welche diesen Abschnitt enthält.
                        </p>
                        <h3>Datenspeicherung bei Brevo</h3>
                        <p className="mb-5">
                            Brevo sichert Eure Daten auf Servern in Deutschland und löscht die personenbezogenen Daten spätestens nach zwei Jahren ab der
                            Beendigung des Vertragsverhältnisses mit mir. Zu jeder Zeit könnt ihr die Löschung Eurer Daten anfordern. Brevo bearbeitet diese
                            Anträge innerhalb von 30 Tagen. Alle Daten, die ich erhebe und an Brevo sende, werden durch Eure Newsletter-Abmeldung bei mir
                            gelöscht.
                        </p>
                        <h3>Newsletter Widerspruchsrecht</h3>
                        <p className="mb-5">
                            Ihr könnt die Newsletter-Anmeldung zu jeder Zeit kündigen. Widerrufe dazu einfach Deine Einwilligung zur Newsletter-Anmeldung. Dies
                            könnt ihr auch durch wenige Klicks selbst durchführen. Den Abmelde-Link findet ihr am Ende jeder Newsletter-E-Mail. Solltet ihr den
                            Link nicht finden, kontaktiere mich und ich werde Deine Newsletter-Anmeldung unverzüglich kündigen. Alle personenbezogenen Daten
                            werden danach von den Brevo-Servern und meinem genutzten Server gelöscht. Es ist Euer Recht, unentgeltlich Auskunft über Eure
                            gespeicherten Daten zu erhalten, sie löschen, sperren oder berichtigen zu lassen.
                        </p>
                        <h3>Newsletter Rechtsgrundlage</h3>
                        <p className="mb-5">
                            Ich versende meinen Newsletter mit Brevo auf der Grundlage Eurer Einwilligung (Artikel 6 Abs. 1 lit. a DSGVO). Ich versende einen
                            Newsletter nur, wenn ihr Euch zuvor aktiv dafür angemeldet habt. Ist es rechtlich erlaubt und sollte eine Einwilligung nicht nötig
                            sein, erfolgt der Newsletter-Versand aufgrund des berechtigten Interesses am Direktmarketing (Artikel 6 Abs. 1 lit. f). Damit ich
                            nachweisen kann, dass Euer Registrierungsprozess den Gesetzen entspricht, wird dieser aufgezeichnet. Weitere Informationen über die
                            Datenverarbeitung findet ihr in der Datenschutzrichtlinie von Brevo unter https://www.brevo.com/de/legal/privacypolicy/ und der
                            folgenden Informationsseite https://www.brevo.com/de/informationen-newsletter-empfaenger/.
                        </p>
                    </div>
                </div>
            </main>
            <NewsletterFormular></NewsletterFormular>
            <Footer />
        </Fragment>
    );
}

export default Datenschutz;
