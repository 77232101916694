import React from "react";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import "./StartseiteLeistungenBlock.css";

function StartseiteLeistungenBlock({ titel, id }) {
    return (
        <div className="StartseiteLeistungenBlock" id={id}>
            <div className="wrapper">
                <div className="content d-flex flex-column justify-content-center">
                    <span className="h2 text-initial fs-2 color-secondary h2--border h2--center mb-7 fs-2 d-flex justify-content-center align-items-center gap-3 flex-wrap">
                        Meine
                        <span className="fs-60px color-secondary font-rollerscript">Leistungen</span>
                    </span>
                    <div className="row justify-content-between mb-5 mb-md-7">
                        <Link to="/yoga" title="zu Yoga" className="col-12 col-sm-6 col-xl-3 d-flex flex-column align-items-center mb-5 top__links">
                            <div className="StartseiteLeistungenBlock__icon">
                                <img src="/img/Leistungen_Yoga.svg" alt="Yoga Icon" />
                            </div>
                            <span className="h4 h4--spaceing text-center">Yoga</span>
                        </Link>
                        <Link to="/fitness" title="zu Fitness" className="col-12 col-sm-6 col-xl-3 d-flex flex-column align-items-center mb-5 top__links">
                            <div className="StartseiteLeistungenBlock__icon">
                                <img src="/img/Leistungen_Fitness.svg" alt="Fitness Icon" />
                            </div>
                            <span className="h4 h4--spaceing text-center">Fitness</span>
                        </Link>
                        <Link
                            to="/ernaehrungscoaching"
                            title="zu Ernährungscoaching"
                            className="col-12 col-sm-6 col-xl-3 d-flex flex-column align-items-center mb-5 top__links"
                        >
                            <div className="StartseiteLeistungenBlock__icon">
                                <img src="/img/Leistungen_Ernaehrungscoaching.svg" alt="Ernährungscoaching Icon" />
                            </div>
                            <span className="h4 h4--spaceing text-center">Ernährungs&shy;Coaching</span>
                        </Link>
                        <Link
                            to="/stand-up-paddling-in-saalburg"
                            title="zu Stand Up Paddling"
                            className="col-12 col-sm-6 col-xl-3 d-flex flex-column align-items-center mb-5 top__links"
                        >
                            <div className="StartseiteLeistungenBlock__icon">
                                <img src="/img/Leistungen_StandUpPaddling.svg" alt="Stand Up Paddling Icon" />
                            </div>
                            <span className="h4 h4--spaceing text-center">Stand Up Paddling</span>
                        </Link>
                    </div>
                    <div className="row justify-content-center">
                        <HashLink
                            to={"/fitness#b3"}
                            title="zu Personal Training"
                            className="col-6 col-md-2 d-flex flex-column align-items-center mb-5 StartseiteLeistungenBlock__icon--small"
                        >
                            <img src="/img/Leistungen_PersonalTraining.svg" alt="Personal Training Icon" />
                            <span className="h6 h6--spaceing h6--center">Personal Training</span>
                        </HashLink>
                        <HashLink
                            to={"/fitness#b4"}
                            title="zu Gruppen Training"
                            className="col-6 col-md-2 d-flex flex-column align-items-center mb-5 StartseiteLeistungenBlock__icon--small"
                        >
                            <img src="/img/Leistungen_GruppenTraining.svg" alt="Gruppen Training Icon" />
                            <span className="h6 h6--spaceing h6--center">Gruppen Training</span>
                        </HashLink>
                        <HashLink
                            to={"/firmenfitness"}
                            title="zu Firmen Fitness"
                            className="col-6 col-md-2 d-flex flex-column align-items-center mb-5 StartseiteLeistungenBlock__icon--small"
                        >
                            <img src="/img/Leistungen_FirmenFitness.svg" alt="Firmen Fitness Icon" />
                            <span className="h6 h6--spaceing h6--center">Firmen Fitness</span>
                        </HashLink>
                        <HashLink
                            to={"/firmenfitness#b3"}
                            title="zu Workshops"
                            className="col-6 col-md-2 d-flex flex-column align-items-center mb-5 StartseiteLeistungenBlock__icon--small"
                        >
                            <img src="/img/Leistungen_Workshops.svg" alt="Workshops Icon" />
                            <span className="h6 h6--spaceing h6--center">Workshops</span>
                        </HashLink>
                        <HashLink
                            to={"/firmenfitness#b3"}
                            title="zu Vorträge"
                            className="col-6 col-md-2 d-flex flex-column align-items-center mb-5 StartseiteLeistungenBlock__icon--small"
                        >
                            <img src="/img/Leistungen_Vortraege.svg" alt="Vorträge Icon" />
                            <span className="h6 h6--spaceing h6--center">Vorträge</span>
                        </HashLink>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default StartseiteLeistungenBlock;
