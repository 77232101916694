import React, { Fragment, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import BlockControls from "../components/BlockControls";
import Footer from "../components/Footer";
import Header from "../components/Header";
import ScrollToTop from "../components/ScrollToTop";
import SocialIcons from "../components/SocialIcons";
import NewsletterFormular from "../components/NewsletterFormular";
import ContentBlock1 from "../components/ContentBlock1";

function Kurskalender() {
    const blockControlsData = [
        {
            titel: "Kurskalender",
            blockId: "b1",
        },
        {
            titel: "Kalender",
            blockId: "b2",
        },
        {
            titel: "Newsletter",
            blockId: "b3",
        },
        {
            titel: "Kontakt",
            blockId: "b4",
        },
    ];

    const [schema, setSchema] = useState("light");
    const [schemaHeader, setSchemaHeader] = useState("light");

    useEffect(() => {
        window.addEventListener("scroll", () => {
            if (window.scrollY > 300) {
                setSchema("dark");
            } else {
                setSchema("light");
            }
        });
    }, []);

    return (
        <Fragment>
            <Helmet>
                <title>Kurskalender</title>
                <meta name="description" content=""></meta>
                <meta name="keywords" content=""></meta>
            </Helmet>
            <ScrollToTop />
            <Header schema={schemaHeader} />
            <SocialIcons schema={schema} />
            <BlockControls blockControlsData={blockControlsData} schema={schema} />
            <ContentBlock1
                titel="Kurskalender"
                text="Entdecke mein vielfältiges Angebot an Online- und Offline-Kursen in Yoga, Fitness und Ernährung!"
                usps={[]}
                buttonText="Zum Kalender"
                buttonLink="#b2"
                targetDialog="DialogKontakt"
                backgroundImg="/img/Kurskalender.webp"
                alignBox="right"
                id={blockControlsData[0].blockId}
            />
            <div className="w-100" id={blockControlsData[1].blockId}>
                <div className="wrapper">
                    <div className="content d-flex flex-column align-items-center justify-content-center h-100vh">
                        <h1 className="h4 h4--spaceing h4--border h4--center mb-7">Aktuelle Kurse</h1>
                        <div className="row mb-4">
                            <div className="col-12 col-sm-6 mb-4 mb-sm-0">
                                <h3 className="h4 h4-spacing mb-4">Einzelbuchung: Einfach und flexibel Kurse buchen</h3>
                                <p className="mb-4">
                                    Mit der <b>"Drop-In"-Mitgliedschaft</b> kannst du ganz einfach und flexibel einzelne Kurse buchen, die deinen Interessen und deinem Zeitplan entsprechen.
                                </p>
                                <p>
                                    <b>Die Preise</b> für die Kurse variieren. Du kannst die aktuellen Preise über die Navigationsleiste im Tab "Preise" finden.
                                </p>
                            </div>
                            <div className="col-12 col-sm-6">
                                <h3 className="h4 h4-spacing mb-4">So einfach geht's:</h3>
                                <ol>
                                    <li>
                                        Klicke auf <b>"Einzelbuchung kaufen"</b>.
                                    </li>
                                    <li>
                                        Wähle die <b>"Drop-In"-Mitgliedschaft</b> aus.
                                    </li>
                                    <li>
                                        <b>Bestätige deine Mitgliedschaft</b> und vervollständige deine Daten.
                                    </li>
                                    <li>
                                        <b>Wähle den Kurs aus</b>, den du buchen möchtest.
                                    </li>
                                    <li>
                                        Klicke auf <b>"Buchen"</b> eines Kurses und schon kannst du am Kurs teilnehmen!
                                    </li>
                                </ol>
                            </div>
                        </div>
                        <iframe src="https://app.octivfitness.com/widget/schedule?publicToken=a4bd4c5780a12259954c0b22cdb76469fa5ba60e" frameBorder="0" height="1000px" width="100%" title="Preise und Kurse Kalender">
                            Dein Browser unterstützt keine iframes
                        </iframe>
                    </div>
                </div>
            </div>
            <NewsletterFormular id={blockControlsData[2].blockId}></NewsletterFormular>
            <Footer id={blockControlsData[3].blockId} />
        </Fragment>
    );
}

export default Kurskalender;
