import React, { Fragment, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import Header from "../components/Header";
import Footer from "../components/Footer";
import ScrollToTop from "../components/ScrollToTop";
import NewsletterFormular from "../components/NewsletterFormular";

function Impressum() {
    const [schema, setSchema] = useState("dark");
    const [schemaHeader, setSchemaHeader] = useState("dark");

    return (
        <Fragment>
            <Helmet>
                <title>Impressum | Carolin Pfeffer</title>
                <meta name="description" content=""></meta>
                <meta name="keywords" content=""></meta>
            </Helmet>
            <ScrollToTop />
            <Header schema={schemaHeader} />
            <main className="w-100">
                <div className="wrapper">
                    <div className="content" style={{ paddingTop: "15rem" }}>
                        <h1 className="mb-5">Impressum</h1>
                        <p className="mb-4">
                            Angaben gemäß § 5TMG:
                            <br />
                            Carolin Pfeffer
                            <br />
                            Tummelgasse 95
                            <br />
                            07929 Saalburg-Ebersdorf
                        </p>
                        <p className="mb-4">
                            USt-IdNr.:
                            <br />
                            DE311129040
                        </p>
                        <p className="mb-4">
                            Kontakt:
                            <br />
                            Telefon: 0163 88 02 363
                            <br />
                            E-Mail: info@carolinpfeffer.de
                            <br />
                            carolinpfeffer.de
                        </p>
                        <p className="mb-4">
                            Verantwortlich für den Inhalt nach § 55 Abs.2 RStV:
                            <br />
                            Carolin Pfeffer
                            <br />
                            Tummelgasse 95
                            <br />
                            07929 Saalburg-Ebersdorf
                        </p>
                        <p className="mb-5">
                            Alternative Streitbeilegung:
                            <br />
                            Die Europäische Kommission stellt eine Plattform für die außergerichtliche Online-Streitbeilegung (OS-Plattform) bereit, aufrufbar
                            unter
                            <a href="https://ec.europa.eu/odr" title="Europäische Kommission">
                                https://ec.europa.eu/odr
                            </a>
                            .
                        </p>
                        <h2 className="mb-4">Haftungsausschluss (Disclaimer)</h2>
                        <h3>Haftung für Inhalte</h3>
                        <p className="mb-4">
                            Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich.
                            Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu
                            überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder
                            Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist
                            jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden
                            Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.
                        </p>
                        <h3>Haftung für Links</h3>
                        <p className="mb-4">
                            Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb können wir für diese
                            fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber
                            der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft.
                            Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar. Eine permanente inhaltliche Kontrolle der verlinkten
                            Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden
                            wir derartige Links umgehend entfernen.
                        </p>
                        <h3>Urheberrecht</h3>
                        <p className="mb-4">
                            Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht. Die
                            Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der
                            schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten, nicht
                            kommerziellen Gebrauch gestattet. Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte
                            Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine
                            Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen
                            werden wir derartige Inhalte umgehend entfernen.
                        </p>
                        <p>Quelle: http://www.e-recht24.de</p>
                    </div>
                </div>
            </main>
            <NewsletterFormular></NewsletterFormular>
            <Footer />
        </Fragment>
    );
}

export default Impressum;
