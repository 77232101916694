import React from "react";
import { Link } from "react-router-dom";
import "./StartseiteLandingBlock.css";

function StartseiteLandingBlock({
    titel,
    subTitel,
    h50px,
    text,
    buttonText,
    buttonLink,
    backgroundImg,
    alignBox,
    id,
    jump,
}) {
    return (
        <div
            className="StartseiteLandingBlock"
            id={id}
            style={{
                backgroundImage: 'url("' + backgroundImg + '")',
                backgroundPosition:
                    alignBox === "right" ? "35% center" : "65% center",
            }}>
            <div
                className={"StartseiteLandingBlock__overlay " + alignBox}></div>
            <div className="wrapper h-100">
                <div className="content h-100 d-flex align-items-end justify-content-center">
                    <div
                        className="StartseiteLandingBlock__box"
                        style={{ [alignBox]: "0" }}>
                        <div className={h50px ? "mb-5" : "mb-3"}>
                            <span
                                className={
                                    "font-rollerscript text-initial h2 d-block " +
                                    (h50px ? "fs-big-resp" : "fs-60px")
                                }>
                                {titel}
                            </span>
                            {subTitel ? (
                                <span className=" color-background fs-4">
                                    {subTitel}
                                </span>
                            ) : null}
                        </div>

                        <span className="d-block mb-5">{text}</span>
                        <Link
                            to={buttonLink}
                            title={buttonText}
                            className="btn-dark-white">
                            {buttonText}
                        </Link>
                    </div>
                    {jump ? (
                        <a
                            href={jump}
                            title={jump}
                            className="ContentBlock1__bottom">
                            <i className="bi bi-arrow-down"></i>
                        </a>
                    ) : null}
                </div>
            </div>
        </div>
    );
}

export default StartseiteLandingBlock;
