import React, { Fragment, useEffect, useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import BlockControls from "../components/BlockControls";
import SocialIcons from "../components/SocialIcons";
import ContentBlock1 from "../components/ContentBlock1";
import ScrollToTop from "../components/ScrollToTop";
import "./Aktivurlaub.css";
import { Helmet } from "react-helmet-async";
import ContentBlockGallery from "../components/ContentBlockGallery";
import Reviews from "../components/Reviews";
import NewsletterFormular from "../components/NewsletterFormular";

function Aktivurlaub() {
    const blockControlsData = [
        {
            titel: "Aktivurlaub",
            blockId: "b1",
        },
        {
            titel: "Aktiv-Angebot",
            blockId: "b2",
        },
        {
            titel: "Impressionen",
            blockId: "b3",
        },
        {
            titel: "Newsletter",
            blockId: "newsletter",
        },
        {
            titel: "Kontakt",
            blockId: "b5",
        },
    ];

    const [schema, setSchema] = useState("light");
    const [schemaHeader, setSchemaHeader] = useState("light");

    useEffect(() => {
        window.addEventListener("scroll", () => {
            if (window.scrollY > 300) {
                setSchema("dark");
            } else {
                setSchema("light");
            }
        });
    }, []);

    return (
        <Fragment>
            <Helmet>
                <title>Aktivurlaub in Saalburg | Carolin Pfeffer</title>
                <meta
                    name="description"
                    content="Aktivurlaub in Saalburg » SUP auf dem Bleilochstausee ✓ Fitnesskurse ✓ Yogakurse ✓  Wanderrouten ✓ Fahrradfahren &amp; vieles mehr ✓"
                ></meta>
                <meta
                    name="keywords"
                    content="aktivurlaub,urlaub mit sport,urlaub in thüringen,urlaub am see,urlaub bleilochtalsperre,urlaub thüringen meer,thüringen urlauber,urlaub yoga,urlaub vegan,urlaub fahrrad fahren,urlaub wandern,urlaub fitness"
                ></meta>
            </Helmet>
            <ScrollToTop />
            <Header schema={schemaHeader} />
            <SocialIcons schema={schema} />
            <BlockControls blockControlsData={blockControlsData} schema={schema} />
            <ContentBlock1
                titel="Aktivurlaub"
                text="Mitten im Grünen Herzen Deutschlands am Bleilochstausee bietet die Natur zahlreiche Möglichkeiten Deinen Urlaub sportlich aktiv zu gestalten."
                usps={["Yoga", "Stand Up Paddling", "Meditation & Stressbewältigung", "Ernährungscoaching", "Fitnesstraining"]}
                buttonText="jetzt kontaktieren"
                buttonLink="dialog"
                targetDialog="DialogKontakt"
                backgroundImg="/img/AktivurlaubLandingBlock.webp"
                //<a href="https://de.freepik.com/fotos-kostenlos/fluss-umgeben-von-waeldern-unter-einem-bewoelkten-himmel-in-thueringen-in-deutschland_13291760.htm#query=outdoor&position=11&from_view=search">Image by wirestock</a> on Freepik
                alignBox="right"
                id={blockControlsData[0].blockId}
            />
            <div className="w-100" id={blockControlsData[1].blockId}>
                <div className="wrapper">
                    <div className="content d-flex flex-column align-items-center justify-content-evenly">
                        <h2 className="h4 h4--spaceing h4--border h4--center mb-7">Urlauber Aktiv-Angebot</h2>
                        <div className="row w-100 mb-4">
                            <div className="col d-flex flex-column justify-content-evenly text-uppercase mb-5">
                                <img src="/img/Aktivurlaub_Yoga.svg" alt="Yoga Icon" className="contentSpecItem mb-4" />
                                <span className="d-block text-center">Yoga</span>
                            </div>
                            <div className="col d-flex flex-column justify-content-evenly text-uppercase mb-5">
                                <img src="/img/Aktivurlaub_Fitness.svg" alt="Fitness Icon" className="contentSpecItem mb-4" />
                                <span className="d-block text-center">Fitness</span>
                            </div>
                            <div className="col d-flex flex-column justify-content-evenly text-uppercase mb-5">
                                <img src="/img/Aktivurlaub_StandUpPaddling.svg" alt="Stand Up Paddling Icon" className="contentSpecItem mb-4" />
                                <span className="d-block text-center">Stand Up Paddling</span>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-md-8 mb-4">
                                <h3 className="d-block h4 h4--spaceing mb-4">Für jeden etwas dabei</h3>
                                <p className="mb-4">
                                    Bei mir findest Du sicher das passende Angebot für jedes Wetter: Erkunde unseren Stausee auf eigene Faust und miete Dir Dein
                                    SUP Board für ein paar Stunden, einen Tag oder Deinen ganzen Urlaub.
                                </p>
                                <h4 className="d-block fw-bold mb-2 fs-5">Genieße eine geführte SUP Tour, Fitness oder Yoga auf dem Wasser.</h4>
                                <p>
                                    Entspanne an Regentagen bei einer Yogastunde in der Gruppe oder beim Einzelunterricht. Powere Dich mal so richtig aus beim
                                    Functional-Fitness und erfahre mehr über gesunde vegane Ernährung.
                                </p>
                                {/* <p className="mb-4">
                                    Ich biete dir gern das passende Angebot für jedes Wetter. SUP-Boards stehen zum Verleih zur Verfügung, an Regentagen wirkt
                                    so manche Yogastunde wahre Wunder. Auch auf dem SUP Board lassen sich effektive Yoga-Übungen für Körper und Geist erlernen.
                                </p>
                                <span className="d-block fw-bold mb-2">Gruppenangebot</span>
                                <p className="mb-4">
                                    Du planst regelmäßige Ausflüge mit deinem Verein, Stammtisch, Freundeskreis, etc. und würdest gern eine Radtour mit ein
                                    klein bisschen mehr Energie durchführen? Gern organisiere ich für Ihre Gruppe die passenden E-Bikes, plane
                                    Einkehrmöglichkeiten mit Akkuladeplatz oder gestalte Ihren kompletten Ausflug.
                                </p>
                                <span className="d-block mb-4">
                                    <span className="fw-bold">Tipp:&nbsp;</span>
                                    Gern kannst du abklären, ob dein Hotel bereits einen Angebotsplan vorliegen hat oder in deiner Buchung eines der Angebote
                                    enthalten ist. Wenn nicht, ruf mich gern an.
                                </span>
                                <span className="d-block fw-bold mb-2">Weitere Angebote für deine Freizeitgestaltung findest du unter:</span>
                                <div className="d-flex gap-3">
                                    <ul>
                                        <li>
                                            <a href="http://www.bootsverleih-saalburg.de/2176049fa10b53f0c/index.html">Bootsverleih Ziebis</a>
                                        </li>
                                        <li>
                                            <a href="https://www.kletterwald-saalburg.de/">Kletterwald</a>
                                        </li>
                                        <li>
                                            <a href="https://www.treibhouse.eu/das-treibhouse">Das Treibhouse</a>
                                        </li>
                                    </ul>
                                    <ul>
                                        <li>
                                            <a href="http://www.saalburg-ebersdorf.de/touristinformation/aktivurlaub">Saalburg-Ebersdorf</a>
                                        </li>
                                        <li>
                                            <a href="https://thueringen.blog">Thüringen blog</a>
                                        </li>
                                    </ul>
                                </div> */}
                            </div>
                            <div className="col-12 col-md-4">
                                <img src="/img/Content_GalerieFitness4.webp" alt="Aktivurlaub Galerie Foto" className="w-100" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="w-100">
                <div className="wrapper">
                    <div className="content">
                        <h4 className="h4 h4--spaceing h4--border h4--center mb-7">Das sagen meine Kunden</h4>
                        <Reviews url={"/cronjobs/googleReviewsPersonal.json"} />
                    </div>
                </div>
            </div>
            <ContentBlockGallery
                id={blockControlsData[2].blockId}
                img1={"/img/Content_GalerieFitness1.webp"}
                img2={"/img/Content_GalerieYoga2.webp"}
                img3={"/img/Content_GalerieSUP4.webp"}
                img4={"/img/Content_GalerieSUP1.webp"}
            />
            <NewsletterFormular id={blockControlsData[3].blockId}></NewsletterFormular>
            <Footer id={blockControlsData[4].blockId} />
        </Fragment>
    );
}

export default Aktivurlaub;
